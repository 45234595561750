const SUBSCRIPTION_MAP = {
    MONTHLY: 1,
    QUARTERLY: 2,
    HALF_YEARLY: 3,
};

const SUBSCRIPTION_DURATION = {
    1: 1,
    2: 3,
    3: 6,
};


const GET_PLANS_FAILURE_MESSAGE = "Unable to get plans. Please refresh.";
const INITIATE_PAYMENT_FAILURE_MESSAGE = "Unable to initiate payments. Please try again.";

export { SUBSCRIPTION_MAP, SUBSCRIPTION_DURATION, GET_PLANS_FAILURE_MESSAGE, INITIATE_PAYMENT_FAILURE_MESSAGE };
