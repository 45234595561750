import lectures from "../../../img/ren-lectures.png";
import liveclassses from "../../../img/ren-liveclass.png";
import problems from "../../../img/ren-problems.png";
import mentorship from "../../../img/ren-mentors.png";
import doubts from "../../../img/ren-doubts.png";
import referral from "../../../img/ren-referrals.png";
import project from "../../../img/ren-project.png";
import personalise from "../../../img/ren-personalise.png";

const RenaissanceWhyLearnContent = [
    {
        img: lectures,
        title: "350+ lectures",
        description: <p>In-depth whiteboard<br /> concept lectures</p>,
    },
    {
        img: project,
        title: "Full Stack Dev Project",
        description: "Work on Industry level projects",
    },
    
    {
        img: liveclassses,
        title: "Live Classes",
        description: "Intermittent classes",
    },
    {
        img: referral,
        title: "Mocks & Referrals",
        description: "Performance based",
    },
    {
        img: problems,
        title: "600+ problems",
        description: <p>Handpicked for your <br /> practice</p>,
    },
    {
        img: doubts,
        title: "Doubt Resolution",
        description: "in Real time",
    },
    {
        img: mentorship,
        title: "1:1 Mentorship",
        description: <p>Meet mentors<br /> whenever you need</p>, 
    },
    {
        img: personalise,
        title: "Personalised Plans",
        description: "Tailored for your needs",
    },
]

export default RenaissanceWhyLearnContent