import React from "react";
import { useState } from "react";
import { RenaissanceFaqContents } from "./Content/RenaissanceFaqsContent";
import "./styles/faq.css";
import FaqAccordion from "./FaqAccordion";

const Faq = () => {
  const [openId, setOpenId] = useState(1);
  const recordAccordionId = (id) => {
    if (id === openId) setOpenId(-1);
    else setOpenId(id);
  };
  return (
    <div className="ren-faq-wrapper">
      <h1>Frequently Asked Questions</h1>
      {RenaissanceFaqContents &&
        RenaissanceFaqContents.map((accordionData, index) => (
          <div key={index}>
            <FaqAccordion
              identifier={accordionData.id}
              key={index}
              accordionData={accordionData}
              recordAccordionId={recordAccordionId}
              isOpen={accordionData.id === openId}
            />
          </div>
        ))}
    </div>
  );
};

export default Faq;
