import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import arrowPrev from "../../img/left-arrow-blue.png";
import linkedin_icon from "../../img/linkedin.png";
import quotesIcon from "../../img/quotes-icon.png";
import arrowNext from "../../img/right-arrow-blue.png";
import { SUCCESS_STORIES } from "./Content/SuccessStoriesContent";
import "./styles/SuccessStoriesMobile.css";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

function SuccessStoriesMobile() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    const getSuccessStoriesMobile = () => {
        return (
            <Slider {...sliderSettings}>
                {SUCCESS_STORIES &&
                    SUCCESS_STORIES.map((data, index) => (
                        <div className="success-stories-slider-card">
                            <div className="quotes-img-wrapper">
                                <div className="quotes">
                                    <img src={quotesIcon} alt="" />
                                </div>
                            </div>

                            <p className="success-stories-slider-card-desc">{data.text}</p>
                            <div className="bottom-wrapper">
                                <div className="success-stories-slider-card-img-wrapper">
                                    <img src={data.img} alt="" />
                                </div>
                                <div className="success-stories-slider-name-wrapper">
                                    <p className="success-stories-slider-name">{data.name}</p>

                                    <a href={data.link}>
                                        <img src={linkedin_icon} alt="" />
                                    </a>
                                </div>
                                <div className="success-stories-slider-company-logo-wrapper">
                                    <p className="sde-slider-text">SDE</p>
                                    <div className={data.company_logo_wrapper}>
                                        <img src={data.company_logo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </Slider>
        );
    };
    return <div>{getSuccessStoriesMobile()}</div>;
}

export default SuccessStoriesMobile;
