import React from "react";
import tickMark from "../../../img/ticks.svg";

const Outcomes = (props) => {
    const { outcomeContents } = props;

    return (
        <div className="learning-outcomes-wrapper">
            <div className="learning-outcomes-content">
                <h2>{outcomeContents.sectionTitle}</h2>
                {outcomeContents.sectionDescription}
            </div>

            <div className="learning-outcomes-points-wrapper">
                {outcomeContents.OutcomesList &&
                    outcomeContents.OutcomesList.map((data, index) => (
                        <React.Fragment key={index}>
                            <div className="learning-outcomes-points" key={index}>
                                <img src={tickMark} alt="" />
                                <p>{data}</p>
                            </div>
                            <div className="horizontal-line-outcomes"></div>
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
};

export default Outcomes;
