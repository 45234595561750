let COUPON_ADDITIONAL_DISCOUNT = 0;
let PLAN_WITH_HEIGHEST_VALUE = 2;
const COUPON_CODE_MODALS = {
    VALID: 1,
    INVALID: 0,
};
const INVALID_COUPON_CODE_MESSAGE = "This Coupon Code is invalid.";
const INITIATE_PAYMENT_FAILURE_MESSAGE = "Unable to initiate payments. Please try again.";
const GET_PLANS_FAILURE_MESSAGE = "Unable to get plans. Please refresh.";
const SUBSCRIPTION_MAP = {
    MONTHLY: 1,
    HALF_YEARLY: 2,
    YEARLY: 3,
};

const SUBSCRIPTION_DURATION = {
    1: 1,
    2: 6,
    3: 12,
};

const UPGRADATION_MAP = {
    MONTHLY: 1,
    QUATERLY: 2,
    HALF_YEARLY: 3,
};

const MULTIPLE_COURSES_SUBSCRIPTION_MAP = {
    "renaissance": {
        MONTHLY: 1,
        HALF_YEARLY: 2,
        YEARLY: 3,
    },
    "low-level-design": {
        MONTHLY: 1,
        QUARTERLY: 2,
        HALF_YEARLY: 3,
    },
};
const MULTIPLE_COURSES_SUBSCRIPTION_DURATION = {
    "renaissance":{
        1:1,
        2:6,
        3:12
    },
    "low-level-design":{
        1:1,
        2:3,
        3:6
    }
}

const SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES = {
    1: [
        <p>Try The Course Out</p>,
        <p>
            <span>500+</span> Concept videos
        </p>,
        <p>
            <span>20-25</span> Live Master Classes
        </p>,
        <p>
            <span>600+</span> Handpicked Problems
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
    ],
    3: [
        <p>
            <span>10-12 </span> Study hours of effort Per week
        </p>,
        <p>
            <span>500+</span> Concept videos
        </p>,
        <p>
            <span>250</span> Live Master Classes
        </p>,
        <p>
            <span>600+</span> Handpicked Problems
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
        <p>
            90 Days of <span>Free Subscription</span> Pause
        </p>,
        <p>Performance Based Mock Interviews</p>,
        <p>Performance based Referrals</p>,
        <p>Access upto 2 Elective Courses</p>
    ],
    2: [
        <p>
            <span>20-24</span> Study hours of effort Per week
        </p>,
        <p>
            <span>100-150</span> Live Master Classes
        </p>,
        <p>
            <span>600+</span> Handpicked Problems
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
        <p>
            45 Days of <span>Free Subscription</span> Pause
        </p>,
        <p>Performance Based Mock Interviews</p>,
        <p>Performance based Referrals</p>,
        <p>Access upto 1 Elective Course</p>
    ],
};

const APPLY_UPGRADE_DISCOUNT_URL = "/applyUpgradeDiscount";
const APPLY_REFERRAL_OR_DISCOUNT_URL = "/applyReferralOrDiscount";
const INITIATE_UPGRADE_URL = "/initiateUpgrade";
const INITIATE_PAYMENT_URL = "/initiatePayment";

export {
    SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES,
    SUBSCRIPTION_MAP,
    SUBSCRIPTION_DURATION,
    UPGRADATION_MAP,
    APPLY_UPGRADE_DISCOUNT_URL,
    APPLY_REFERRAL_OR_DISCOUNT_URL,
    INITIATE_UPGRADE_URL,
    INITIATE_PAYMENT_URL,
    COUPON_ADDITIONAL_DISCOUNT,
    PLAN_WITH_HEIGHEST_VALUE,
    COUPON_CODE_MODALS,
    INVALID_COUPON_CODE_MESSAGE,
    INITIATE_PAYMENT_FAILURE_MESSAGE,
    GET_PLANS_FAILURE_MESSAGE,
    MULTIPLE_COURSES_SUBSCRIPTION_MAP,
    MULTIPLE_COURSES_SUBSCRIPTION_DURATION

};
