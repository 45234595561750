import RedirectLoader from "../../../resources/images/ppa-redirect.gif";
import { useEffect } from "react";
import { redirectAfterGoogleSignIn } from "./GoogleClient";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import "./styles/GoogleLoginLoader.css";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";

export const GoogleLoginLoader = ({ authorization_code, state }) => {
    let navigate = useNavigate();
    const { loggedIn, setStatesAfterIsSubscribed } = useGlobalContext();

	const redirectUser = (link) => navigate(link);
   
    useEffect(() => {
        redirectAfterGoogleSignIn(
            authorization_code,
            redirectUser,
            setStatesAfterIsSubscribed,
            state
        );
    }, [authorization_code]);

    const redirectIfLoggedIn = () => {
    //   if (loggedIn) navigate("/crack-coding-interviews");
      if (loggedIn) redirectToLandingPageStrategy();
    };

    return (
        <div className="site-card-border-less-wrapper"> 
                {redirectIfLoggedIn()}
                <div className='redirect-card'>
                    <img alt="Loading..." className="redirecting-loader" src={RedirectLoader} />
                    <p className="redirecting-msg">Redirecting in a moment...</p>
                </div>
        </div>
    )
}
