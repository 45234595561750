import React from "react";
import AdvantagesContent from "./Content/AdvantagesContent";

function AdvantagesSection() {
    return (
        <>
            {AdvantagesContent &&
                AdvantagesContent.map((advantage) => (
                    <div className="advantages-card">
                        <div className="advantages-image">
                            <img className={advantage.className} src={advantage.img} />
                        </div>

                        <div className="advantages-card-data">{advantage.text}</div>
                    </div>
                ))}
        </>
    );
}

export default AdvantagesSection;
