import React, { useEffect, useState } from "react";
import { SUBSCRIPTION_MAP, SUBSCRIPTION_DURATION } from "./PricingConstants";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { startFreeTrial } from "../../../courses/Strategies/RedirectionStrategy";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { gaCourseNameMap } from "../../../courses/CourseConstants";
const PricingPlanCard = (props) => {
    const navigate = useNavigate();
    const [perMonthPrice, setPerMonthPrice] = useState({});
    const [basePlans, setBasePlans] = useState({});
    const [discountedPlans, setDiscountedPlans] = useState({});
    const [subscriptionIdentifiers, setSubscriptionIdentifiers] = useState({});
    const { setCouponDiscountPrice, freeTrialFormFilled } = useGlobalContext();
    const redirectUser = (link) => navigate(link);

    const upgradelink = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`;

    const getPriceAfterDiscount = (price, discount) => {
        return Math.floor(price - (price * discount) / 100);
    };

    const getBasePlans = () => {
        let basePlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            basePlansData[SUBSCRIPTION_MAP[subscription]] = props.isCouponValid
                ? getPriceAfterDiscount(
                      parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),
                      parseFloat(
                          props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount
                      ) -
                          parseFloat(
                              props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1]
                                  .additional_discount
                          )
                  )
                : parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp);
        }

        return basePlansData;
    };

    const getDiscountedPlans = () => {
        let discountedPlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            discountedPlansData[SUBSCRIPTION_MAP[subscription]] = getPriceAfterDiscount(
                parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),

                parseFloat(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount)
            );
        }
        return discountedPlansData;
    };

    const getSubscriptionIdentifiers = (plans) => {
        let planIdentifiers = {};
        plans &&
            plans.map((plan, index) => {
                planIdentifiers[index + 1] = plan.subscription_id;
            });
        return planIdentifiers;
    };
    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            setBasePlans(getBasePlans());
            setDiscountedPlans(getDiscountedPlans());
            setSubscriptionIdentifiers(getSubscriptionIdentifiers(props.subscriptionPlans));
        }
    }, [props.subscriptionPlans]);

    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            let pricePerMonthData = {};
            for (const subscription in SUBSCRIPTION_MAP) {
                pricePerMonthData[SUBSCRIPTION_MAP[subscription]] = Math.floor(
                    discountedPlans[SUBSCRIPTION_MAP[subscription]] /
                        SUBSCRIPTION_DURATION[SUBSCRIPTION_MAP[subscription]]
                );
            }
            setPerMonthPrice(pricePerMonthData);
        }
    }, [discountedPlans]);

    const reloadToUpgradePlan = () => {
        window.open(upgradelink, "_blank");
    };

    const reloadToSignUp = () => {
        navigate("/signup");
        // window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`, "_self");
    };

    //after isSubscribed and after login fns are changed this commented part will be removed
    const reloadToStartFreeTrial = () => {
        //navigate("/start-free-trial");
        startFreeTrial(freeTrialFormFilled, redirectUser);
    };

    const onSubscribeHandler = (cardType) => {
        //user not logged in --> takes to sign up page
        if (!props.loggedIn) reloadToSignUp();
        //logged in cases
        //user comes for first time --> takes him to free trial page
        else if (!props.everSubscribed) reloadToStartFreeTrial();
        //user is subscribed and not on free trial --> takes him to upgrade page on dashboard
        else if (props.checkUserSubscribed && !props.checkOnFreeTrail) reloadToUpgradePlan();
        //---> free trial users and expired users can make payments
        else handleSubscriptionPlan(cardType);
    };

    const handleSubscriptionPlan = (subscriptionId) => {
        props.subscriptionPlans.map((subscription) => {
            if (subscription.subscription_id === subscriptionIdentifiers[subscriptionId]) {
                sendEventToGTM({
                    event: "initiated-payment",
                    course: `${gaCourseNameMap[getCurrentCourse()]}`,
                    subscription_id: subscription?.subscription_id,
                    subscription_name: subscription?.subscription_name,
                });
                props.initiatePayment(subscription);
            }
        });
    };

    const getCardData = (cardType) => {
        let output = {
            duration: "",
            initialPrice: "",
            finalPrice: "",
            perMonth: "",
            moneySaved: 0,
        };
        output.duration = SUBSCRIPTION_DURATION[cardType];
        output.perMonth = perMonthPrice[cardType];
        output.initialPrice = basePlans[cardType];
        output.finalPrice = discountedPlans[cardType];
        return output;
    };

    const getSubscriptionCard = (cardType) => {
        let cardData = getCardData(cardType);
        setCouponDiscountPrice(
            Math.max(
                basePlans[1] - discountedPlans[1],
                basePlans[2] - discountedPlans[2],
                basePlans[3] - discountedPlans[3]
            )
        );
        //setCouponDiscountPrice(basePlans[3] - discountedPlans[3]);
        return (
            <div
                className={
                    cardType === SUBSCRIPTION_MAP.QUARTERLY
                        ? "dsa-pricing-plan-card-two"
                        : "dsa-pricing-plan-card"
                }
            >
                {cardType === SUBSCRIPTION_MAP.QUARTERLY ? (
                    <div className="dsa-recommend-triangle">Recommended</div>
                ) : (
                    <></>
                )}
                <p className="dsa-plan-duration">
                    {" "}
                    {cardData.duration}&nbsp;
                    {cardType === SUBSCRIPTION_MAP.MONTHLY ? (
                        <span>Month</span>
                    ) : (
                        <span>Months</span>
                    )}
                </p>

                <div className="dsa-horizontal-line-price"></div>
                <div className="dsa-initial-price-wrapper">
                    <p className="dsa-plan-price">₹{cardData.initialPrice}</p>
                    {props.showSavedMoney && cardData.initialPrice - cardData.finalPrice > 0 ? (
                        <p className="dsa-price-saved">
                            saved ₹ {cardData.initialPrice - cardData.finalPrice} /-
                        </p>
                    ) : (
                        <></>
                    )}
                </div>

                <p className="dsa-plan-discount-price">₹{cardData.finalPrice}</p>
                <div className="dsa-plan-desc">which costs &#x20b9;{cardData.perMonth}/month</div>
                <button
                    onClick={() => onSubscribeHandler(cardType)}
                    className="dsa-subscribe-now-button"
                >
                    Subscribe Now
                </button>
            </div>
        );
    };

    return (
        <div className="dsa-pricing-plans-wrapper">
            {getSubscriptionCard(SUBSCRIPTION_MAP.MONTHLY)}
            {getSubscriptionCard(SUBSCRIPTION_MAP.QUARTERLY)}
            {getSubscriptionCard(SUBSCRIPTION_MAP.HALF_YEARLY)}
        </div>
    );
};

export default PricingPlanCard;
