import React from "react";
import "../ComponentsStyles/CommonPrerequisites.css";

const Prerequisites = (props) => {
    const { prerequisitesContent } = props;
    return (
        <div className={`prerequisites + ${prerequisitesContent.BgClassName}`}>
            <h1>{prerequisitesContent.sectionTitle}</h1>
            {prerequisitesContent.sectionDescription}
        </div>
    );
};

export default Prerequisites;
