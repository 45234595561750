import React from "react";
import "../ComponentsStyles/CommonCurriculum.css";
import videoButton from "../../../img/lld-video-button.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import ActionButton from "../ActionButton";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { GTM_TRACKER } from "../../../GoogleAnaytics/gtmConstants";

const Curriculum = (props) => {
    const { topics } = props;
    const navigate = useNavigate();
    const [seeMore, setSeeMore] = useState(false);
    const { loggedIn } = useGlobalContext();
    const { windowWidth } = useWindowDimensions();
    const style = {
        color: "white",
        background: " linear-gradient(91.59deg, #444BAB 1.85%, #3CA5C2 99.69%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        padding: ".8vw 2vw",

        fontSize: "1.39vw",
        marginTop: "1vw",
    };
    const mobileStyle = {
        color: "white",
        background: " linear-gradient(91.59deg, #444BAB 1.85%, #3CA5C2 99.69%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        padding: "10px 16px",
        fontSize: "4vw",
        marginTop: "10px",
    };
    const handleSeeMore = () => {
        setSeeMore(!seeMore);
    };
    const getTopicCard = (data, index) => (
        <div className="topic-card" key={index}>
            <div className="topic-card-heading">
                <p>{data.heading}</p>
                {/* intentionally kept if required in future */}
                {/* <p>{data.days}</p> */}
            </div>
            <span>Topics Covered</span>
            {data.subtopics &&
                data.subtopics.map((subtopic) => (
                    <div className="subtopics-wrapper">
                        <img src={videoButton}></img>
                        <p>{subtopic}</p>
                    </div>
                ))}
        </div>
    );
    const getFreeTrialButton = () => {
        return (
            <div className={GTM_TRACKER.START_FREE_TRIAL} onClick={() => navigate("/signup")}>
                <ActionButton
                    style={windowWidth > 600 ? style : mobileStyle}
                    name=" Start 7 Day Free Trial"
                />
            </div>
        );
    };
    const getIndex = (index) => {
        return index === 0 || index === 1 || index === 2;
    };
    return (
        <div className="curriculum-wrapper">
            <h1>Course Curriculum</h1>
            <div className="topics-wrapper">
                {topics &&
                    topics.map(
                        (data, index) => (getIndex(index) || seeMore) && getTopicCard(data, index)
                    )}

                <div className="see-more-wrapper">
                    <span
                        className="see-more"
                        onClick={() => {
                            handleSeeMore();
                        }}
                    >
                        {!seeMore ? "See more" : "See Less"}
                    </span>
                    {seeMore ? (
                        <span className="arrow-up" onClick={() => handleSeeMore()}></span>
                    ) : (
                        <span className="arrow-down" onClick={() => handleSeeMore()}></span>
                    )}
                </div>

                {!loggedIn ? getFreeTrialButton() : <></>}
            </div>
        </div>
    );
};

export default Curriculum;
