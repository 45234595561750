import React from "react";
import "./styles/IntroDesktop.css";
import Slider from "react-slick";
import leftArrow from "../../img/LeftArrow.svg";
import rightArrow from "../../img/RightArrow.svg";
import Chart from "../../img/Chart.svg";
import Computer from "../../img/Computer.svg";
import Code from "../../img/Code.svg";
import Document from "../../img/Document.svg";
import TimeCircle from "../../img/TimeCircle.svg";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CardDataProjects as CardData } from "./SpringBootData";

function LeftArrow({ className, onClick }) {
    return <img className={className} onClick={onClick} src={leftArrow} alt="Left Arrow" />;
}

function RightArrow({ className, onClick }) {
    return <img className={className} onClick={onClick} src={rightArrow} alt="Right Arrow" />;
}

const Card = ({ imgSrc, heading, paragraph, tags }) => {
    return (
        <div className="springboot-intro-card">
            <div className="springboot-intro-image-section">
                <img src={imgSrc} alt={`${imgSrc}`} width={250} />
            </div>
            <div className="springboot-intro-information-box">
                <div className="springboot-intro-card-heading">{heading}</div>
                <p className="springboot-intro-card-paragraph">{paragraph}</p>
                <div className="springboot-intro-tags-container">
                    {tags.map((data, index) => (
                        <div className="springboot-intro-tag">{data}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const IntroDesktop = () => {
    var sliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <div className="springboot-intro-desktop">
            <p className="springboot-intro-recommend">Recommended for Working Professionals</p>
            <div className="springboot-intro-banner">
                <div>
                    <h1 className="springboot-intro-heading">
                        Learn Backend Engineering in JAVA Spring Boot Framework
                    </h1>
                    <h2 className="springboot-intro-description">
                        Be a Rockstar JAVA Backend Developer. Dive Deep into Spring MVC, Spring
                        Security, Spring Data, JPA, Hibernate and Many more Frameworks. Explore
                        Design Patterns and Concurrency Concepts, Ace Backend Developer Interviews
                    </h2>
                    <div className="springboot-intro-carousel-parent mobile">
                        <div className="springboot-intro-carousel">
                            <Slider {...sliderSettings}>
                                {CardData.map((data, index) => (
                                    <Card {...data} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className="springboot-intro-list-element">
                        <img src={Chart} alt="Chart" />
                        <p>Hybrid Learning: Self paced videos and weekend classes</p>
                    </div>
                    <div className="springboot-intro-list-element">
                        <img src={Computer} alt="Computer" />
                        <p>Learn by doing 3 Industry-level projects.</p>
                    </div>
                    <div className="springboot-intro-list-element">
                        <img src={Code} alt="Code" />
                        <p>Crack Interviews for backend developer roles</p>
                    </div>
                    <div className="springboot-intro-list-element">
                        <img src={Document} alt="Document" />
                        <p>Shine your Resume with the most in-demand backend skills</p>
                    </div>
                    <div className="springboot-intro-list-element">
                        <img src={TimeCircle} alt="TimeCircle" />
                        <p>
                            Estimated Duration: <b>3 months</b>
                        </p>
                    </div>
                    <div className="springboot-intro-button-container">
                        <a href="/signup">
                            <Button
                                variant="contained"
                                className="springboot-intro-free-trial-button"
                            >
                                Start 7-Days Free Trial
                            </Button>
                        </a>
                        <p className="springboot-intro-after-button-description">
                            Watch 10+ lectures and solve problems for <span>free</span>
                        </p>
                    </div>
                </div>
                <div className="springboot-intro-carousel-parent desktop">
                    <div className="springboot-intro-carousel">
                        <Slider {...sliderSettings}>
                            {CardData.map((data, index) => (
                                <Card {...data} />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroDesktop;
