import React from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Cookies from "universal-cookie";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Advantage from "./Advantage";
import Curriculum from "./Curriculum";
import Prerequisites from "./Prerequisites";
import Mentor from "../Mentor/Mentor";
import SuccessStories from "./SuccessStories";
import Pricing from "./Pricing/Pricing";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import {
    setUTMParametersInLocalStorage,
    setUtmCouponCodeInCookie,
    useQuery,
} from "../../Utilities/CommonUtilities";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";

const PythonLandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.PYTHON);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);
    setUtmCouponCodeInCookie(query);

    return (
        <div>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header />
            </div>

            <div>
                <Intro />
            </div>
            <div>
                <About />
            </div>
            <div>
                <Audience />
            </div>
            <div>
                <Advantage />
            </div>
            <div>
                <Curriculum />
            </div>

            <div>
                <Prerequisites />
            </div>

            <div>
                <Mentor course={availableCourses.PYTHON} />
            </div>
            <div>
                <SuccessStories />
            </div>
            <div>
                <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            </div>
            <div>
                <Faq />
            </div>
            <div>
                <ExploreCourses course={availableCourses.PYTHON} />
            </div>
            <div>
                <ExploreBlogs course={availableCourses.PYTHON} />
            </div>

            <div>
                <FreeTrial />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default PythonLandingPageRoute;
