import React from "react";
import "./styles/Intro.css";
import {
    WHATS_APP_LINK,
    PPA_ADMIN_PHONE_NUMBER,
    ADMIN_WHATSAPP_MESSAGE,
} from "../../Constants/LandingPageConstants";
import whatsappIcon from "../../img/whatsapp-icon.svg";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import introProfile from "./../../img/IntroProfiles.webp";

const IntroIllustrator = () => {

    const openWhatsapp = () => {
        window.open(WHATS_APP_LINK + PPA_ADMIN_PHONE_NUMBER + ADMIN_WHATSAPP_MESSAGE, "_blank");
    };
    return (
        <div className="intro-illustor-wrapper">
            <img src={introProfile} alt="" />
            <div
                        className={`${GTM_TRACKER.WHATSAPP} intro-talk-to-us-btn`}
                        onClick={openWhatsapp}
                    >
                        <img src={whatsappIcon} alt="" />
                        <p>&nbsp;Talk to us</p>
                    </div>
        </div>
    );
};

export default IntroIllustrator;
