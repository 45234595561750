import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {
    admissionFAQs,
    curriculumFAQs,
    pauseFAQs,
    courseOutcomesFAQs,
} from "../../Constants/FaqTabsConstant";
import CustomMadeAccordion from "../CustomMadeAccordion";

const useStyles = makeStyles({
    tabs: {
        "& .MuiTab-root.Mui-selected": {
            color: "#444BAB",
        },
    },
});
const tabStyles = {
    fontFamily: "Poppins-SemiBold",
    padding: "0 3vw",
    fontSize: "1.1vw",
    textTransform: "capitalize",
    whiteSpace: " noWrap",
};
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export default function TheFaqsTabs() {
    const [value, setValue] = React.useState(0);
    const [openIdAdmission, setOpenIdAdmission] = useState(1);
    const [openIdCirriculum, setOpenIdCirriculum] = useState(1);
    const [openIdPause, setOpenIdPause] = useState(1);
    const [openIdCourse, setOpenIdCourse] = useState(1);

    const classes = useStyles();

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const getTabs = (value) => {
        return (
            <>
                <Tabs
                    TabIndicatorProps={{ style: { background: "#444BAB" } }}
                    className={classes.tabs}
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                >
                    <Tab sx={tabStyles} label="Admission and Eligibility:" {...a11yProps(0)} />
                    <Tab sx={tabStyles} label="Curriculum and Schedule:" {...a11yProps(1)} />
                    <Tab sx={tabStyles} label="Pause/Resume Subscription:" {...a11yProps(2)} />
                    <Tab sx={tabStyles} label="Course Outcomes:" {...a11yProps(3)} />
                </Tabs>
            </>
        );
    };

    const recordAccordionIdAdmission = (id) => {
        if (id === openIdAdmission) setOpenIdAdmission(-1);
        else setOpenIdAdmission(id);
    };
    const recordAccordionIdCirriculum = (id) => {
        if (id === openIdCirriculum) setOpenIdCirriculum(-1);
        else setOpenIdCirriculum(id);
    };
    const recordAccordionIdPause = (id) => {
        if (id === openIdPause) setOpenIdPause(-1);
        else setOpenIdPause(id);
    };
    const recordAccordionIdCourse = (id) => {
        if (id === openIdCourse) setOpenIdCourse(-1);
        else setOpenIdCourse(id);
    };

    const getTabPanels = () => {
        return (
            <>
                <TabPanel value={value} index={0}>
                    {admissionFAQs.map((accordionData, index) => (
                        <CustomMadeAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionIdAdmission}
                            isOpen={accordionData.id === openIdAdmission}
                            action="FAQs (click)"
                        />
                    ))}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {curriculumFAQs.map((accordionData, index) => (
                        <CustomMadeAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionIdCirriculum}
                            isOpen={accordionData.id === openIdCirriculum}
                            action="FAQs (click)"
                        />
                    ))}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {pauseFAQs.map((accordionData, index) => (
                        <CustomMadeAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionIdPause}
                            isOpen={accordionData.id === openIdPause}
                            action="FAQs (click)"
                        />
                    ))}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {courseOutcomesFAQs.map((accordionData, index) => (
                        <CustomMadeAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionIdCourse}
                            isOpen={accordionData.id === openIdCourse}
                            action="FAQs (click)"
                        />
                    ))}
                </TabPanel>
            </>
        );
    };
    return (
        <Box sx={{ width: "100%", padding: "0 3vw" }}>
            <Box>{getTabs(value)}</Box>
            {getTabPanels()}
        </Box>
    );
}
