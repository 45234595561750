import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { PPA_ADMIN_PHONE_NUMBER_TELEGRAM } from "../../Constants/LandingPageConstants";
import logo from "./../../img/PpaLogo.svg";
import linkedIn from "./../../img/footerLinkedIn.png";
import facebook from "./../../img/footerFacebook.png";
import instagram from "./../../img/footerInstagram.png";
import youtube from "./../../img/footerYoutube.png";
import telegram from "./../../img/footerTelegram.png";
import curriculumPdf from "../../CurriculumPdf/RenaissanceCourseCurriculum.pdf";
import "./../LandingPage/styles/Footer.css";
import TalkToUsButton from "../Common/TalkToUsButton";
const getFooterLinks = () => {
    return (
        <div className="footer-links-section">
            <div className="footer-sec-first-column">
                <a href="https://programmingpathshala.com/">Home</a>
                <a href="/crack-coding-interviews#">Renaissance</a>
                <a href="/low-level-design#">Low Level Design</a>
                <a href="/dsa-and-algorithms-essentials#">
                    Data Structures & <br /> Algorithms Essesntials
                </a>
                {/* <a href="/become-a-frontend-engineer#">Frontend Development</a> */}
                <a href="/begin-with-c++">Begin with C and C++</a>
            </div>

            <div className="footer-sec-second-column">
                <a href="#Pricing">Pricing</a>
                <a target="_blank" rel="noreferrer" href="/stories">
                    Stories
                </a>
                <a target="_blank" rel="noreferrer" href="https://blogs.programmingpathshala.com/">
                    Blogs
                </a>
            </div>
            <div className="footer-sec-third-column">
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://forms.gle/jsepbL2id7gpkSLeA"
                    className="links-font-style"
                    to=""
                >
                    Teach With Us
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://forms.gle/nG8FArKkwNEJucqE7"
                    className="links-font-style"
                    to=""
                >
                    Get Scholarship
                </a>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>

                <Link to="/refund-policy">Refund Policy</Link>
            </div>
        </div>
    );
};

function footerSocialIcons() {
    return (
        <div className="icon-wrapper">
            <a
                href="https://www.linkedin.com/school/programming-pathshala/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={linkedIn} alt="linkedin" width={50} title="LinkedIn" />
            </a>
            <a
                href="https://www.youtube.com/channel/UC4uPfwRHPbYlmjNMiKIdWNg"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={youtube} alt="youtube" width={50} title="Youtube" />
            </a>
            <a
                href="https://www.facebook.com/Programmingpathshala.1"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={facebook} alt="facebook" width={50} title="Facebook" />
            </a>
            <a
                href="https://www.instagram.com/programmingpathshala/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={instagram} alt="instagram" width={50} title="Instagram" />
            </a>
            <a href="https://t.me/programmingpathshala" target="_blank" rel="noopener noreferrer">
                <img src={telegram} alt="telegram" width={50} title="Telegram" />
            </a>
        </div>
    );
}

const getallRightsReserved = () => {
    return (
        <div className="all-rights-reverved-text">
            All rights reserved with @Programming Pathshala
        </div>
    );
};

const getFooterLogo = () => {
    return (
        <a
            className="footer-logo"
            target="_blank"
            rel="noreferrer"
            href="https://programmingpathshala.com/"
        >
            <div className="footer-logo-wrapper">
                <img src={logo} alt="footer-ppa-logo" />
            </div>
        </a>
    );
};

const Footer = () => {
    return (
        <>
            <div className="know-more-about-us-wrapper">
                <div className="know-more-about-text-wrapper">
                    <p className="know-more-about-text">
                        Want to Know more <br /> About our Courses?
                    </p>
                    <p className="lets-hear-all-text">
                        Lets hear all about it.{" "}
                        <a href={PPA_ADMIN_PHONE_NUMBER_TELEGRAM}>Contact Us</a>
                    </p>
                    {getFooterLinks()}
                </div>
                <div className="know-more-about-logo-wrapper">
                    {getFooterLogo()}
                    <p className="we-help-text">
                        We help our users to build skills, which are valued and enabling them to
                        believe in themselves.
                    </p>
                    {footerSocialIcons()}
                </div>
            </div>
            <div className="horizontal-line">{getallRightsReserved()}</div>
            <div>
                <TalkToUsButton />
            </div>
        </>
    );
};

export default Footer;
