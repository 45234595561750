import React, { useContext, useState } from "react";

const StoriesContext = React.createContext();

export function useStoriesContext() {
    return useContext(StoriesContext);
}

export function StoriesContextProvider ({ children }) {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loaded, setLoaded] = useState(true);

    const value = {
        setIsSnackbarOpen,
        isSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        loaded, 
        setLoaded
    };

    return (
        <StoriesContext.Provider value={value}>
            {children}
        </StoriesContext.Provider>
    );
}  