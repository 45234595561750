import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { gaCourseNameMap } from "../../../courses/CourseConstants";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { startFreeTrial } from "../../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import "../styles/Pricing.css";
import bestValue from "./../../../img/bestValue.png";
import mostPopular from "./../../../img/mostPopular.png";
import subscriptionPlanTickMark from "./../../../img/subscriptionPlanTickMark.png";
import {
    SUBSCRIPTION_DURATION,
    SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES,
    SUBSCRIPTION_MAP,
} from "./PricingConstants";

const PricingPlanCard = (props) => {
    const { windowWidth } = useWindowDimensions();
    const navigate = useNavigate();
    const [perMonthPrice, setPerMonthPrice] = useState({});
    const [basePlans, setBasePlans] = useState({});
    const [discountedPlans, setDiscountedPlans] = useState({});
    const [subscriptionIdentifiers, setSubscriptionIdentifiers] = useState({});
    const { setCouponDiscountPrice, freeTrialFormFilled } = useGlobalContext();
    const redirectUser = (link) => navigate(link);

    const upgradelink = `${
        process.env.REACT_APP_NEW_DASHBOARD_URL
    }/${getCurrentCourse()}/classroom/subscription`;

    const getPriceAfterDiscount = (price, discount) => {
        return Math.floor(price - (price * discount) / 100);
    };

    const getBasePlans = () => {
        let basePlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            basePlansData[SUBSCRIPTION_MAP[subscription]] = props.isCouponValid
                ? getPriceAfterDiscount(
                      parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),
                      parseFloat(
                          props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount
                      ) -
                          parseFloat(
                              props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1]
                                  .additional_discount
                          )
                  )
                : parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp);
        }

        return basePlansData;
    };

    const getDiscountedPlans = () => {
        let discountedPlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            discountedPlansData[SUBSCRIPTION_MAP[subscription]] = getPriceAfterDiscount(
                parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),

                parseFloat(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount)
            );
        }
        return discountedPlansData;
    };

    const getSubscriptionIdentifiers = (plans) => {
        let planIdentifiers = {};
        plans &&
            plans.map((plan, index) => {
                planIdentifiers[index + 1] = plan.subscription_id;
            });
        return planIdentifiers;
    };
    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            setBasePlans(getBasePlans());
            setDiscountedPlans(getDiscountedPlans());
            setSubscriptionIdentifiers(getSubscriptionIdentifiers(props.subscriptionPlans));
        }
    }, [props.subscriptionPlans]);

    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            let pricePerMonthData = {};
            for (const subscription in SUBSCRIPTION_MAP) {
                pricePerMonthData[SUBSCRIPTION_MAP[subscription]] = Math.floor(
                    discountedPlans[SUBSCRIPTION_MAP[subscription]] /
                        SUBSCRIPTION_DURATION[SUBSCRIPTION_MAP[subscription]]
                );
            }
            setPerMonthPrice(pricePerMonthData);
        }
    }, [discountedPlans]);

    const reloadToUpgradePlan = () => {
        window.open(upgradelink, "_blank");
    };

    const reloadToSignUp = () => {
        navigate("/signup");
        // window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`, "_self");
    };

    //after isSubscribed and after login fns are changed this commented part will be removed
    const reloadToStartFreeTrial = () => {
        startFreeTrial(freeTrialFormFilled, redirectUser);
    };

    const onSubscribeHandler = (cardType) => {
        //user not logged in --> takes to sign up page
        if (!props.loggedIn) reloadToSignUp();
        //logged in cases
        //user comes for first time --> takes him to free trial page
        else if (!props.everSubscribed) reloadToStartFreeTrial();
        //user is subscribed and not on free trial --> takes him to upgrade page on dashboard
        else if (props.checkUserSubscribed && !props.checkOnFreeTrail) reloadToUpgradePlan();
        //---> free trial users and expired users can make payments
        else handleSubscriptionPlan(cardType);
    };

    const handleSubscriptionPlan = (subscriptionId) => {
        props.subscriptionPlans.map((subscription) => {
            if (subscription.subscription_id === subscriptionIdentifiers[subscriptionId]) {
                sendEventToGTM({
                    event: "initiated-payment",
                    course: `${gaCourseNameMap[getCurrentCourse()]}`,
                    subscription_id: subscription?.subscription_id,
                    subscription_name: subscription?.subscription_name,
                });
                props.initiatePayment(subscription);
            }
        });
    };
    const getCardData = (cardType) => {
        let output = {
            duration: "",
            initialPrice: "",
            finalPrice: "",
            perMonth: "",
            services: [],
            moneySaved: 0,
        };
        output.duration = SUBSCRIPTION_DURATION[cardType];
        output.perMonth = perMonthPrice[cardType];
        output.services = SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES[cardType];
        output.initialPrice = basePlans[cardType];
        output.finalPrice = discountedPlans[cardType];

        return output;
    };

    const getSubscriptionCard = (cardType) => {
        let cardData = getCardData(cardType);
        setCouponDiscountPrice(basePlans[3] - discountedPlans[3]);

        return (
            <section className="subscription-card-wrapper">
                {cardType === SUBSCRIPTION_MAP.HALF_YEARLY ? (
                    <img src={mostPopular} />
                ) : cardType === SUBSCRIPTION_MAP.YEARLY ? (
                    <img src={bestValue} />
                ) : (
                    <></>
                )}

                <h3>
                    {cardData.duration}
                    {cardType === SUBSCRIPTION_MAP.MONTHLY ? " Month" : " Months"}
                </h3>
                <div id="free-trial-users-plans-card-hr"></div>
                <div className="subscription-plan-price-saved-wrapper">
                    <h4>₹ {cardData.initialPrice} </h4>
                    {props.showSavedMoney ? (
                        <p className="money-saved-text">
                            saved ₹ {cardData.initialPrice - cardData.finalPrice} /-
                        </p>
                    ) : (
                        <></>
                    )}
                </div>

                <h2>₹ {cardData.finalPrice}</h2>

                <p>
                    which costs <span className="free-user-subscription-rupee-symbol">₹</span>
                    {cardData.perMonth}/month
                </p>
                <button
                    onClick={() => {
                        onSubscribeHandler(cardType);
                    }}
                >
                    subscribe now{" "}
                </button>
                <section className="card-services-wrapper">
                    {cardData.services.map((service, index) => {
                        return (
                            <section className="card-services-point-wrapper" key={index}>
                                <img src={subscriptionPlanTickMark} />
                                {service}
                            </section>
                        );
                    })}
                </section>
            </section>
        );
    };
    return (
        <div className="subscription-card-container">
            {Object.keys(SUBSCRIPTION_MAP).map((subscription) => (
                <>{getSubscriptionCard(SUBSCRIPTION_MAP[subscription])}</>
            ))}
        </div>
    );
};

export default PricingPlanCard;
