import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import arrowRight from "../../img/arrow-right.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreCourses.css";
import { CourseContents } from "./ExploreCoursesCardDetails";
import { availableCourses } from "../../courses/CourseConstants";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const ExploreCourses = (props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    const currentCourse = props.course;
    const getHeading = () => {
        if (currentCourse === availableCourses.PYTHON) return CourseContents.coursePythonHeading;
        if (currentCourse === availableCourses.LLD) return CourseContents.courseLLDHeading;
        if (currentCourse === availableCourses.CPP) return CourseContents.courseCPPHeading;
        if (currentCourse === availableCourses.RENAISSANCE) return CourseContents.courseRenHeading;
        if (currentCourse === availableCourses.CRASH_COURSE) return CourseContents.courseDsaHeading;
    };
    const courseDetails = CourseContents.courseCardDetails.filter(
        (item) => item.courseName !== currentCourse
    );
    const getHeader = () => {
        return (
            <div className="learning-path-header-wrapper">
                <p className="learning-path-title">
                    Explore <span className="blue-heading-tag">Our Other Courses</span>
                </p>
            </div>
        );
    };

    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {courseDetails &&
                    courseDetails.map((data, index) => (
                        <a
                            href={data.clickable ? data.courseLink : ""}
                            className="course-card"
                            key={index}
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: `${data.gaCourseTracker}`,
                                });
                            }}
                        >
                            <div className="course-card-img-wrapper">
                                <img src={data.courseBannerImg} alt="" />
                            </div>
                            <p className="course-card-desc">{data.courseDescription}</p>

                            <div className="course-card-btn">
                                <p className="know-more-txt">Know More</p>
                                <div className={"arrow-right"}>
                                    <img src={arrowRight} alt="" />
                                </div>
                            </div>
                        </a>
                    ))}
            </Slider>
        );
    };

    return (
        <div id="explore-courses" className="courses-wrapper">
            {getHeading()}
            {getSlidingStories()}
        </div>
    );
};

export default ExploreCourses;
