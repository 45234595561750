const PythonTopics = [
    {
        heading: "Syntax in Python",
        data: [
            "1.1 What is Programming?",
            "1.2 What and Why Python?",
            "1.3 Data Types and Conversions",
            "1.4 Operators",
        ],
    },
    {
        heading: "Control Flow in Python",
        data: [
            "2.1 If-Else in Python",
            "2.2 Nested If-Else in Python",
            "2.3 String and Membership Operators",
            "2.4 While Loop",
            "2.5 Nested While Loop",
            "2.6 Problems like Calculator, Primality Check, Factorial, Pattern Printing, GCD and LCM",
        ],
    },
    {
        heading: "Built-in Data Structures in Python",
        data: [
            "3.1 Lists in Python",
            "3.2 Mutability vs Immutability",
            "3.3 For Loop",
            "3.4 Tuples",
            "3.5 Range",
            "3.6 Zip",
            "3.7 Set",
            "3.8 Dictionaries",
            "3.9 Enumerate",
            "3.10 Concepts of Memory Management, Shallow vs Deep Copying, Hashable vs Unhashable",
        ],
    },
    {
        heading: "Functions in Python",
        data: [
            "4.1 What are Functions,Parameters,Arguments?",
            "4.2 Positional vs Keyword Arguments, args and kwargs",
            "4.3 Return",
            "4.4 Yield and Generators",
            "4.5 Pass by Object Reference",
            "4.6 Scope and LEGB Principle",
            "4.7 Function Call Stack and Basic Recursion",
        ],
    },
    {
        heading: "Functional Programming in Python",
        data: [
            "5.1 Intro to Different Programming Paradigms",
            "5.2 Higher Order Functions",
            "5.3 Currying",
            "5.4 Lambdas and Nested Lambdas",
            "5.5 Map, Filter, Reduce, Any and All",
            "5.6 Comprehensions",
        ],
    },
    {
        heading: "Get closer to real world Python codes in Companies ",
        data: [
            "6.1 Setting up own IDE Pycharm and own coding Environment",
            "6.2 Folder Structures",
            "6.3 Modules and Packages",
            "6.4 Exceptions, Error Handling",
            "6.5 Raising our own errors",
        ],
    },
    {
        heading: "Object Oriented Programming in Python",
        data: [
            "7.1 Classes and Objects",
            "7.2 Data Encapsulation & Hiding",
            "7.3 Constructors",
            "7.4 Inheritance",
            "7.5 Polymorphism",
            "7.6 Access Modifiers",
            "7.7 Static Methods and Variables and annotations",
        ],
    },
    {
        heading: "Problem Solving in Python",
        data: [
            "8.1 Basic problems using If-else",
            "8.2 Problems on Loops and Nested Loops",
            "8.3 Problems using Lists",
            "8.4 Problems on Strings and String Manipulation",
            "8.5 Problems using Dictionaries and Sets",
            "8.6 Carving the Way Ahead",
        ],
    }
];

export { PythonTopics };
