import React from "react";
import "./Styles/FreeTrial.css";
import whatsappIcon from "../../img/whatsapp-icon.svg";
import { useNavigate } from "react-router-dom";
import {
    WHATS_APP_LINK,
    PPA_ADMIN_PHONE_NUMBER,
    ADMIN_WHATSAPP_MESSAGE,
} from "../../Constants/LandingPageConstants";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { startFreeTrial, goToDashboard } from "../../courses/Strategies/RedirectionStrategy";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const FreeTrial = () => {
    const { freeTrialFormFilled, loggedIn, everSubscribed } = useGlobalContext();
    const navigate = useNavigate();

    const redirectUser = (link) => navigate(link);
    const openWhatsapp = () => {
        window.open(WHATS_APP_LINK + PPA_ADMIN_PHONE_NUMBER + ADMIN_WHATSAPP_MESSAGE, "_blank");
    };
    const getFreeTrialButton = () => {
        return (
            <div
                className="cpp-free-trial-btn"
                onClick={() => startFreeTrial(freeTrialFormFilled, redirectUser)}
            >
                <button>Start 7 Day Free Trial</button>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div className="cpp-free-trial-btn" onClick={() => goToDashboard()}>
                <button>Go To Dashboard</button>
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div
                className={`${GTM_TRACKER.START_FREE_TRIAL} cpp-free-trial-btn`}
                onClick={() => navigate("/signup")}
            >
                <button>Try 7-Day Free Trial</button>
            </div>
        );
    };
    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };
    return (
        <div className="cpp-freetrial-wrapper">
            <div className="cpp-free-trial-content">
                <h1>
                    Still confused? Take a free trial of our course
                    <br /> and see the difference yourself
                </h1>
                <div className="cpp-buttons-wrapper">
                    <div
                        className={`${GTM_TRACKER.WHATSAPP} cpp-talk-to-us-btn`}
                        onClick={openWhatsapp}
                    >
                        <p>Talk to us</p>
                        <img src={whatsappIcon} alt="" />
                    </div>
                    {getFreeTrailOrDashboardButton()}
                </div>
            </div>
        </div>
    );
};

export default FreeTrial;
