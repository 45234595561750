import React from "react";
import "./styles/Mentor.css";
import Vivek from "../../img/Springboot-vivek.svg";
import Amazon from "../../img/Amazon_logo.svg";
import linkedinImg from "../../img/linkedinImg.png";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

const Mentor = () => {
    const { windowWidth } = useWindowDimensions();
    return (
        <div className="springboot-mentor">
            <h2 className="springboot-mentor-heading">Learn with Industry Experienced Mentor</h2>
            <div className="springboot-mentor-mentor-info">
                <div className="springboot-mentor-img-container">
                    <div className="springboot-mentor-img-box">
                        <img src={Vivek} alt={`${Vivek}`} width={windowWidth > 600 ? 160 : 40} />
                    </div>
                    <div className="springboot-mentor-about-mobile">
                        <div>Vivekanand Vivek</div>
                        <div>
                            IIT BHU Alumni, Ex-SDE{" "}
                            <img
                                className="springboot-mentor-amazon-image"
                                src={Amazon}
                                alt={`${Amazon}`}
                            />
                        </div>
                        <a href="https://in.linkedin.com/in/vivekanand-vivek-7a4ab388">
                            <img src={linkedinImg} alt="LinkedIn" width={24} />
                        </a>
                    </div>
                </div>
                <div className="springboot-mentor-description">
                    <p className="springboot-mentor-paragraph">
                        Vivek has taught 10,000+ students through on campus workshops and online
                        courses on Interview Preparation. He has worked at Amazon with its High
                        Scale Systems for three years after graduating from IIT. He is loved by his
                        students for his lucid in-depth explanations and ability to make people
                        think through problems.
                    </p>
                    <div className="springboot-mentor-about">
                        <div>Vivekanand Vivek</div>
                        <div>
                            IIT BHU Alumni, Ex-SDE <img src={Amazon} alt={`${Amazon}`} />
                        </div>
                        <a href="https://in.linkedin.com/in/vivekanand-vivek-7a4ab388">
                            <img src={linkedinImg} alt="LinkedIn" width={24} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mentor;
