import React from "react";
import "./styles/ProjectSection.css";
import { Button } from "@mui/material";
import { CardDataProjects as CardData } from "./SpringBootData";

const Card = ({ imgSrc, heading, paragraph, tags }) => {
    return (
        <div className="springboot-project-card">
            <div className="springboot-project-image-section">
                <img src={imgSrc} alt={`${imgSrc}`} width={250} />
            </div>
            <div className="springboot-project-information-box">
                <div className="springboot-project-card-heading">{heading}</div>
                <p className="springboot-project-card-paragraph">{paragraph}</p>
                <div className="springboot-project-tags-container">
                    {tags.map((data, index) => (
                        <div className="springboot-project-tag">{data}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const ProjectSection = () => {
    return (
        <div className="springboot-project">
            <h2 className="springboot-project-heading">
                Learn by Building: Hands-On Projects for Real-World Mastery
            </h2>
            <p className="springboot-project-paragraph">
                Dive into practical, real-world projects designed to transform your theoretical
                knowledge into tangible skills, preparing you for the challenges of backend
                engineering.
            </p>
            <div className="springboot-project-card-container">
                {CardData.map((data, index) => (
                    <Card {...data} />
                ))}
            </div>
            <div className="springboot-project-button-container">
                <a href="/signup">
                    <Button variant="contained" className="springboot-project-free-trial-button">
                        Start 7-Days Free Trial
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default ProjectSection;
