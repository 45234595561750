import React from "react";
import Stars from "../../img/Stars.svg";
import RatingContainer from "../../img/RatingContainer.svg";
import "./styles/Impact.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

const Impact = () => {
    const { windowWidth } = useWindowDimensions();
    return (
        <div className="springboot-impact">
            <div>Our Impact</div>
            <div className="springboot-impact-horizontal-line" />
            <div className="springboot-impact-sub-div">
                <div>
                    16 LPA+ <p className="springboot-impact-paragraph">Avg CTC</p>
                </div>
                <div>
                    300% <p className="springboot-impact-paragraph">Avg Increment</p>
                </div>
            </div>
            <div className="springboot-impact-sub-div">
                <div>
                    20,000+ <p className="springboot-impact-paragraph">Student Taught</p>
                </div>
                <div className="springboot-impact-rating-container">
                    <div>
                        <img src={Stars} alt="Stars" height={windowWidth > 800 ? 24 : 16} /> 4.9/5
                    </div>
                    <img
                        src={RatingContainer}
                        alt="Rating Container"
                        height={windowWidth > 800 ? 48 : 32}
                    />
                </div>
            </div>
        </div>
    );
};

export default Impact;
