import React from "react";
import { useEffect } from "react";
import { availableCourses } from "../../courses/CourseConstants";
import { getCurrentCourse, setCurrentCourse } from "../../courses/CourseUtility";
import Header from "../Header/Header";
import Body from "../LandingPage/Body";
//import Footer from "../LandingPage/Footer";
import Footer from "../Footer/Footer";
// import BannerRibbon from "../Header/BannerRibbon";
import TfpRibbon from "../Header/TfpRibbon";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";

export const LandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.RENAISSANCE);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    return (
        <div className="">
            <div>{/* <BannerRibbon /> */}</div>
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <Body
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    course={availableCourses.RENAISSANCE}
                />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};
