import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styles from './styles/WinterBanner.module.css';
import RightImg from '../../resources/images/right-snow.png';
import Snowman from '../../resources/images/snowman.svg';
import Bell from '../../resources/images/bell.svg';
import { handleApplyCouponCode } from "../../Utilities/BannerUtils";

const WinterBanner = ({setCouponCode}) => {
   const location = useLocation();
  const isAuthRoute = location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/course-select" ;
  const calculateTargetTime = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setHours(24, 0, 0, 0);
    return tomorrow.getTime();
  };

  const [targetTime, setTargetTime] = useState(calculateTargetTime);

  const [timeLeft, setTimeLeft] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({
          hours: String(hours).padStart(2, '0'),
          minutes: String(minutes).padStart(2, '0'),
          seconds: String(seconds).padStart(2, '0'),
        });
      } else {
        setTargetTime(calculateTargetTime());
        clearInterval(timer);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [targetTime]);

  return (
    <div className={styles.banner}>
      <div>
        <img className={styles.snowmanImage} src={RightImg} alt="Snowman" />
      </div>
      <div>
        <img className={styles.snowmanIcon} src={Snowman} alt="Snowman" />
      </div>
      <div className={styles.timeLeftContainer}>
        <p className={styles.timeLeftLabel}>Time Left</p>
        <div className={styles.timeLeft}>
          <div className={styles.timeLeftItem}>
            <div className={styles.timeLeftBox}>
              {timeLeft.hours}
            </div>
            <span className={styles.timeLeftText}>Hrs</span>
          </div>
          <div className={styles.timeLeftItem}>
            <div className={styles.timeLeftBox}>
              {timeLeft.minutes}
            </div>
            <span className={styles.timeLeftText}>Mins</span>
          </div>
          <div className={styles.timeLeftItem}>
            <div className={styles.timeLeftBox}>
              {timeLeft.seconds}
            </div>
            <span className={styles.timeLeftText}>Secs</span>
          </div>
        </div>
      </div>
      <div className={styles.flashSaleContainer}>
        <div className={styles.flashSaleText}>
          Winter Carnival Flash Sale:{" "}
          <span className={styles.flashSaleBoldText}>Flat 30% OFF</span> on All Courses! | Apply
          Code: <span className={styles.flashSaleBoldText}>WINTER30</span>
        </div>
        <div>
            {
        !isAuthRoute &&
          (<button onClick={() => handleApplyCouponCode({ couponCode: "WINTER30", setCouponCode })} className={`winter-claim-now ${styles.claimButton}`}>
            Claim now
          </button>)
          }
        </div>
      </div>
      <div>
        <img className={styles.bellIcon} src={Bell} alt="bell" />
      </div>
      <div className={styles.snowmanRightImage}>
        <img className={styles.rightImg} src={RightImg} alt="Snowman" />
      </div>
    </div>
  );
};

export default WinterBanner;
