import React, { useRef } from "react";
import "./styles/SuccessStories.css";
import { PYTHON_SUCCESS_STORIES } from "./Content/PythonSuccessStoriesContent";
import quotesIcon from "../../img/quotes-icon.png";
import linkedin_icon from "../../img/linkedin.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SuccessStories = () => {

    const { windowWidth } = useWindowDimensions();
    let storiesSliderRef = useRef(null);

    const sliderSettings = {
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    dots: false,
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                    arrows: false,
                },
            },
        ],
    };

    const getStoriesCard = () => {
        return (
            PYTHON_SUCCESS_STORIES &&
            PYTHON_SUCCESS_STORIES.map((success_story, index) => (
                <>
                    <div className="python-success-stories-card">
                        <img
                            className="python-success-stories-card-quotes-img"
                            src={quotesIcon}
                            alt=""
                        />
                        <div className="python-success-stories-card-txt">
                            <p>{success_story.text}</p>
                        </div>
                        <img
                            className="python-success-stories-card-img"
                            src={success_story.img}
                            alt=""
                        />
                        <div className="python-success-stories-name-wrapper">
                            <p className="python-success-stories-name">{success_story.name}</p>
                            <a href={success_story.link}>
                                <img src={linkedin_icon} alt="" />
                            </a>
                        </div>
                        <div className="python-success-stories-company-logo-wrapper">
                            <p className="python-sde-text">{success_story.job_description}</p>
                            <div className={success_story.company_logo_wrapper}>
                                <img src={success_story.company_logo} alt="" />
                            </div>
                        </div>
                    </div>
                </>
            ))
        );
    }

    return (
        <div className="python-success-stories-wrapper">
            <h1>
                Success <strong>Stories</strong>
            </h1>
            <h2>They did it. So can you!</h2>
            {windowWidth < 600 ? <div className="python-stories-slider">
            <Slider ref={(slider) => {
                storiesSliderRef = slider;
            }}
                {...sliderSettings}
            >{getStoriesCard()}
            </Slider>
            <div className="python-stories-slider-arrows-wrapper">
                    <img
                        onClick={() => storiesSliderRef?.slickPrev()}
                        src={arrowPrev}
                        alt="prev"
                    />
                    <img
                        onClick={() => storiesSliderRef?.slickNext()}
                        src={arrowNext}
                        alt="next"
                    />
                </div>
            </div> 
            : <div className="python-success-stories-card-wrapper">{getStoriesCard()}</div>}
            <div
                className={`${GTM_TRACKER.SUCCESS_STORIES} python-success-stories-read-more-btn`}
                onClick={() =>
                    window.open(
                        process.env.REACT_APP_FRONTEND_MAIN_URL + "crack-coding-interviews/stories",
                        "_self"
                    )
                }
            >
                <button>Read More Stories</button>
            </div>
        </div>
    );
};

export default SuccessStories;
