import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import Intro from "./Intro";
import LogoBelt from "./LogoBelt";
import About from "./About";
import Audience from "./Audience";
import Advantage from "./Advantage";
import Curriculum from "./Curriculum";
import PreRequisites from "./PreRequisites";
import Mentor from "../Mentor/Mentor";
import SuccessStories from "./SuccessStories";
import Faq from "./Faq";
import Pricing from "./Pricing/Pricing";
import Scholarship from "./Scholarship";
import Community from "./Community";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import Cookies from "universal-cookie";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";

const DsaCrashCourseRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.CRASH_COURSE);

    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header />
            </div>
            <Intro />
            <LogoBelt />
            <About />
            <Audience />
            <Advantage />
            <Curriculum />
            <PreRequisites />
            <Mentor course={availableCourses.CRASH_COURSE} />
            <SuccessStories />
            <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            <Scholarship />
            <Community />
            <Faq />
            <ExploreCourses course={availableCourses.CRASH_COURSE} />
            <ExploreBlogs course={availableCourses.CRASH_COURSE} />
            <FreeTrial />
            <Footer />
        </div>
    );
};

export default DsaCrashCourseRoute;
