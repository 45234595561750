const ReactPrerequisitesContents = {
    sectionTitle: "Prerequisites",
    sectionDescription: (
        <>
            <span>No prior experience in programming is required.</span>
        </>
    ),
    // the css are found in commonPrerequisites.css file
    BgClassName: "react-bg-color",
};
export { ReactPrerequisitesContents };
