import lecturesImg from "../../../img/cpp-lectures.png";
import caseStudyImg from "../../../img/cpp-problems.png";
import regularLiveClasses from "../../../img/cpp-liveclasses.png";
import doubtSupportImg from "../../../img/cpp-doubts.png";
import mockInterviewImg from "../../../img/cpp-interview.png";
import problemSolvingImg from "../../../img/cpp-problemsolving.png";

const CppAboutContents = {
    sectionTitle: "Why Learn C++ from Programming Pathshala?",
    sectionDescription: (
        <>
            <p>If you are interested in learning a programming language that is powerful,</p>
            <p>versatile, and efficient, then C++ is a great choice.</p>
        </>
    ),
    AboutInfoWithImages: [
        {
            image: lecturesImg,
            desc: <span><strong>150+</strong> In-depth lectures</span>,
        },
        {
            image: caseStudyImg,
            desc: <span><strong>100+</strong> Problems covered</span>,
        },
        {
            image: doubtSupportImg,
            desc: <span>Real-time Doubt Resolution by mentors</span>,
        }, 
        {
            image: mockInterviewImg,
            desc: <span>Master Competitive Programming & Ace Interviews</span>,
        },
        {
            image: problemSolvingImg,
            desc: <span>Develop Problem solving ability</span>,
        },
    ],
};

export { CppAboutContents };
