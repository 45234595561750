import React from "react";
import "./styles/About.css";
import { PythonAboutContents } from "./Content/PythonAboutContents"; 
import pythonAboutImage from "../../img/python-about-image.png";

const About = () => {
    const aboutContents = PythonAboutContents;
  
    return (
        <div className="python-about-wrapper">
            <div className="python-about-heading">  
                <h1>Why learn <strong>Python</strong>?</h1>
            </div>
            <div className="python-about-content-wrapper">
                <div className="python-about-content">
                    <div className="python-about-image-wrapper">
                        <img
                            className="python-about-image"
                            src={pythonAboutImage}
                            alt=""
                        ></img>
                    </div>
                    <div className="python-about-description-wrapper">
                        {aboutContents.sectionSubHeading}
                        {aboutContents.sectionDescription}
                    </div>
                </div>
                <div className="python-about-items-wrapper">
                    <div className="python-about-info-wrapper">
                        {aboutContents.AboutInfoWithImages &&
                            aboutContents.AboutInfoWithImages.map((data, index) => (
                                <div key={index} className="python-about-info">
                                    <img className="python-about-icons" src={data.image} alt="" />
                                    <p>{data.desc}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>           
        </div>
    );
};

export default About;
