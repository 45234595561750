import React from "react";
import IntroIllustrator from "./IntroIllustrator";
import LandingPageContent from "./LandingPageContent";
import "./styles/Intro.css";
import AdsLiveClassForm from "./AdsLiveClassForm";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { nullCheckExistingParams } from "../../Utilities/CommonUtilities";

const Intro = () => {
    const { windowWidth } = useWindowDimensions();

    return (
        <div className="intro-container">
            <div className="intro-para-illustrator-wrapper">
                <div className="intro-words">
                    <LandingPageContent />
                </div>

                <div className="illustrator">
                    {/* only show ads live class form if utm present and it is not in mobile design */}
                    {windowWidth > 600 && !nullCheckExistingParams() ? (
                        <AdsLiveClassForm />
                    ) : (
                        <IntroIllustrator />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Intro;
