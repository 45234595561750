import axios from "axios";
import {
	HEADERS,
	HTTP_SUCCESS,
	SIGNUP_AND_LOGIN_PAGE_STEPS,
} from "../../../Constants/GlobalConstants";
import axiosInstance from "../../../Utilities/RequestWrapper";
import { nullCheckExistingParams, getUserAdDetails } from "../../../Utilities/CommonUtilities.js";
const signUp = (
	email,
	setSignUpSubmitError,
	setSignUpSubmitErrorMessage,
	setSignUpStep,
	setLoaded
) => {
    
    axiosInstance
		.post(
			process.env.REACT_APP_AUTH_URL + "/isUserRegistered",
			{
				username: email,
			},
			{ headers: HEADERS }
		)
		.then((response) => {
			if (response.status === HTTP_SUCCESS) {
				if (!(response.data.Response.isFirstLogin) && response.data.Response.isUserRegistered) {
					setLoaded(true);
					setSignUpSubmitError(true);
					setSignUpSubmitErrorMessage("This email id already exists");
					setSignUpStep(SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP);
				} else {
					//loading screen true
					setSignUpStep(SIGNUP_AND_LOGIN_PAGE_STEPS.CHANGE_PASSWORD);
                    axiosInstance
						.post(
							process.env.REACT_APP_AUTH_URL + "/signup",
							{
								username: email,
							},
							{ headers: HEADERS }
						)
						.then((response) => {
							//loading screen false
							if (response.status === HTTP_SUCCESS) {
								setLoaded(true);
								setSignUpSubmitError(false);
								setSignUpSubmitErrorMessage("");							
								// AD DETAILS 
								if(!nullCheckExistingParams()){
									axios.post(
										process.env.REACT_APP_AUTH_URL + "/persistAdUserDetails",
										{
											email: email,
											ad_details: getUserAdDetails()
										},
										{ headers: HEADERS }
									);
								}
								else {
									localStorage.removeItem('utmParameters');
								}
							}
						})
						.catch((error) => {
							setLoaded(true);
							setSignUpStep(SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP);
							setSignUpSubmitError(true);
							error &&
							error.response &&
							error.response.data &&
							error.response.data.Response &&
							error.response.data.Response.errorMessage
								? setSignUpSubmitErrorMessage(
										error.response.data.Response.errorMessage
								  )
								: setSignUpSubmitErrorMessage(error.message);
						});
				}
			}
		})
		.catch((error) => {
			setLoaded(true);
			setSignUpStep(SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP);
			setSignUpSubmitError(true);
			error &&
			error.response &&
			error.response.data &&
			error.response.data.Response &&
			error.response.data.Response.errorMessage
				? setSignUpSubmitErrorMessage(error.response.data.Response.errorMessage)
				: setSignUpSubmitErrorMessage(error.message);
		});
};

const signUpWithEmail = (
	email,
	setLoaded,
	setCurrentLoggedInUserEmail,
	setShowOtpScreen,
	navigate
) => {
	setLoaded(false);
    axiosInstance
		.post(
			process.env.REACT_APP_AUTH_URL + "/isUserRegistered",
			{
				username: email,
			},
			{ headers: HEADERS }
		)
		.then((response) => {
			if (response.status === HTTP_SUCCESS) {
				if (!(response.data.Response.isFirstLogin) && response.data.Response.isUserRegistered) {
					setLoaded(true);
					setCurrentLoggedInUserEmail(email);
					navigate('/login', { replace: true });
				} else {
                    axiosInstance
						.post(
							process.env.REACT_APP_AUTH_URL + "/signup",
							{
								username: email,
							},
							{ headers: HEADERS }
						)
						.then((response) => {
                            setLoaded(true);
							if (response.status === HTTP_SUCCESS) {
								setCurrentLoggedInUserEmail(email);
								setShowOtpScreen(true);
								navigate('/signup', { replace: true });
								// AD DETAILS 
								if(!nullCheckExistingParams()){
									axios.post(
										process.env.REACT_APP_AUTH_URL + "/persistAdUserDetails",
										{
											email: email,
											ad_details: getUserAdDetails()
										},
										{ headers: HEADERS }
									);
								}
								else {
									localStorage.removeItem('utmParameters');
								}
							}
						})
						.catch((error) => {
							setLoaded(true);
							navigate('/signup', { replace: true });
						});
				}
			}
		})
		.catch((error) => {
			setLoaded(true);
			navigate('/signup', { replace: true });
		});
}

const resendOTP =(email,currentAuthFlow)=>{
    axiosInstance
		.post(
			process.env.REACT_APP_AUTH_URL + "/resendotp",
			{
				email: email,
				signUpFlow:currentAuthFlow,
			},
			{ headers: HEADERS }
		)
}




export { signUp,resendOTP, signUpWithEmail };

