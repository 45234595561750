const faqContent = [
    {
        id: 1,
        question: "Can I Study Concurrency and Low Level Design in parallel?",
        answer: (
            <p>
                Yes, both Concurrency and Low Level Design can be studied independently or in
                parallel. As none of them are a prerequisite to each other.
            </p>
        ),
    },
    {
        id: 2,
        question: "How much duration is needed to complete this course?",
        answer: <p>6-9 months is the ideal duration required to complete this course well.</p>,
    },
    {
        id: 3,
        question: "In which language will the course be taught in?",
        answer: (
            <p>
                The course will be mainly taught in Java, as it is the only purely object oriented
                language. Nevertheless, if you code in any other language, we will be happy to help
                you out.
            </p>
        ),
    },
    {
        id: 4,
        question: "How does Doubt Support work?",
        answer: (
            <p>
                There is a discussion forum integrated on the dashboard under each video and
                problem. You can simply comment and ask your doubts there. All doubts are resolved
                by course instructors and mentors in almost real time.
            </p>
        ),
    },
    {
        id: 5,
        question: "How do Masterclasses work?",
        answer: (
            <p>
                In Masterclasses, we challenge your understanding of topics with the help of new
                questions/case-studies and also resolve your doubts. Masterclasses are scheduled on
                a rolling basis by topics and are usually one per week.
            </p>
        ),
    },
    {
        id: 6,
        question: "What is the free Pause and Resume feature?",
        answer: (
            <p>
                If you are not able to study for a while, due to health issues, exams, hectic
                projects, or anything else - you can pause your subscription for a number of days -
                and your subscription will get extended for those number of days for free. The
                content will be no longer accessible until you resume back. Your no. of pause days
                will be an additional 25% days over your subscription duration. Note: If you don’t
                take any Pause, your Free Pause Days will not be added to your Subscription Days.
            </p>
        ),
    },
    {
        id: 7,
        question:
            "Can I apply for senior software developer roles like SDE2, SDE3, etc. after finishing this course?",
        answer: (
            <p>
                Yes, you can! You will be prepared for Design and Machine Coding rounds after the
                completion of this course. We also recommend you to brush up your DSA concepts with{" "}
                <a
                    className="renaissance-link"
                    href="https://renaissance.programmingpathshala.com/crack-coding-interviews"
                >
                    Renaissance
                </a>{" "}
                to crack problem solving rounds.
            </p>
        ),
    },
];

export default faqContent;
