import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import "../ComponentsStyles/CommonIntro.css";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { goToDashboard, startFreeTrial } from "../../../courses/Strategies/RedirectionStrategy";
import { GTM_TRACKER } from "../../../GoogleAnaytics/gtmConstants";

const Intro = (props) => {
    const { IntroBannerContents } = props;

    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, subscribed, everSubscribed, freeTrialFormFilled } = useGlobalContext();

    const getFreeTrialButton = () => {
        return (
            <div>
                {" "}
                <button
                    className="common-intro-cta-btn"
                    onClick={() => {
                        startFreeTrial(freeTrialFormFilled, redirectUser);
                    }}
                >
                    Start 7 Day Free Trial
                </button>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div>
                <button className="common-intro-cta-btn" onClick={() => goToDashboard()}>
                    Go to Dashboard
                </button>
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div className={GTM_TRACKER.START_FREE_TRIAL}>
                {" "}
                <button
                    className="common-intro-cta-btn"
                    onClick={() => {
                        navigate("/signup");
                    }}
                >
                    Start 7 Day Free Trial
                </button>
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
        <>
            <div className={`common-intro-wrapper + ${IntroBannerContents.BgColorClassName}`}>
                <div className="common-intro-content">
                    {IntroBannerContents.introCourseHeading}

                    {IntroBannerContents.introCourseDescription}

                    {getFreeTrailOrDashboardButton()}

                    {IntroBannerContents.introCourseReccomends && (
                        <p className="common-recommend">
                            {IntroBannerContents.introCourseReccomends}
                        </p>
                    )}
                </div>
                <div className="common-intro-illustartor-wrapper">
                    <img
                        className={IntroBannerContents.illustratorCss}
                        src={IntroBannerContents.imageIllustrator}
                        alt=""
                    ></img>
                </div>
            </div>
            <div className="common-nav-sections">
                {IntroBannerContents.commonNavTags &&
                    IntroBannerContents.commonNavTags.map((data, index) => (
                        <Link
                            key={index}
                            smooth
                            to={`#${data}`}
                            scroll={(el) => scrollWithOffset(el)}
                        >
                            {data}
                        </Link>
                    ))}
            </div>
        </>
    );
};

export default Intro;
