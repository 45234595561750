import React from "react";
import IntroPoints from "./Content/IntroContent";
import "./styles/Intro.css";

function IntroPointsMobile() {
    return (
        <div className="intro-points-mob">
            {IntroPoints &&
                IntroPoints.map((introPoint) => (
                    <div className="intro-points">
                        <img src={introPoint.img} alt="" />
                        <p>{introPoint.text}</p>
                    </div>
                ))}
        </div>
    );
}

export default IntroPointsMobile;
