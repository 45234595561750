import axios from "axios";
import { HEADERS,
	HTTP_SUCCESS,
	FORGOT_PASSWORD_SCREENS
} from "../../../Constants/GlobalConstants";
import axiosInstance from "../../../Utilities/RequestWrapper";

const forgotPassword = (
	email,
	setOtpSent,
	setLoading,
	setForgotPasswordError,
	setForgotPasswordErrorMessage,
	setforgotPasswordScreen,
) => {
    axiosInstance
		.post(
			process.env.REACT_APP_AUTH_URL + "/forgotPassword",
			{
				username: email,
			},
			{ headers: HEADERS }
		)
		.then((response) => {
			if (response.status === HTTP_SUCCESS) {
				setLoading(false);
				setOtpSent(true);
				setForgotPasswordError(false);
				setForgotPasswordErrorMessage("");
				setforgotPasswordScreen(FORGOT_PASSWORD_SCREENS.CREATE_NEW_PASSWORD);
			}
		})
		.catch((error) => {
			setLoading(false);
			setOtpSent(false);
			setForgotPasswordError(true);
			error.response
				? setForgotPasswordErrorMessage(error.response.data.errorMessage)
				: setForgotPasswordErrorMessage(error.message);
		});
};

export { forgotPassword };
