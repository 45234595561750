const SUBSCRIPTION_MAP = {
    MONTHLY: 1,
    HALF_YEARLY: 2,
    YEARLY: 3,
};

const SUBSCRIPTION_DURATION = {
    1: 1,
    2: 6,
    3: 12,
};

const GET_PLANS_FAILURE_MESSAGE = "Unable to get plans. Please refresh.";
const INITIATE_PAYMENT_FAILURE_MESSAGE = "Unable to initiate payments. Please try again.";

const SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES = {
    1: [
        <p>Try The Course Out</p>,
        <p>
            <span>200+</span> Concept videos
        </p>,
        <p>
            <span>2-3</span> Live Master Classes
        </p>,
        <p>
            <span>15+</span> Practical LLD case studies
        </p>,
        <p>
            <span>1:1</span> Mentorship Sessions
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
    ],
    3: [
        <p>
            <span>200+</span> Concept videos
        </p>,
        <p>
            <span>40-50</span> Live Master Classes
        </p>,
        <p>
            <span>15+</span> Practical LLD case studies
        </p>,
        <p>
            <span>1:1</span> Mentorship Sessions
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
        <p>
            <span>90</span> Days of Free Subscription Pause
        </p>,
        <p>Performance Based Mock Interviews</p>,
        <p>Performance based Referrals</p>,
    ],
    2: [
        <p>
            <span>200+</span> Concept videos
        </p>,
        <p>
            <span>20-25</span> Live Master Classes
        </p>,
        <p>
            <span>15+</span> Practical LLD case studies
        </p>,
        <p>
            <span>1:1</span> Mentorship Sessions
        </p>,
        <p>
            <span>24*7</span> Doubt Support
        </p>,
        <p>
            <span>45</span> Days of Free Subscription Pause
        </p>,
        <p>Performance Based Mock Interviews</p>,
        <p>Performance based Referrals</p>,
    ],
};

export {
    SUBSCRIPTION_MAP,
    SUBSCRIPTION_DURATION,
    GET_PLANS_FAILURE_MESSAGE,
    INITIATE_PAYMENT_FAILURE_MESSAGE,
    SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES,
};
