export const NAV_LINK_CONSTANTS = {
    TECH_FELLOWSHIP_PROGRAM_URL: `${process.env.REACT_APP_FRONTEND_LANDING_PAGE_MAIN_URL}tech-fellowship-program`,
    RENAISSANCE_COURSE_URL: process.env.REACT_APP_FRONTEND_MAIN_URL,
    LOW_LEVEL_DESIGN_COURSE_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}low-level-design`,
    JAVA_SPRINGBOOT_COURSE_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}java-springboot`,
    DSA_AND_ALGORITHMS_ESSENTIALS_COURSE_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`,
    BEGIN_WITH_CPP_COURSE_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-c++`,
    BEGIN_WITH_PYTHON_COURSE_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-python`,
    BEGIN_WITH_BECOME_A_FRONTEND_ENGINEER_URL: `${process.env.REACT_APP_FRONTEND_MAIN_URL}become-a-frontend-engineer`,
    PROGRAMMING_PATHSHALA_YOUTUBE_URL: `https://www.youtube.com/@ProgrammingPathshala`,
    PUBLIC_COMMUNITY_URL: process.env.REACT_APP_COMMUNITY_URL,
    PUBLIC_EVENTS_URL: process.env.REACT_APP_EVENTS_URL,
    PUBLIC_BLOGS_URL: process.env.REACT_APP_BLOGS_URL,
};
