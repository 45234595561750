import React from "react";
import { useNavigate } from "react-router-dom";
import { DsaAudienceContent } from "./DsaCrashCourseContents/DsaAudienceContent";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import {
    goToDashboard,
    startFreeTrial,
} from "../../courses/Strategies/RedirectionStrategy";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import star from "../../img/dsa-audience-star.png";
import "./styles/Audience.css";

const Audience = () => {
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, subscribed, everSubscribed, freeTrialFormFilled } =
        useGlobalContext();

    const getFreeTrialButton = () => {
        return (
            <div className="dsa-audience-cta-btn-wrapper">
                <button
                    onClick={() => {
                        startFreeTrial(freeTrialFormFilled, redirectUser);
                    }}
                >
                    Start Free Trial
                </button>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div className="dsa-audience-cta-btn-wrapper">
                <button onClick={() => goToDashboard()}>Go to Dashboard</button>
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div
                className={`${GTM_TRACKER.START_FREE_TRIAL} dsa-audience-cta-btn-wrapper`}
            >
                {" "}
                <button onClick={() => navigate("/signup")}>
                    Try 7-Day Free Trial
                </button>
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };

    const getContent = () => {
        return (
            DsaAudienceContent.AudienceList &&
            DsaAudienceContent.AudienceList.map((data, index) => (
                <React.Fragment key={index}>
                    <div className="dsa-audience-points" key={index}>
                        <img src={star} alt="" />
                        <p>{data}</p>
                    </div>
                </React.Fragment>
            ))
        );
    }

    return (
        <div className="dsa-audience-wrapper">
            <h1>{DsaAudienceContent.sectionTitle}</h1>
            <div className="dsa-audience-points-wrapper">
                {getContent()}
                {getFreeTrailOrDashboardButton()}
            </div>
        </div>
    );
};

export default Audience;
