import React, { useEffect, useState } from "react";
import CloseButton from "../../img/AddStoryCloseButton.png";
import AttachedIcon from "../../img//AttachedIcon.png";
import "./styles/AddStoryModal.css";
import { getInstitutions, userStoryFormUpload } from "./StoriesClient";
import Snackbar from "@mui/material/Snackbar";
import { useStoriesContext } from "../../Contexts/StoriesContexts";
import { OTHER_INSTITUTION_INDEX, OTHER_INSTITUTION_NAME } from "./StoriesConstants";

function AddStoryModal(props) {
    const [personAttached, setPersonAttached] = useState(false);
    const [logoAttached, setLogoAttached] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [companyExists, setCompanyExists] = useState(true);
    const [name, setName] = useState("");
    const [personImage, setPersonImage] = useState(null);
    const [companyId, setCompanyId] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [logoImage, setLogoImage] = useState(null);
    const [story, setStory] = useState("");
    const [linkedInUrl, setLinkedInUrl] = useState("");
    const [blogLink, setBlogLink] = useState("");

    const { setIsSnackbarOpen, isSnackbarOpen, snackbarMessage, setSnackbarMessage } = useStoriesContext();

    useEffect(() => {
        getInstitutions(setInstitutionsData, setIsSnackbarOpen, setSnackbarMessage);
    }, []);

    // Functions to handle image uploads and respective states
    const handleCandidateImageUpload = (event) => {
        const length = document.getElementById(event.target.id).files.length;
        if (length === 1) {
            setPersonAttached(true);
            setPersonImage(event.target.files[0]);
        } else {
            setPersonAttached(false);
            setPersonImage(null);
        }
    };

    const handleCompanyLogoImageUpload = (event) => {
        const length = document.getElementById(event.target.id).files.length;
        if (length === 1) {
            setLogoAttached(true);
            setLogoImage(event.target.files[0]);
        } else {
            setLogoAttached(false);
            setLogoImage(null);
        }
    };

    //Function to handle select menu in company names
    const handleCompanyNameChange = (event) => {
        if (event.target.value == 0) {
            setCompanyExists(false);
        } else {
            setCompanyId(parseInt(event.target.value));
        }
    };

    //Setter function for institutions
    const setInstitutionsData = (ins) => {
        ins.push({ ins_id: OTHER_INSTITUTION_INDEX, ins_name: OTHER_INSTITUTION_NAME });
        setInstitutions(ins);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    // Getter functions for each type of input
    const getCandidateNameInput = () => {
        return (
            <div className="add-story-input-container">
                <label className="input-label">Name</label>
                <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="input-box"
                    required
                    value={name}
                    onChange={handleNameChange}
                />
            </div>
        );
    };

    const getCandidateImageInput = () => {
        return (
            <div className="add-story-input-container">
                <label htmlFor="person-image">
                    <p
                        className={
                            personAttached ? "input-label-image-uploaded" : "input-label-image"
                        }
                    >
                        {personAttached ? "Image Attached" : "+ Add Image"}{" "}
                    </p>
                    <img
                        src={AttachedIcon}
                        alt="Image Attached"
                        className="attached-icon"
                        htmlFor="person-image"
                        style={personAttached ? { display: "block" } : { display: "none" }}
                    />
                </label>
                <input
                    type="file"
                    id="person-image"
                    name="person-image"
                    onChange={handleCandidateImageUpload}
                    required
                />
                <p className="file-limit">File Size Limit: 5 MB</p>
            </div>
        );
    };

    const handleCompanyChange = (event) => {
        setCompanyName(event.target.value);
    };

    const getCandidateCompanyNameInput = () => {
        return (
            <div className="add-story-input-container">
                <label className="input-label">Company</label>
                {companyExists ? (
                    <select
                        className="input-box"
                        placeholder="Company Name"
                        onChange={handleCompanyNameChange}
                    >
                        {institutions.map((ins) => (
                            <option value={ins.ins_id} key={ins.ins_id}>
                                {ins.ins_name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        type="text"
                        placeholder="Company Name"
                        className="input-box"
                        required
                        name="companyName"
                        onChange={handleCompanyChange}
                    />
                )}
            </div>
        );
    };

    const getCandidateCompanyImageInput = () => {
        return (
            <div className="add-story-input-container">
                <label htmlFor="logo-image">
                    <p
                        className={
                            logoAttached ? "input-label-image-uploaded" : "input-label-image"
                        }
                    >
                        {logoAttached ? "Logo Attached" : "+ Add Logo"}
                    </p>
                    <img
                        src={AttachedIcon}
                        alt="Image Attached"
                        className="attached-icon"
                        htmlFor="logo-image"
                        style={logoAttached ? { display: "block" } : { display: "none" }}
                    />
                </label>
                <input
                    type="file"
                    id="logo-image"
                    name="logo-image"
                    onChange={handleCompanyLogoImageUpload}
                    required
                />
                <p className="file-limit">File Size Limit: 5 MB</p>
            </div>
        );
    };

    const handleStoryChange = (event) => {
        setStory(event.target.value);
    };

    const getCandidateStoryInput = () => {
        return (
            <div className="add-story-input-container">
                <label className="input-label">Write Story</label>
                <textarea
                    placeholder="Write here"
                    className="input-box input-textarea"
                    required
                    name="story"
                    value={story}
                    onChange={handleStoryChange}
                />
                <span className="add-story-char-counter">{`${2000 - story.length}/2000`}</span>
            </div>
        );
    };

    const handleLinkedInChange = (event) => {
        setLinkedInUrl(event.target.value);
    };

    const getCandidateLinkedinInput = () => {
        return (
            <div className="add-story-input-container">
                <label className="input-label">LinkedIn</label>
                <input
                    type="text"
                    placeholder="https://www.linkedin.com/username "
                    className="input-box"
                    required
                    value={linkedInUrl}
                    name="linkedInUrl"
                    onChange={handleLinkedInChange}
                />
            </div>
        );
    };

    const handleResetForm = () => {
        setPersonAttached(false);
        setLogoAttached(false);
        setCompanyExists(true);
        setName("");
        setPersonImage(null);
        setCompanyId(0);
        setCompanyName("");
        setLogoImage(null);
        setStory("");
        setLinkedInUrl("");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("companyId", companyId);
        formData.append("companyName", companyName);
        formData.append("story", story);
        formData.append("blogLink", blogLink);
        formData.append("linkedInUrl", linkedInUrl);
        formData.append("person-image", personImage);
        formData.append("logo-image", logoImage);
        userStoryFormUpload(formData);
    };

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    }

    const getErrorSnackbar = () => {
        return (
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        );
    };

    const handleBlogLinkChange = (event) => {
        setBlogLink(event.target.value);
    }

    const getCandidateBlogLink = () => {
        return (
            <div className="add-story-input-container">
                <label className="input-label">Blog Link</label>
                <input
                    type="text"
                    placeholder="https://programmingpathshala.medium.com/title "
                    className="input-box"
                    required
                    value={blogLink}
                    name="blogLink"
                    onChange={handleBlogLinkChange}
                />
            </div>
        )
    }

    return (
        <div className="add-story-wrapper">
            {getErrorSnackbar()}
            <button className="add-story-close" onClick={props.handleCloseStoryModal}>
                <img src={CloseButton} alt="Close Button" />
            </button>
            <div className="add-story-container">
                <p className="add-story-header">Add Success Story</p>
                <form id="add-story-form" onSubmit={handleSubmit}>
                    {getCandidateNameInput()}
                    {getCandidateImageInput()}
                    {getCandidateCompanyNameInput()}
                    {companyExists ? <></> : getCandidateCompanyImageInput()}
                    {getCandidateStoryInput()}
                    {getCandidateBlogLink()}
                    {getCandidateLinkedinInput()}
                </form>
            </div>
            <button className="add-story-reset" onClick={handleResetForm}>
                Delete this Story
            </button>
            <button form="add-story-form" className="add-story-submit">
                Publish
            </button>
        </div>
    );
}

export default AddStoryModal;