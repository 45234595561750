import React from "react";
import scholarshipImg from "../../img/cpp-scholarship.png";
import "./Styles/Scholarship.css";

const Scholarship = () => {
  return (
    <div className="cpp-scholarship-wrapper"> 
        <div className="cpp-scholarship-image-wrapper">
            <img src={scholarshipImg} alt=""/>
        </div>
        <div className="cpp-scholarship-content">
            <div className="cpp-scholarship-about">
                <span>Up-to <span className="cpp-scholarship-text">100% scholarships</span> for students in need. </span><br className="line-break" />
                <span><span className="cpp-scholarship-text">Avail now</span> and achieve your dreams.</span>
            </div>
            <div className="cpp-scholarship-button-wrapper">
                <a className="cpp-scholarship-button" href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true">
                Apply Now
                </a>
            </div> 
        </div>
    </div>
  )
}

export default Scholarship