import CaseStudiesIcon from "../../../img/case-studies.png";
import InterviewIcon from "../../../img/interview-icon.png";
import LectureIcon from "../../../img/lectures-icon.png";
import MentorshipIcon from "../../../img/mentorship.png";
import QuestionMarkIcon from "../../../img/question-mark-icon.png";

const AboutPoints = [
    {
        text: (
            <p>
                <span className="first-point">200+</span> In-depth lectures & <br />
                intermittent Live Classes.
            </p>
        ),
        img: LectureIcon,
    },
    {
        text: <p>15+ Practical LLD case studies.</p>,
        img: CaseStudiesIcon,
    },
    {
        text: (
            <p>
                Real time Doubt Resolution by
                <br /> course mentors.
            </p>
        ),
        img: QuestionMarkIcon,
    },
    {
        text: <p>1:1 Mentorship Sessions.</p>,
        img: MentorshipIcon,
    },
    {
        text: (
            <p>
                Mock Interviews & performance
                <br /> based referrals.
            </p>
        ),
        img: InterviewIcon,
    },
];

export default AboutPoints;
