import advancersIcon from "../../../img/advancers-icon.svg";
import hustlersIcon from "../../../img/hustlers-icon.svg";
import dreamersIcon from "../../../img/dreamers-icon.svg";
import preChampsIcon from "../../../img/prep-champ-icon.svg";
const AudienceInfo = [
    {
        type: "An Advancer",
        text: "Leveling up for SDE2, Senior SDE, or related roles",
        img: advancersIcon,
    },
    {
        type: "A Prep Champ",
        text: "Preparing for companies with LLD, Concurrency or Machine Coding in hiring rounds.",
        img: preChampsIcon,
    },
    {
        type: "A Dreamer",
        text: "Wanting to ace design discussions, be a rockstar code reviewer and fast track your promotion.",
        img: dreamersIcon,
    },
    {
        type: "A Hustler",
        text: "Struggling while developing, maintaining or testing a complicated concurrent application.",
        img: hustlersIcon,
    },
];

export default AudienceInfo;
