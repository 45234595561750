import React, { useState } from "react";
import "./styles/Features.css";
import ppaVsYoutube from "./../../img/ppa-vs-youtube.webp";
import thumbnail from "./../../img/personalisation-thumbnail.webp";

const Features = () => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    // set clicked to true instead of inverting it to permanently get out of thumbnail state
    // this prevents accidently clicking again to get back to thumbnail state
    setClicked(true); 
  };
  return (
    <div className="features-wrapper">
      <h1>Experience personalised learning</h1>
      <div className="personalisation-video-wrapper" onClick={handleClick}>
        {clicked ? (
          <iframe
            className="personalisation-video-wrapper"
            sandbox=""
            src="https://storage.googleapis.com/course-overview-videos/What%20is%20Personalisation%202.mp4"
            title="Experience personalised learning"
            allowFullScreen
          ></iframe>
        ) : (
          <img src={thumbnail} alt="" className="personalisaton-thumbnail" />
        )}
      </div>
      <p>Programming Pathshala vs Youtube</p>
      <img src={ppaVsYoutube} alt="" />
    </div>
  );
};

export default Features;
