import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import ArrowRight from "../../img/ArrowRight.png";
import videoBanner from "../../img/video-banner-lld-concurrency.png";
import AboutPoints from "./Content/AboutContent";
import "./styles/About.css";
import "./styles/Common.css";
const About = () => {
    const [playVideo, setPlayVideo] = useState(false);
    const { windowWidth, windowHeight } = useWindowDimensions();
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, everSubscribed, freeTrialFormFilled } = useGlobalContext();

    const getFreeTrialButton = () => {
        return (
            <div
                className="try-free-trial"
                onClick={() => startFreeTrial(freeTrialFormFilled, redirectUser)}
            >
                <p>Start 7 Day Free Trial</p>
                <img src={ArrowRight} />
            </div>
        );
    };

    const keyHighlights = () => {
        return (
            <div className="key-highlights">
                <p>
                    <strong>Our key highlights</strong>
                </p>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div className="try-free-trial" onClick={() => goToDashboard()}>
                <p>Go To Dashboard</p>
                <img src={ArrowRight} />
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div
                className={`${GTM_TRACKER.START_FREE_TRIAL} try-free-trial`}
                onClick={() => {
                    navigate("/signup");
                }}
            >
                <p>Try Our 7-Day Free Trial</p>
                <img src={ArrowRight} />
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };
    const handlePlayVideo = () => {
        setPlayVideo(true);
    };
    const poster = document.querySelector(".poster");
    if (poster) {
        poster.addEventListener("click", removePoster);
    }

    function removePoster() {
        poster.classList.add("poster-active");
        document.querySelector("iframe").src += "?autoplay=1";
    }

    return (
        <div className="about-wrapper">
            <h1>
                Why Learn <span className="blue-heading-tag">LLD and Concurrency</span>?
            </h1>
            <div className="about-contents-wrapper">
                {playVideo ? (
                    <div className="video-wrapper">
                        <iframe
                            src="https://storage.googleapis.com/landing-page-intro-videos/lld-concurrency-landing-page-video.mp4"
                            className="lld-video-player"
                            allow="autoplay"
                        ></iframe>
                        {windowWidth <= 600 ? keyHighlights() : getFreeTrailOrDashboardButton()}
                    </div>
                ) : (
                    <div className="video-wrapper" onClick={handlePlayVideo}>
                        <img src={videoBanner} alt="" />
                        {windowWidth <= 600 ? keyHighlights() : getFreeTrailOrDashboardButton()}
                    </div>
                )}
                <div className="contents-wrapper">
                    {AboutPoints &&
                        AboutPoints.map((aboutPoint) => (
                            <div className="about-points-wrapper">
                                <img src={aboutPoint.img} alt="" />
                                {aboutPoint.text}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default About;
