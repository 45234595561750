import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import "./styles/PasswordField.css";
import "./styles/CommonFunction.css";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const PasswordField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleShowPassword = () => setIsPasswordVisible(!isPasswordVisible);

  return (
    <div className="passwordfield-wrapper">
      <div htmlFor={field.name} className="passwordfield-label">
        {label}
      </div>

      <div className="passwordfield-input-wrapper">
        {meta.touched && meta.error ? <ErrorIcon className="passwordfield-error-icon" /> : <></>}

        {isPasswordVisible ? (
          <VisibilityIcon className="passwordfield-eye-icon" onClick={handleShowPassword} />
        ) : (
          <VisibilityOffIcon className="passwordfield-eye-icon" onClick={handleShowPassword} />
        )}

        <input
          className={`passwordfield-input ${
            meta.touched && meta.error && "passwordfield-input-error"
          }`}
          {...field}
          {...props}
          type={isPasswordVisible ? "text" : "password"}
          autoComplete="off"
        />
        <ErrorMessage component="div" name={field.name} className="passwordfield-error" />
      </div>
    </div>
  );
};
