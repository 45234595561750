import Cookies from "universal-cookie";
import axios from "axios";
import axiosInstance from "../../Utilities/RequestWrapper";
import { getCurrentCourse } from "../../courses/CourseUtility";
import redirectToLandingPageStrategy from "../../courses/Strategies/LandingPageRedirectionStrategy";
import { HTTP_SUCCESS, SUCCESS } from "../../Constants/GlobalConstants";
import { checkCurrentCourseAndSubscriptionStatus } from "../../courses/Strategies/RedirectionStrategy";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { gaCourseNameMap } from "../../courses/CourseConstants";

const afterInitiateFreeTrial = (response) => {
    if (response.data.Response === SUCCESS) {
        sendEventToGTM({
            event: "successful-free-trial",
            course: `${gaCourseNameMap[getCurrentCourse()]}`,
        });
        window.open(`${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`, "_self");
    } else redirectToLandingPageStrategy();
};

const startFreeTrial = (
    data,
    setFreeTrialSubmitLoading,
    setFreeTrialSubmitError,
    setFreeTrialSubmitErrorMessage,
    redirectUser,
    isSubscribedResponse,
    setStatesAfterIsSubscribed
) => {
    const cookies = new Cookies();
    axiosInstance
        .post(process.env.REACT_APP_AUTH_URL + "/updateUserDataBasedOnEmail", data, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setFreeTrialSubmitLoading(false);
            setFreeTrialSubmitError(false);
            setFreeTrialSubmitErrorMessage("");
            if (response.data.Response === "Success") {
                window.gtag("config", "AW-610341018");
                window.gtag("event", "conversion", {
                    send_to: "AW-610341018/QLqMCKzu1fEBEJqhhKMC",
                });
                initiateFreeTrialForExistingUsers();
            }
        })
        .catch((error) => {
            setFreeTrialSubmitLoading(false);
            setFreeTrialSubmitError(true);
            setFreeTrialSubmitErrorMessage(error.message);
        });
};

const initiateFreeTrialForExistingUsers = async () => {
    const cookies = new Cookies();
    await axios
        .post(
            process.env.REACT_APP_PAYMENT_SERVICE_URL + "initiateFreeTrial",
            {},
            {
                headers: {
                    authorization: cookies.get("authorization"),
                    course: getCurrentCourse(),
                },
            }
        )
        .then((response) => {
            afterInitiateFreeTrial(response);
        })
        .catch((error) => {
            console.error("Error inititating free trial for the user", error.message);
            redirectToLandingPageStrategy();
        });
};

export { startFreeTrial, initiateFreeTrialForExistingUsers };
