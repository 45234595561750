const DsaFaqContents = [
    {
        id: 1,
        question: "How much duration is needed to complete this course?",
        answer: "About 2-3 months are needed to complete the course.",
    },
    {
        id: 2,
        question: "How is this course different from Renaissance Course?",
        answer:
            "Renaissance course is a complete interview preparation course, which includes DSA, CS Fundamentals and System Design, with over 350+ hours of lectures and 600+ handpicked problems. DSA Essentials is a more concise course with 100+ handpicked and curated lectures and problems for someone who has less time to prepare for interviews. Renaissance needs over 6 months for completion, while DSA Essentials can be completed in 3 months.  ",
    },
    {
        id: 3,
        question: "How does Doubt Support work?",
        answer:
            "There is a discussion forum integrated on the dashboard under each video and problem. You can simply comment and ask your doubts there. All doubts are resolved by course instructors and mentors in almost real time. ",
    },
    {
        id: 4,
        question: "What is the free Pause and Resume feature?",
        answer:
            "If you are not able to study for a while, due to health issues, exams, hectic projects, or anything else - you can pause your subscription for a number of days - and your subscription will get extended for those number of days for free. The content will be no longer accessible until you resume back. You will get 7 Free pause days with the 1 month subscription plan, 23 pause days with the 3 month subscription plan, and 45 pause days with the 6 month subscription plan. Note: If you don’t take any Pause, your Free Pause Days will not be added to your Subscription Days.",
    },
    {
        id: 5,
        question: "Will I get a certificate for the course?",
        answer:
            "Yes, will receive a certificate for the completion of the program, subject to performance on the course.",
    },
    {
        id: 6,
        question: "I cannot pay due to financial circumstances, what should I do?",
        answer: (
            <p>
                We have scholarships for meritorious students in exceptional financial
                situations. Please apply at{"  "}
                <a href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true">
                    bit.ly/3wFhoAY
                </a>
            </p>
        ),
    },
];

export { DsaFaqContents };
