import React from "react";
import AboutRenaissance from "./AboutRenaissance";
import CourseIncluded from "./CourseIncludes";
import Intro from "./Intro";
import LearnWith from "./LearnWith";
import Slider from "./LogoBelt";
import OurCurriculum from "./OurCurriculum";
import "./../LandingPage/styles/Body.css";
import Pricing from "./Pricing/Pricing";
import TheFaqs from "./TheFaqs";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import Mentor from "../Mentor/Mentor";
import SuccessStories from "./SuccessStories";
import Faq from "./Faq";
import Advantage from "./Advantage";
import Features from "./Features";
import Audience from "./Audience";
import WhyLearn from "./WhyLearn";
import Community from "./Community";

const Body = (props) => {
    const currentCourse = props.course;
    return (
        <div className="body">
            <div id="Intro">
                <Intro />
            </div>
            <div id="logo-slider">
                <Slider />
            </div>
            <WhyLearn />
            <div className="about-renaissance">
                <AboutRenaissance />
            </div>
            <Audience />
            <Advantage />
            <div id="Our-curriculum">
                <OurCurriculum />
            </div>
            <Features />
            <Mentor course={currentCourse} />
            <SuccessStories />
            <div id="Pricing">
                <Pricing {...props} />
            </div>
            <Community />
            <Faq />
            <ExploreCourses course={currentCourse} />
            <ExploreBlogs course={currentCourse} />
            <FreeTrial />
        </div>
    );
};

export default Body;
