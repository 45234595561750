const ReactFaqContents = [
    {
        id: 1,
        question: "How much duration is needed to complete this course? ",
        answer: "The course can ideally be completed in 3 months. ",
    },
    {
        id: 2,
        question: "Will there be a project to work on as a part of this course?",
        answer: "Yes, apart from the mini projects that will be included throughout the course, there will also be a mentor-led major project at the end of the course, that will help you get hands-on practical experience with frontend development.",
    },
    {
        id: 3,
        question: "How does Doubt Support work?",
        answer: "You can ask doubts in the discussion forum present along with every video and problem, and there will also be weekly live classes where you can get your doubts resolved in person.",
    },
    {
        id: 4,
        question: "What is the free Pause and Resume feature?",
        answer: "If you are not able to study for a while, due to health issues, exams, hectic projects, or anything else - you can pause your subscription for a number of days - and your subscription will get extended for those number of days for free. Once you Pause your subscription, the content will be no longer accessible until you resume back. You get 15 Free pause days with this subscription.",
    },
    {
        id: 5,
        question: "Will I get a certificate for the course?",
        answer: "Yes, will receive a certificate for the completion of the program, subject to performance on the course.",
    },
    {
        id: 6,
        question:
            "Can I apply to Software Developer internships or jobs after finishing this course on Frontend Engineering?",
        answer: "Yes, you can apply for Frontend Engineer roles in tech companies.",
    },
    {
        id: 7,
        question: "I cannot pay due to financial circumstances, what should I do?",
        answer: (
            <p>
                We have scholarships for meritorious students in exceptional financial situations.
                Please apply at {"  "}
                <a href="https://docs.google.com/forms/u/0/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true">
                    bit.ly/3wFhoAY
                </a>
            </p>
        ),
    },
];

export { ReactFaqContents };
