import * as Yup from "yup";
import { PHONE_NUMBER_VALIDATION_REGEX, YEAR_VALIDATION_REGEX } from "../Constants/GlobalConstants";
import {
    ATLEAST_ONE_LOWERCASE,
    ATLEAST_ONE_UPPERCASE,
    ATLEAST_ONE_SPECIAL_CHARACTER,
    ATLEAST_ONE_NUMBER,
} from "../Constants/LogInFlowConstants";

const validateEmail = (email) => {
    const emailValidationRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailValidationRegex.test(String(email).toLowerCase());
};

const validateOtp = (otp) => {
    const otpValidationRegex = /^[0-9]{6,6}$/;
    return otpValidationRegex.test(String(otp));
};

const loginPageValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(40, "Password must not exceed 40 characters"),
});

const freeTrialValidationSchemaWorkingProfessional = Yup.object().shape({
    name: Yup.string()
        .required("Name is required")
        .max(30, "Name can't be more than 30 characters"),
    phoneNumber: Yup.string()
        .required("Phone no. is required")
        .matches(PHONE_NUMBER_VALIDATION_REGEX, "Phone number is not valid"),
    collegeOrOrganization: Yup.string().required("Organization is required"),
    workExperience: Yup.string().required("Work Experience is required"),
});

const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
});

const signUpValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
});

const setPasswordValidationSchema = Yup.object().shape({
    otp: Yup.string()
        .required("OTP is required")
        .matches(/^[0-9]+$/, "OTP should only contain digits")
        .min(6, "OTP must be exactly 6 digits")
        .max(6, "OTP must be exactly 6 digits"),
    newPassword: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 6 characters")
        .max(40, "Password must not exceed 40 characters"),
    confirmNewPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const emailValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
});

const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
        .required("OTP is required")
        .matches(/^[0-9]+$/, "OTP should only contain digits")
        .min(6, "OTP must be exactly 6 digits")
        .max(6, "OTP must be exactly 6 digits"),
});
const freeTrialValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required")
        .max(30, "Name can't be more than 30 characters"),
    phoneNumber: Yup.string()
        .required("Phone no. is required")
        .matches(PHONE_NUMBER_VALIDATION_REGEX, "Phone number is not valid"),
    userType: Yup.string().required("User type is required"),
    experience: Yup.string().required("Work Experience is required"),
    proficiency: Yup.string().required("Proficiency is required"),
});

const passwordValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(40, "Password must not exceed 40 characters")
        .matches(ATLEAST_ONE_LOWERCASE, "Password must contain at least 1 lowercase character")
        .matches(ATLEAST_ONE_UPPERCASE, "Password must contain at least 1 uppercase character")
        .matches(ATLEAST_ONE_NUMBER, "Password must contain at least 1 number")
        .matches(ATLEAST_ONE_SPECIAL_CHARACTER, "Password must contain at least 1 symbol")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Please fill all the fields to set your password"),
});

export {
    validateEmail,
    validateOtp,
    loginPageValidationSchema,
    forgotPasswordValidationSchema,
    setPasswordValidationSchema,
    signUpValidationSchema,
    freeTrialValidationSchema,
    freeTrialValidationSchemaWorkingProfessional,
    emailValidationSchema,
    passwordValidationSchema,
    otpValidationSchema,
};
