import React, { useContext, useState } from "react";

const PricingContext = React.createContext();

export function usePricingContext() {
    return useContext(PricingContext);
}

export function PricingContextProvider({ children }) {
    const [upgradeCouponCode, setUpgradeCouponCode] = useState("");
    const [referralOrDiscountCouponCode, setReferralOrDiscountCouponCode] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const value = {
        setUpgradeCouponCode,
        setReferralOrDiscountCouponCode,
        referralOrDiscountCouponCode,
        setLoaded,
        loaded,
        upgradeCouponCode,
        errorMessage,
        setErrorMessage,
    };

    return <PricingContext.Provider value={value}>{children}</PricingContext.Provider>;
}
