const availableCourses = {
    RENAISSANCE: "renaissance",
    LLD: "low-level-design",
    CPP: "cpp",
    REACT: "react",
    CRASH_COURSE: "crash-course",
    PYTHON: "python",
    JAVA_SPRINGBOOT: "java-springboot",
};

const COURSE_RESPONSE_IDENTIFIERS = {
    renaissance: "Response",
    "low-level-design": "LLD",
    "crash-course": "CRASH_COURSE",
    react: "REACT",
    cpp: "CPP",
    python: "PYTHON",
    "java-springboot": "JAVA_SPRINGBOOT",
};

const courseName = {
    renaissance: "Renaissance",
    "low-level-design": "Low Level Design",
    cpp: "Cpp",
    react: "React",
    "crash-course": "CRASH_COURSE",
    python: "Python",
    "java-springboot": "Java Springboot",
};

const gaCourseNameMap = {
    renaissance: "Renaissance",
    "low-level-design": "Low-Level-Design",
    cpp: "Cpp",
    react: "Frontend",
    "crash-course": "Dsa-Crash-Course",
    python: "Python",
    "java-springboot": "Java Springboot",
};

const LANDING_PAGE_ROUTE_MAP = {
    renaissance: "crack-coding-interviews",
    "low-level-design": "low-level-design",
    "crash-course": "dsa-and-algorithms-essentials",
    react: "become-a-frontend-engineer",
    cpp: "begin-with-c++",
    python: "begin-with-python",
};

module.exports = {
    availableCourses,
    courseName,
    LANDING_PAGE_ROUTE_MAP,
    COURSE_RESPONSE_IDENTIFIERS,
    gaCourseNameMap,
};
