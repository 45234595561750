import ZealVagadiaImg from "../../img/ZealVagadiaInspireStories.svg";
import YagyaModiImg from "../../img/YagyaModiInspireStories.png";
import AyushSrivastavaImg from "../../img/AyushSrivastavaInspireStories.png";
import amazon from "../../resources/images/AmazonLogoSuccessStory.png";
import google from "../../resources/images/GoogleLogoSuccessStory.png";
import paypal from "../../resources/images/PaypalLogoSuccessStory.png";

const studentsInfo = [
    {
        profileImg: ZealVagadiaImg,
        profileName: "Zeal Vagadia",
        linkedInLink: "https://www.linkedin.com/in/zeal-vagadia-77b029182/",
        profileJob: "Software Engineer at Amazon",
        companyLogo: amazon,
        profileJobDesc:
            "Whatever I am today, I owe it to Programming Pathshala and their life changing course. I think it is pretty much all we need to build problem solving skills.",
    },
    {
        profileImg: YagyaModiImg,
        profileName: "Yagya Modi",
        companyLogo: google,

        linkedInLink:
            "https://www.linkedin.com/in/yagya-modi-5085b51a9?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BPOr07wXiTXyGALySfayq7g%3D%3D",
        profileJob: "SWE at Google",
        profileJobDesc:
            "I have always been a person who wanted a teacher and some proper guidance and I think that programming Pathshala is best for it.",
    },
    {
        profileImg: AyushSrivastavaImg,
        profileName: "Ayush Srivasthava",
        companyLogo: paypal,

        linkedInLink:
            "https://www.linkedin.com/in/ayushsriv?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BXwN2YYFMRVSDY1hisym%2B%2Bw%3D%3D",
        profileJob: "SDE-2 at PayPal",
        profileJobDesc:
            "Fortunately, I came across Programming Pathshala on Quora and I was amazed to see awesome reviews. I went through the website and checked the Curriculum, and moreover the fee of the course was in my budget and not overpriced.",
    },
];

export { studentsInfo };
