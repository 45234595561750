import React from "react";
import "./Styles/Curriculum.css";
import { Cpptopics } from "./Content/CppCurriculumContent";
import downloadIcon from "../../img/download-btn-lld.png";
import CppCurriculumPdf from "../../CurriculumPdf/C++Curriculum.pdf";
import TopicCard from "./CurriculumTopicCard";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Curriculum = () => {
    const topics = Cpptopics;
    return (
        <div className="cpp-curriculum-wrapper">
            <h1>Course Curriculum</h1>
            <div className="cpp-topics-wrapper">
                {topics && topics.map((data, index) => <TopicCard data={data} index={index} />)}
            </div>
            <a
                className={GTM_TRACKER.DOWNLOAD_CURRICULUM}
                href={CppCurriculumPdf}
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className="cpp-download-curriculum">
                    <img src={downloadIcon} alt="" />
                    <p>Download Curriculum</p>
                </div>
            </a>
        </div>
    );
};

export default Curriculum;
