import Cookies from "universal-cookie";
import axiosInstance from "../../Utilities/RequestWrapper";

const checkAdmin = (setAdmin, setIsSnackbarOpen, setSnackbarMessage) => {
    const cookies = new Cookies();
    axiosInstance
        .get(process.env.REACT_APP_UTILITY_URL + "/isAdmin", {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setAdmin(response.data.Response.is_admin);
            setIsSnackbarOpen(false);
        })
        .catch((error) => {
            setIsSnackbarOpen(true);
            setSnackbarMessage("Admin check failed.");
        });
};

export { checkAdmin };
