import kunalSingal from "../../../img/kunal-singal.png";
import chetanVerma from "../../../img/chetan-verma.png";
import prakashRao from "../../../img/prakash-rao.png";
import google_icon from "../../../img/google-icon.svg";
import innovacer_icon from "../../../img/innovacer-logo.png";

import publicis_sapient_icon from "../../../img/publicis-sapient-icon.svg";

const SUCCESS_STORIES = [
    {
        name: "Kunal Singhal",
        img: kunalSingal,
        company_logo: google_icon,
        company_logo_wrapper: "google-wrapper",
        link: "https://www.linkedin.com/in/kunal1006/",
        text: "Thank you so much for the awesome course. I am feeling very much comfortable with LLD questions now. I was able to apply the right design pattern at the right place in one of my interviews with ClearTrip. Vivek sir has been very helpful throughout my journey, and I am very grateful. This course helped me crack the first LLD round of ClearTrip!",
    },
    {
        name: "Chetan Verma",
        img: chetanVerma,
        company_logo: innovacer_icon,
        company_logo_wrapper: "cn-wrapper",
        link: "https://www.linkedin.com/in/chetan-verma-633577146/",
        text: "The Programming Pathshala's Low-Level Design course deserves all the credit for empowering me with exceptional skills as a software developer. It has been an absolute game-changer. Thanks to this remarkable course, I am now skilled at navigating complex architectural challenges, evaluating multiple solutions with precision, and making informed decisions by weighing their respective advantages and disadvantages. ",
    },
    {
        name: "Prakash Rao",
        img: prakashRao,
        company_logo: publicis_sapient_icon,
        company_logo_wrapper: "ps-wrapper",
        link: "https://www.linkedin.com/in/prakash-rao-shelke/",
        text: "I was very impressed with the LLD concepts here, because with the way I learned LLD earlier, I found it very tough. But here at Programming Pathshala, it was made easier for me with great guidance by the mentors, and I started gaining confidence with my LLD skills eventually, and was able to do well in my interview.",
    },
];

export { SUCCESS_STORIES };
