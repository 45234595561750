import React from "react";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import AudienceInfo from "./Content/AudienceContent";
import "./styles/Audience.css";

const Audience = () => {
    const { windowWidth } = useWindowDimensions();

    function MobileHeading() {
        return (
            <>
                <h1>This course is for you if</h1>
                <h1>you are:</h1>
            </>
        );
    }

    function DesktopHeading() {
        return (
            <h1>
                This <span className="blue-heading-tag">course</span> is for you if you are:
            </h1>
        );
    }
    return (
        <div className="audience-wrapper">
            {windowWidth <= 600 ? <MobileHeading /> : <DesktopHeading />}
            <div className="audience-card-wrapper">
                {AudienceInfo &&
                    AudienceInfo.map((audience) => (
                        <div className="audience-card">
                            <div className="audience-img">
                                <img src={audience.img} />
                            </div>

                            <div className="audience-card-data">
                                <p>{audience.type}</p>
                                <span>{audience.text}</span>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Audience;
