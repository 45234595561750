import React, { useState } from "react";
import arrowRight from "../../img/cpp-rightarrow.png";
import arrowDown from "../../img/cpp-down-arrow.png";
import "./Styles/Curriculum.css";

const TopicCard = (props) => {
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        setClicked((prev) => !prev);
    };
    return (
    <div key={props.index}>
        <div onClick={handleClick} className="cpp-topic-card" key={props?.data?.id}>
            <div className="cpp-topic-card-heading-wrapper">
                <div className="cpp-topic-card-heading">
                    {clicked ? <img src={arrowDown} alt="" /> : <img src={arrowRight} alt="" />}
                    <p>{props.data.heading}</p>
                </div>
                <span>Module {props.data.module}</span>
            </div>
            <div className={ clicked ? "cpp-line-break" : ""}></div>
            {clicked ? props.data.subtopics &&
                props.data.subtopics.map((subtopic) => (
                    <div className="cpp-subtopics-wrapper">
                        <p>{subtopic}</p>
                    </div>
                )): <div></div>}
        </div>
    </div>
);
}

export default TopicCard;