import React from "react";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import IntroDesktop from "./IntroDesktop";
import IntroMobile from "./IntroMobile";
import "./styles/Intro.css";

const Intro = () => {
    const { windowWidth } = useWindowDimensions();
    return <>{windowWidth <= 600 ? <IntroMobile /> : <IntroDesktop />}</>;
};

export default Intro;
