import axios from "axios";
import Cookies from "universal-cookie";
import axiosInstance from "../../Utilities/RequestWrapper";

let getExperiences = (
    setStoriesAndExperiencesData,
    setIsSnackbarOpen,
    setSnackbarMessage,
    setLoaded
) => {
    axiosInstance
        .get(
            `${process.env.REACT_APP_MISC_URL}/getExperiencesAndStories`
            // "https://backend-staging.programmingpathshala.com/rest/misc/getExperiencesAndStories"
        )
        .then((response) => {
            if (response.data) {
                let latestStoriesData = (response.data && response.data.latest_stories) || [];
                let storiesData = (response.data && response.data.stories) || [];
                let lastIndexOfSlice = Math.floor(storiesData.length / 5) * 5;
                storiesData = storiesData.slice(0, lastIndexOfSlice);
                setStoriesAndExperiencesData(storiesData, latestStoriesData);
                setIsSnackbarOpen(false);
                setLoaded(true);
            }
        })
        .catch((error) => {
            setIsSnackbarOpen(false);
            setLoaded(true);
            setSnackbarMessage("Failed to fetch stories. Try Again.");
        });
};

const getInstitutions = (setInstitutions, setIsSnackbarOpen, setSnackbarMessage) => {
    const cookies = new Cookies();
    axiosInstance
        .get(
            `${process.env.REACT_APP_MISC_URL}/getInstitutions`,
            // "https://backend-staging.programmingpathshala.com/rest/misc/getInstitutions",

            {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((res) => {
            setInstitutions(res.data);
            setIsSnackbarOpen(false);
        })
        .catch((error) => {
            setIsSnackbarOpen(true);
            setSnackbarMessage("Failed to get institutions. Try Again.");
        });
};

const userStoryFormUpload = (formData, setIsSnackbarOpen, setSnackbarMessage) => {
    const cookies = new Cookies();
    axiosInstance
        .post(
            `${process.env.REACT_APP_MISC_URL}/addStory`,
            // "https://backend-staging.programmingpathshala.com/rest/misc/addStory",

            formData,
            {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((response) => {
            window.location.reload(true);
            setIsSnackbarOpen(false);
        })
        .catch((error) => {
            setIsSnackbarOpen(true);
            setSnackbarMessage("Failed to upload story. Try Again.");
        });
};

export { getExperiences, getInstitutions, userStoryFormUpload };
