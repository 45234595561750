import React, { useState } from "react";
import "./styles/CourseCurriculum.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { TabsData, InformationData } from "./SpringBootData";

const Tabs = ({ title, module, active, onClick }) => {
    return (
        <div className={`springboot-curriculum-tab ${active ? "active" : ""}`} onClick={onClick}>
            <div className="springboot-curriculum-title">{title}</div>
            <div className="springboot-curriculum-capsule">{module}</div>
        </div>
    );
};

const CourseCurriculum = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { windowWidth } = useWindowDimensions();
    return (
        <div className="springboot-coursecurriculum">
            <h2 className="springboot-curriculum-heading">Course Curriculum</h2>
            <div className="springboot-curriculum-switcher-container">
                <div className="springboot-curriculum-tabs-container">
                    {TabsData.map((data, index) => (
                        <>
                            <Tabs
                                {...data}
                                active={index === activeTab}
                                onClick={() => setActiveTab(index)}
                            />
                            <div
                                className={`springboot-curriculum-information-box mobile ${
                                    activeTab === index ? "show" : ""
                                }`}
                            >
                                <div className="springboot-curriculum-topics">Topics</div>

                                <ul className="springboot-curriculum-points">
                                    {InformationData[activeTab].map((data, index) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    ))}
                </div>
                <div className="springboot-curriculum-information-box desktop">
                    <div className="springboot-curriculum-topics">Topics</div>
                    <ul className="springboot-curriculum-points">
                        {InformationData[activeTab].map((data, index) => (
                            <li>{data}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CourseCurriculum;
