import googleImg from "../../img/sliderGoogle.png";
import amazonLogo from "../../img/sliderAmazon.png";
import microsoft from "../../img/sliderMicrosoft.png";
import adobe from "../../img/sliderAdobe.png";
import razorpay from "../../img/sliderRazorpay.png";
import flipkart from "../../img/sliderFlipkart.png";
import walmart from "../../img/sliderWalmart.png";
import paytm from "../../img/sliderPaytm.png";
import oracle from "../../img/sliderOracle.png";
import paloalto from "../../img/sliderPaloalto.png";

const CompanyLogos = [
    {
        src: googleImg,
        text: "googleImg",
        classes: "dsa-google-logo",
    },
    {
        src: flipkart,
        text: "flipkart",
        classes: "dsa-flipkart-logo",
    },
    {
        src: adobe,
        text: "adobe",
        classes: "dsa-adobe-logo",
    },
    {
        src: microsoft,
        text: "microsoft",
        classes: "dsa-microsoft-logo",
    },
    {
        src: amazonLogo,
        text: "amazon",
        classes: "dsa-amazon-logo"
    },
    {
        src: walmart,
        text: "walmart",
        classes: "dsa-walmart-logo",
    },
    {
        src: razorpay,
        text: "razorpay",
        classes: "dsa-razorpay-logo",
    },
    {
        src: paytm,
        text: "paytm",
        classes: "dsa-paytm-logo",
    },
    {
        src: oracle,
        text: "oracle",
        classes: "dsa-oracle-logo",
    },
    {
        src: paloalto,
        text: "paloalto",
        classes: "dsa-paloalto-logo"
    },
]

export default CompanyLogos;