import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowPrev from "../../../img/arrowPrev.png";
import arrowNext from "../../../img/arrowNext.png";
import "../ComponentsStyles/CommonLearners.css";
import doubleQuotationMark from "../../../img/doubleQuotationMark.png";
import linkedinImg from "../../../img/linkedin.png";
import { useNavigate } from "react-router-dom";
import LinearGradientBtn from "../styles/LinearGradientBtn";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const Learners = (props) => {
    const { studentsInfo } = props;
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 100,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };
    const getSlider = () => {
        return (
            <div className="stories-wrapper">
                <Slider {...sliderSettings}>
                    {studentsInfo.map((data, index) => (
                        <div key={index}>
                            <div className="learners-profile">
                                <div className="learners-img-wrapper">
                                    <img className="learners-img" src={data.profileImg} alt="" />
                                    <img
                                        src={data.companyLogo}
                                        className="learners-company-logo"
                                        alt=""
                                    />
                                </div>

                                <div className="learners-content-wrapper">
                                    <img className="quotation" src={doubleQuotationMark} alt="" />
                                    <div className="learners-job-desc">{data.profileJobDesc}</div>

                                    <a
                                        className="learners-name"
                                        href={data.linkedInLink}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <p>{data.profileName}</p>
                                        <img src={linkedinImg} alt="" />
                                    </a>

                                    <p className="learners-job">{data.profileJob}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    };
    const navigate = useNavigate();
    return (
        <div>
            <p className="learners-heading">What our learners say about us</p>
            {getSlider()}

            <div className="read-more-stories-wrapper">
                <div
                    className="read-more-stories-btn-container ga-suceess-stories-tracker"
                    onClick={() => navigate("/crack-coding-interviews/stories")}
                >
                    <LinearGradientBtn name="Read More Stories" />
                </div>
            </div>
        </div>
    );
};

export default Learners;
