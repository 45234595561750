import HarshitImg from "../../../img/harshit-story.png";
import turvo from "../../../img/turvo-story.png";
import BalvinderImg from "../../../img/balvinder-story.png";
import paloalto from "../../../img/paloalto-story.png";
import ZealImg from "../../../img/zeal-story.png";
import amazon from "../../../img/amazon-story.png";
import GurinderImg from "../../../img/gurinder-story.png";
import SrikantImg from "../../../img/srikant-story.png";
import AshutoshImg from "../../../img/ashutosh-story.png";
import AyushImg from "../../../img/ayush-story.png";
import paypal from "../../../img/paypal-story.png";
import YagyaImg from "../../../img/yagya-story.png";
import google from "../../../img/google-story.png";
import ChetanImg from "../../../img/chetan-story.png";
import innovacer from "../../../img/innovacer-story.png";

const RENAISSANCE_SUCCESS_STORIES = [
  {
    name: "Gurinder Kaur",
    img: GurinderImg,
    company_logo: amazon,
    company_logo_wrapper: "amazon-wrapper",
    job_description: "SDE ",
    link: "https://www.linkedin.com/in/gurinder-kaur98/",
    text: (
      <p>
        The &quot;Renaissance&quot; course is very well structured, and has
        everything to instil the skills and confidence in one to crack their
        dream company. The content, the platform, the 1:1 guidance, and
        everything is great here.
      </p>
    ),
  },
  {
    name: "Ashutosh Amrutkar",
    img: AshutoshImg,
    company_logo: paypal,
    company_logo_wrapper: "paypal-wrapper",
    job_description: "SDE",
    link: `https://www.linkedin.com/in/ashutosh-amrutkar-0b15b5169/`,
    text: "The course has well-structured lectures, weekend live classes, and comprehensive problems, covered all possible variations. There was constant support from Vivek Sir, Bharat Sir and Anoop Sir who were always accessible, and were a source of inspiration throughout.",
  },
  {
    name: "Srikant Mothe",
    img: SrikantImg,
    company_logo: amazon,
    company_logo_wrapper: "amazon-wrapper",
    job_description: "SDE",
    link: `https://www.linkedin.com/in/srikanth-mothe/`,
    text: "Vivek Sir and Bharat Sir taught each topic very elaborately and built the concept from very fundamentals. Both are excellent teachers and under their guidance, I developed my own intuition to approach any new problem of DSA.",
  },
  {
    name: "Balvinder Kaur",
    img: BalvinderImg,
    company_logo: paloalto,
    company_logo_wrapper: "amazon-wrapper",
    job_description: "SDE ",
    link: "https://www.linkedin.com/in/balvinderkaur17/",
    text: "The course covers everything in depth and in a very structured way. There is never enough to learn and grow. I felt very prepared for my interviews after being a part of this course, and could enhance my DSA and problem solving skills in greater depth.",
  },
  {
    name: "Yagya Modi",
    img: YagyaImg,
    company_logo: google,
    company_logo_wrapper: "google-wrapper",
    job_description: "SDE ",
    link: "https://www.linkedin.com/in/yagya-modi-5085b51a9/",
    text: (
      <p>
        I have always been a person who wanted a teacher and some proper
        guidance and I think that Programming Pathshala is best for it. To be
        honest, college does not matter, if you&apos;re preparing from
        Programming Pathshala.
      </p>
    ),
  },
  {
    name: "Harshit Jaiswal",
    img: HarshitImg,
    company_logo: turvo,
    company_logo_wrapper: "amazon-wrapper",
    job_description: "SDE II ",
    link: "https://www.linkedin.com/in/harshitt07/",
    text: (
      <p>
        Programming Pathshala is the best course. You don&apos;t know what you
        get until you enrol and start preparing. It is the most Value for money
        course available online and You&apos;re getting &ldquo;Gold in the price
        of Silver&rdquo;.
      </p>
    ),
  },
  // {
  //     name: "Zeal Vagadia",
  //     img: ZealImg,
  //     company_logo: amazon,
  //     company_logo_wrapper: "amazon-wrapper",
  //     job_description: "SDE ",
  //     link: "https://www.linkedin.com/in/zeal-vagadia-77b029182/",
  //     text: "Whatever I am today, I owe it to Programming Pathshala and their life changing course. I think it is pretty much all we need to build problem solving skills. The syllabus is designed in such a way that even a beginner can crack Top Tech Companies",
  // },

  // can be added in future
  // {
  //     name: "Ayush Srivasthava",
  //     img: AyushImg,
  //     company_logo: paypal,
  //     company_logo_wrapper: "paypal-wrapper",
  //     job_description: "SDE ",
  //     link: "https://www.linkedin.com/in/ayushsriv/",
  //     text: "Fortunately, came across Programming Pathshala on Quora and I was amazed to see awesome reviews. I went through the website and checked the Curriculum, and moreover the fee of the course was in my budget.",
  // },

  // {
  //     name: "Chetan Verma",
  //     img: ChetanImg,
  //     company_logo: innovacer,
  //     company_logo_wrapper: "amazon-wrapper",
  //     job_description: "SDE ",
  //     link: "https://www.linkedin.com/in/chetan-verma-633577146",
  //     text: "Thanks to the remarkable course - Low Level Design, I am now skilled at navigating complex architectural challenges, evaluating multiple solutions with precision, and making informed decisions by weighing their respective advantages and disadvantages.",
  // },
];

export { RENAISSANCE_SUCCESS_STORIES };
