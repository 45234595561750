import React from "react";
import "./../ComponentsStyles/CommonInstructors.css";
const Instructors = (props) => {
    const { InstructorsList, instructorsSectionTitle } = props;
    return (
        <div className="common-instructors-wrapper">
            <h1>{instructorsSectionTitle}</h1>
            {InstructorsList &&
                InstructorsList.map((data, index) => (
                    <div className="common-instructors-info" key={index}>
                        <div className="common-instructor-image-wrapper">
                            <img className="common-instructor-image" src={data.image} alt="" />
                            <h2>{data.name}</h2>
                            <p>{data.position},</p>
                            <p>{data.title}</p>
                        </div>
                        <div className="common-instructor-desc">{data.desc}</div>
                    </div>
                ))}
        </div>
    );
};
export default Instructors;
