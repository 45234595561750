import React from "react";
import "./Styles/About.css";
import { CppAboutContents } from "./Content/CppAboutContent";
import cppAboutImage from "../../img/cpp-about.png";

const About = () => {
    const aboutContents = CppAboutContents;
  
    return (
        <div className="cpp-about-wrapper">
            <div className="cpp-about-heading">  
                <h1>{aboutContents.sectionTitle}</h1>
                <div className="cpp-about-subheading">{aboutContents.sectionDescription}</div>
            </div>
            <div className="cpp-about-content">
                <div className="cpp-about-image-wrapper">
                    <img
                        className="cpp-about-image"
                        src={cppAboutImage}
                        alt=""
                    ></img>
                </div>
                <div className="cpp-about-items-wrapper">
                    <div className="cpp-about-info-wrapper">
                        {aboutContents.AboutInfoWithImages &&
                            aboutContents.AboutInfoWithImages.map((data, index) => (
                                <div key={index} className="cpp-about-info">
                                    <img className="cpp-about-icons" src={data.image} alt="" />
                                    <p>{data.desc}</p>
                                </div>
                            ))}
                    </div>
                </div> 
            </div>              
        </div>
    );
};

export default About;
