import beginnerImg from "../../../img/cpp-beginner.png";
import developerImg from "../../../img/cpp-developer.png";

const CppCourseForContents = {
    sectionTitle: <h1>This course is for you if you are:</h1>,
    sectionOfferings: [
        {
            img: beginnerImg,
            subheading: <h3>A Beginner</h3>,
            text: <p>Looking to get started in the world of programming<br /> and build a strong foundation in C++.</p>,
        }, 
        {
            img: developerImg,
            subheading: <h3>A Developer</h3>,
            text: <p>Looking to expand your skills<br /> 
            and become a C++ pro.</p>,
        }, 
    ],

};

export { CppCourseForContents };
