import React from "react";
import Minus from "../../img/Minus.png";
import Plus from "../../img/Plus.png";
import "./styles/faq.css";

const FaqAccordion = (props) => {
    const handleAccordionClick = () => {
        props.recordAccordionId(props.identifier);
    };

    return (
        <div onClick={handleAccordionClick} className="python-accordion-wrapper">
            <div className={(props.isOpen ? "python-accordion-background" : " ") + " python-accordion-item"}>
                {" "}
                <div className="python-accordion-question">
                    <h2>{props.accordionData.question}</h2>
                    {props.isOpen ? <img src={Minus} alt="" /> : <img src={Plus} alt="" />}
                </div>
                <div className={(props.isOpen ? "python-accodrion-show" : " ") + " python-accodrion-content"}>
                    <p>{props.accordionData.answer}</p>
                </div>
            </div>
        </div>
    );
};

export default FaqAccordion;
