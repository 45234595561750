import React from "react";
import { GoogleLoginLoader } from "./GoogleLogin/GoogleLoginLoader";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { Navigate } from "react-router-dom";

export const LoginFlow = () => {
    const cognitoAuth = new URLSearchParams(window.location.search);
    const authorization_code = cognitoAuth.get("code");

    const state = cognitoAuth.get("state");

    if (!isNULLEMPTYORUNDEFINED(authorization_code)) {
        return <GoogleLoginLoader authorization_code={authorization_code} state={state}/>;
    } else {
        return <Navigate to="/crack-coding-interviews" replace />
    }
};

export default LoginFlow;
