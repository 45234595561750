import React from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import "./Styles/PasswordValidatePopup.css";
import {
  ATLEAST_ONE_LOWERCASE,
  ATLEAST_ONE_NUMBER,
  ATLEAST_ONE_SPECIAL_CHARACTER,
  ATLEAST_ONE_UPPERCASE,
  minLength,
} from "../../../Constants/LogInFlowConstants";

const validation = (validator, label) => {
  return (
    <p className="pop-up-ticks-text">
      {validator() ? (
        <CheckSharpIcon className="common-icons" sx={{ color: "#3E8EBC" }} />
      ) : (
        <CloseSharpIcon className="common-icons validate-text-icon" />
      )}
      <span className={validator() ? "pop-up-ticks-text" : "validate-text-icon"}>{label}</span>
    </p>
  );
};

const PasswordValidatePopup = ({ password }) => {
  const checkForLength = () => minLength.test(password);
  const checkForUpperCaseLetter = () => ATLEAST_ONE_UPPERCASE.test(password);
  const checkForLowerCaseLetter = () => ATLEAST_ONE_LOWERCASE.test(password);
  const checkForNumber = () => ATLEAST_ONE_NUMBER.test(password);
  const checkForSpecialCharacter = () => ATLEAST_ONE_SPECIAL_CHARACTER.test(password);
  const isValidPassword = () =>
    checkForLength() &&
    checkForUpperCaseLetter() &&
    checkForLowerCaseLetter() &&
    checkForNumber() &&
    checkForSpecialCharacter();

  return !isValidPassword() ? (
    <div className="password-validate-pop-up-wrapper">
      <h3 className="pop-up-heading">Password must have</h3>
      <div>
        {validation(checkForLength, "At least 8 characters")}
        {validation(checkForUpperCaseLetter, "At least one uppercase letter")}
        {validation(checkForLowerCaseLetter, "At least one lowercase letter")}
        {validation(checkForNumber, "At least one number")}
        {validation(checkForSpecialCharacter, "At least one special character")}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PasswordValidatePopup;
