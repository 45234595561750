import React from "react";
import "./styles/Intro.css";
import IntroEmailInput from "./IntroEmailInput";
import { whatWeOfferList } from "../../Constants/LandingPageConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import ActionButton from "../Common/ActionButton";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import { nullCheckExistingParams, setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import introProfile from "./../../img/IntroProfiles.webp";
import AdsLiveClassForm from "./AdsLiveClassForm";

const LandingPageContent = () => {
    const { windowWidth } = useWindowDimensions();
    const style = {
        color: "white",
        background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        width:  "auto",
        padding: ".65vw 5.7vw",
        fontSize: "18px",
        whiteSpace: "noWrap",
    };
    const mobileStyle = {
        color: "white",
        background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        width: "95%",
        padding: "2vw 15px",
        fontSize: "16px",
        whiteSpace: "noWrap",
    }
    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    const navigate = useNavigate();
   
    const {
        loggedIn,
        subscribed,
        everSubscribed,
        setShowOtpScreen,
        setCurrentLoggedInUserEmail,
        freeTrialFormFilled,
    } = useGlobalContext();

    const redirectUser = (link) => navigate(link);

    const mobileStarStyle = {
        color: "#444BAB",
        fontSize: "14px",
    };
    const starStyle = {
        color: "#444BAB",
        fontSize: "1.1vw",
        marginTop: "0.1vw",
    };
    const starStyleFive = {
        color: "#8C8888",
        fontSize: "1.1vw",
        marginTop: "0.1vw",
    };
    const mobileStarStyleFive = {
        color: "#8C8888",
        fontSize: "14px",
    };

    const getIntroHeadingAndCaption = () => {
        return (
            <>
                <h1 className="renaissance-heading">Renaissance Plus:
                <span className="renaissance-caption">
                 &nbsp;Master Data<br/> Structures & Algorithms, and Full
                <br /> Stack Development
                </span></h1>
                <h3 className="renaissance-caption-subheading">
                    Become 100% Coding Interview Ready
                </h3>
            </>
        );
    };

    const getIntroWhatWeOfferList = () => {
        return (
            <>
                {whatWeOfferList &&
                    whatWeOfferList.map((data, index) => (
                        <div key={index} className="intro-list">
                            <div className="intro-images-point">
                                <img alt={data.alt} src={data.image}></img>
                            </div>
                            <div>
                                <p className="intro-list-para">{data.title}</p>
                            </div>
                        </div>
                    ))}
            </>
        );
    };
    const getFreeTrialBtnForMobile = () => {
        return (
            <div
                onClick={() => {
                    startFreeTrial(freeTrialFormFilled, redirectUser);
                }}
            >
                <ActionButton style={windowWidth < 600 ? mobileStyle : style} name="Try 7-Day Free Trial" />
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div onClick={() => goToDashboard()}>
                <ActionButton style={windowWidth < 600 ? mobileStyle : style} name="Go to Dashboard" />
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div
                className={GTM_TRACKER.START_FREE_TRIAL}
                onClick={() => {
                    navigate("/signup");
                }}
            >
                <ActionButton style={windowWidth < 600 ? mobileStyle : style} name="Try 7 Day Free Trial" />
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialBtnForMobile();
        else return getDashBoardButton();
    };

    const getTimeAndRequirement = () => {
        return (
            <>
                <div className="common-about-rating-wrapper">
                    <Rating
                        sx={{ color: "#FFC728" }}
                        name="half-rating-read"
                        defaultValue={5}
                        precision={0.2}
                        readOnly
                        size={window > 600 ? "small" : "medium"}
                    />
                    <p className="requirement-para"><strong>4.9</strong> / 5 rating by <strong>5k+</strong> Students</p>
                </div>
            </>
        );
    };

    return (
        <div className="introduction-text">
            {getIntroHeadingAndCaption()}
            <div className="intro-illustrator-mobile">
                {/* only show ads live class form if utm is present and it is mobile */}
                {!nullCheckExistingParams() ? (
                    <AdsLiveClassForm />
                ) : (
                    <img src={introProfile} alt="" />
                )}
            </div>
            <div className="intro-list-wrapper">{getIntroWhatWeOfferList()}</div>
            <div className="time-and-requirement-wrapper">{getTimeAndRequirement()}</div>
            {getFreeTrailOrDashboardButton()}
            <div>
                <p className="free-trail">
                    Watch 10+ lectures and Solve problems for <strong>free</strong>
                </p>
            </div>
        </div>
    );
};

export default LandingPageContent;
