import React from "react";
import "../ComponentsStyles/CommonCourseFor.css";
import tickMarkImg from "../../../img/ticks.svg";
import "../ComponentsStyles/CommonOutcomes.css";
const CourseFor = (props) => {
    const { courseForContents } = props;

    return (
        <div className="common-course-for-wrapper">
            <div className="common-course-for-content">
                <h2>{courseForContents.sectionTitle}</h2>
                {courseForContents.sectionSubTitle && <p>{courseForContents.sectionSubTitle}</p>}
                {courseForContents.sectionOfferings &&
                    courseForContents.sectionOfferings.map((data, index) => (
                        <div className="common-course-for-content-points" key={index}>
                            <div className="tick-mark-img-wrapper">
                                <img src={tickMarkImg} alt="" />
                            </div>
                            <p>{data}</p>
                        </div>
                    ))}

                {courseForContents.sectionSubItalicDescription && (
                    <span>{courseForContents.sectionSubItalicDescription}</span>
                )}

                <p>{courseForContents.sectionSummary}</p>
            </div>

            <div className="common-course-for-illustrator-wrapper">
                <img
                    className={
                        "common-course-for-content-image " + courseForContents.illustratorCss
                    }
                    src={courseForContents.sectionIllustrator}
                    alt=""
                ></img>
            </div>
        </div>
    );
};

export default CourseFor;
