import axios from "axios";
import {
    HEADERS,
    HTTP_SUCCESS,
    SIGNUP_AND_LOGIN_PAGE_STEPS,
} from "../../../Constants/GlobalConstants";
import { setAuthorizationCookie } from "../../../cookie-manager/CookieManager";
import { hasFilledUserDetailsForm } from "../../../courses/CourseUtility";
import axiosInstance from "../../../Utilities/RequestWrapper";
import { checkCurrentCourseAndSubscriptionStatus } from "../../../courses/Strategies/RedirectionStrategy";

const loginWithEmailAndPassword = (
    username,
    password,
    setFormSubmitError,
    setFormSubmitErrorMessage,
    setFormSubmitLoader,
    setLoginStep,
    redirectUser,
    setStatesAfterIsSubscribed,
    redirect_url
) => {
    axiosInstance
        .post(
            process.env.REACT_APP_AUTH_URL + "/login",
            {
                username: username,
                password: password,
            },
            { headers: HEADERS }
        )
        .then((response) => {
            if (response.status === HTTP_SUCCESS) {
                if (response.data.Response.AUTH_FLOW === "LOGGED_IN") {
                    afterLogin(
                        response.data.Response.IdToken,
                        setLoginStep,
                        SIGNUP_AND_LOGIN_PAGE_STEPS.LOGIN,
                        setFormSubmitLoader,
                        redirectUser,
                        setStatesAfterIsSubscribed,
                        redirect_url
                    );
                    setFormSubmitError("");
                    setFormSubmitErrorMessage("");
                } else if (response.data.Response.AUTH_FLOW === "CHANGE_PASSWORD") {
                    setFormSubmitError("");
                    setFormSubmitErrorMessage("");
                    setFormSubmitLoader(true);
                }
            }
        })
        .catch((error) => {
            setFormSubmitLoader(true);
            //setChangePasswordState(false);
            //setSignUpAndLoginStep("");
            setFormSubmitError(true);
            error.response
                ? setFormSubmitErrorMessage(error.response.data.errorMessage)
                : setFormSubmitErrorMessage(error.message);
        });
};

const afterLogin = (
    token,
    setSignUpAndLoginStep,
    currentAuthFlow,
    setLoader,
    redirectUser,
    setStatesAfterIsSubscribed,
    redirect_url
) => {
    setAuthorizationCookie(token);

    // Remove utm data after login
    localStorage.removeItem("utmParameters");

    axiosInstance
        .get(process.env.REACT_APP_UTILITY_URL + "/isSubscribed", {
            headers: {
                authorization: token,
            },
        })
        .then((response) => {
            setLoader(true);
            if (response.status === HTTP_SUCCESS) {
                if (!hasFilledUserDetailsForm(response.data)) {
                    redirectUser("/start-free-trial");
                } else {
                    checkCurrentCourseAndSubscriptionStatus(response,redirectUser,setStatesAfterIsSubscribed,redirect_url) 
                }
            }
        })
        .catch((error) => {
            setLoader(true);
            setSignUpAndLoginStep(currentAuthFlow); // set state to the current state(whatever state the user was in)
            throw error;
        });
};

export { loginWithEmailAndPassword, afterLogin };
