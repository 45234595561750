import React from "react";
import TermsBanner from "./TermsBanner/TermsBanner";
import "./TermsAndCondition.css";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
const TermsAndConditions = () => {
    return (
        <div className="terms-and-condition-wrapper">
            <TermsBanner />
            <PrivacyPolicy />
        </div>
    );
};

export default TermsAndConditions;
