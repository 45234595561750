import React from "react";
import "./styles/GoogleLogin.css";
import { useState } from "react";
import { passwordValidationSchema } from "../../../Utilities/Validation";
import { setPasswordAfterGoogleLogin } from "./GoogleClient";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import "./../../Common/styles/CommonFunction.css";
import Loader from "../../Loader/Loader";
import Header from "../../Header/Header";
import PasswordValidatePopup from "../PasswordValidatePopup/PasswordValidatePopup";
import { isNULLEMPTYORUNDEFINED } from "../../../Utilities/SessionUtility";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { PasswordField } from "../../Common/PasswordField";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";
import { goToDashboard } from "../../../courses/Strategies/RedirectionStrategy";

export const SignUpWithGoogle = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [passwordLoader, setPasswordLoader] = useState(false);
    const { subscribed, setStatesAfterIsSubscribed } = useGlobalContext();

    const redirectUser = (link) => navigate(link, { replace: true });

    const setNewPassword = () => {
        return (
            <div className="google-form-wrapper">
                {passwordLoader && <Loader />}
                <h2 className="common-page-title">Set Your Password</h2>

                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: "",
                    }}
                    validationSchema={passwordValidationSchema}
                    onSubmit={(values) => {
                        setPasswordLoader(true);
                        setPasswordAfterGoogleLogin(
                            values.password,
                            values.confirmPassword,
                            setPasswordLoader,
                            redirectUser,
                            setStatesAfterIsSubscribed
                        );
                    }}
                >
                    {(formik) => (
                        <Form>
                            <div style={{ position: "relative" }}>
                                <PasswordField label="Set Password" name="password" />
                                <div className="password-validate-popup">
                                    <PasswordValidatePopup password={formik.values.password} />
                                </div>
                            </div>
                            <PasswordField label="Confirm Password" name="confirmPassword" />
                            <button className="google-signup-otp-confirm-btn" type="submit">
                                Set Password and Continue
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    };

    const redirectIfInvalidState = () => {
        if (subscribed)
            // window.open(process.env.REACT_APP_NEW_DASHBOARD_URL, "_self");
            goToDashboard();
        else if (
            isNULLEMPTYORUNDEFINED(cookies.get("id_token")) ||
            isNULLEMPTYORUNDEFINED(cookies.get("access_token"))
        )
            // window.open(process.env.REACT_APP_FRONTEND_MAIN_URL, "_self");
            redirectToLandingPageStrategy();
    };

    return (
        <>
            {redirectIfInvalidState()}
            <div className="header-sticky">
                <Header />
            </div>
            <section className="google-login-screen-wrapper">{setNewPassword()}</section>
        </>
    );
};
