import React from "react";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import prerequisiteImg from "../../img/pre-requisites-img-right.png";
import "./styles/Prerequisites.css";

const Prerequisites = () => {
    const { windowWidth } = useWindowDimensions();
    return (
        <div className="lld-pre-requisites-wrapper">
            <div className="lld-pre-requisites-image-left">
                <img src={prerequisiteImg} alt="background image" />
            </div>
            <div className="lld-pre-requisites-content">
                <h1>But wait, what are the pre-requisites for this course?</h1>
                {windowWidth <= 600 ? (
                    <span>
                        <p>Fair command on any programming language.</p>
                        <p className="basic-knowledge">Course is taught in Java, so some basic</p>
                        <p>background in Java will be helpful.</p>
                    </span>
                ) : (
                    <span>
                        <strong>Fair command on any programming language.</strong>
                        <br /> Course is taught in Java, so some{" "}
                        <strong>basic background in Java</strong> will be helpful.{" "}
                    </span>
                )}
            </div>
            <div className="lld-pre-requisites-image-right">
                <img src={prerequisiteImg} alt="background image" />
            </div>
        </div>
    );
};

export default Prerequisites;
