const privatePolicyData = [
    {
        title: "Introduction",
        content:
            "Protecting your private information is our priority. This Statement of Privacy applies to the Site and Programming Pathshala and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Programming Pathshala include the Site and Programming Pathshala. The Programming Pathshala website is a Technical Interview Preparation site. By using the Programming Pathshala website, you consent to the data practices described in this statement.",
    },
    {
        title: "Collection of your Personal Information",
        content:
            "If you purchase Programming Pathshala's products and services, we collect billing and credit/debit card information. This information is used to complete the purchase transaction. Programming Pathshala encourages you to review the privacy statements of websites you choose to link to from Programming Pathshala so that you can understand how those websites collect, use and share your information. Programming Pathshala is not responsible for the privacy statements or other content on websites outside of the Programming Pathshala website.",
    },
    {
        title: "Use of your Personal Information",
        content:
            "Programming Pathshala collects and uses your personal information to operate its website(s) and deliver the services you have requested.  Programming Pathshala may also use your personally identifiable information to inform you of other products  Programming Pathshala. Programming Pathshala may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. Programming Pathshala does not sell, rent or lease its customer lists to third parties. Programming Pathshala may share data with trusted parties to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Programming Pathshala, and they are required to maintain the confidentiality of your information.  Programming Pathshala will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on  Programming Pathshala; (b) protect and defend the rights or property of  Programming Pathshala; and, (c) act under exigent circumstances to protect the personal safety of users of  Programming Pathshala, or the public.",
    },
    {
        title: "Automatically Collected Information",
        content:
            "Information about your computer hardware and software may be automatically collected by  Programming Pathshala. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the  Programming Pathshala website.",
    },
];

const rightsAndObligationsData = [
    {
        content:
            "Violating or attempting to violate the integrity or security of the Platform or any content therein;",
    },
    {
        content:
            "Transmitting any information (including messages and hyperlinks) on or through the Platform that is disruptive or competitive to the provision of our Services;",
    },
    {
        content:
            "Reproducing, duplicating, copying, selling, reselling or exploiting for any commercial purpose any Programming Pathshala’s Content or any portion thereof, other than as expressly allowed under this Terms of Service;",
    },
    {
        content:
            "Using the Programming Pathshala’s Marks in connection with, or to transmit, any unsolicited communications or emails or for any other unauthorised purpose;",
    },
    {
        content:
            "Intentionally submitting on the Platform any incomplete, false or inaccurate information;",
    },
    {
        content:
            "Using any engine, software, tool, agent or other device or mechanism (such as spiders, robots etc.) to navigate or search the Platform;",
    },
    {
        content:
            "Attempting to decipher, decompile, disassemble, alter, modify or reverse engineer any part of the Platform;",
    },
    {
        content:
            "Copying or duplicating in any manner any of the Programming Pathshala Content or other information available from the Platform;",
    },

    {
        content:
            "Posting or transmitting, or cause to be posted or transmitted, any communication or solicitation or other “phishing”, “pharming” or “whaling” message designed or intended to obtain password, account, personal information, or confidential information from any other User or any other third party whatsoever;",
    },
    {
        content:
            "Using or launching any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the Platform in a manner that sends more request messages to Programming Pathshala’s servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser; ",
    },
    {
        content:
            "Circumventing or disabling any digital rights management, usage rules, or other security features of the Platform; or allowing any third party to access the Platform through your account unless Programming Pathshala specifically allows you to do so.",
    },
    {
        content:
            "You shall not host, display, upload, modify, publish, transmit, update or share any information/content that: belongs to another person and to which the User does not have any right; ",
    },
];

export { privatePolicyData, rightsAndObligationsData };
