const DsaAudienceContent = {
    sectionTitle: "Who is this Course for?",
    AudienceList: [
        "Anyone who wants to build problem solving skills",
        "Working professionals nervous about DSA interview rounds",
        "Students who want to crack Developer roles",
        "Anyone who wants to brush up their DSA concepts",
    ],
};

export { DsaAudienceContent };
