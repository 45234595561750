import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Outcome from "./Outcome";
import Curriculum from "./Curriculum";
import Prerequisites from "./PreRequisites";
import Mentor from "../Mentor/Mentor";
import Scholarship from "./Scholarship";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import SuccessStories from "./SuccessStories";
import Pricing from "./Pricing/Pricing";
import Faq from "./Faq";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import Cookies from "universal-cookie";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import {
    setUTMParametersInLocalStorage,
    setUtmCouponCodeInCookie,
    useQuery,
} from "../../Utilities/CommonUtilities";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";

const CppLandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.CPP);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);
    setUtmCouponCodeInCookie(query);

    return (
        <div>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header />
            </div>
            <Intro />
            <About />
            <Audience />
            <Outcome />
            <Curriculum />
            <Prerequisites />
            <Mentor course={availableCourses.CPP} />
            <SuccessStories />
            <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            <Scholarship />
            <Faq />
            <ExploreCourses course={availableCourses.CPP} />
            <ExploreBlogs course={availableCourses.CPP} />
            <FreeTrial />
            <Footer />
        </div>
    );
};

export default CppLandingPageRoute;
