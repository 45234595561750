import IntroLearnIcon from "../../../img/cpp-learn-icon.png";
import IntroDevelopIcon from "../../../img/cpp-develop-icon.png";
import IntroBeginnerIcon from "../../../img/cpp-beginner-icon.png";
import IntroTimerIcon from "../../../img/cpp-timer-icon.png";

const CppIntroBannerContents = {
    introCourseHeading: <h1>Begin Programming with C++</h1>,
    introCourseDescription: (
        <h2 className="cpp-intro-desc">
           Become a C++ Pro and take your first step <br />
           towards becoming a programmer
        </h2>
    ),

    commonNavTags: [
        "About",
        "Learning Outcomes",
        "Who this course is for",
        "Prerequisites",
        "Course Curriculum",
        "Instructor",
        "Pricing",
        "FAQs",
    ],
    introPoints: [
        {
            text: <span>Start your path in software development, competitive Programming and more.</span>,
            img: IntroLearnIcon
        },
        {
            text: <span>Learn problem solving, variables, data types, control structures, etc.</span>,
            img: IntroDevelopIcon
        },
        {
            text: <span>Beginner Friendly</span>,
            img: IntroBeginnerIcon
        },
        {
            text: <span><strong>Estimated Duration:</strong> 3 months</span>,
            img: IntroTimerIcon
        },
    ],
};

export { CppIntroBannerContents };
