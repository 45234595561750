const Dsatopics = [
    {
        id: 1,
        module: 1,
        heading: "Gearing Up",
        subtopics: [
            {
                title: "Time and Space Complexity",
                noOfLectures: 4,
            },
            {
                title: "Recursion",
                noOfLectures: 8,
            },
            {
                title: "Backtracking",
                noOfLectures: 11,
            },
            {
                title: "Arrays",
                noOfLectures: 5,
            },
        ],
    },
    {
        id: 2,
        module: 2,
        heading: "Fundamental Algorithms",
        subtopics: [
            {
                title: "Searching",
                noOfLectures: 7,
            },
            {
                title: "Sorting",
                noOfLectures: 13,
            },
            {
                title: "Two Pointers",
                noOfLectures: 8,
            },
            {
                title: "Bit Manipulation",
                noOfLectures: 5,
            },
        ],
    },
    {
        id: 3,
        module: 3,
        heading: "Fundamental Data Structures",
        subtopics: [
            {
                title: "Hashing",
                noOfLectures: 10,
            },
            {
                title: "Stack",
                noOfLectures: 8,
            },
            {
                title: "Queue and Deque",
                noOfLectures: 10,
            },
            {
                title: "Linked List",
                noOfLectures: 13,
            },
            {
                title: "Binary Trees",
                noOfLectures: 27,
            },
            {
                title: "Binary Search Tree",
                noOfLectures: 18,
            },
        ],
    },
    {
        id: 4,
        module: 4,
        heading: "Advanced DSA",
        subtopics: [
            {
                title: "Heaps",
                noOfLectures: 8,
            },
            {
                title: "Greedy",
                noOfLectures: 7,
            },
            {
                title: "Dynamic Programming",
                noOfLectures: 24,
            },
            {
                title: "Graphs",
                noOfLectures: 29,
            },
            {
                title: "Tries",
                noOfLectures: 8,
            },
        ],
    },
];

export { Dsatopics };
