import React, { useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./styles/Curriculum.css";

const TopicCard = (props) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked((prev) => !prev);
  };
  return (
    <div key={props.index}>
      <div onClick={handleClick} className="dsa-topic-card" key={props?.data?.id}>
        <div className="dsa-topic-card-heading-wrapper">
          <div
            className={
              clicked
                ? "dsa-topic-card-clicked-heading"
                : "dsa-topic-card-heading"
            }
          >
            {clicked ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            <p>{props.data.heading}</p>
          </div>
          <span>Module {props.data.module}</span>
        </div>
        <div className={clicked ? "dsa-line-break" : ""}></div>
        <div
          className={
            (clicked ? "dsa-module-topics" : " ") + " dsa-module-content"
          }
        >
          {props.data.subtopics &&
            props.data.subtopics.map((subtopic) => (
              <div className="dsa-subtopics-wrapper">
                <p>{subtopic.title}</p>
                <p>{subtopic.noOfLectures} Lectures</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TopicCard;
