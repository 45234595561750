import BharatImg from "../../../img/bharat.svg";
import utkarshImg from "../../../img/Utkarsh.svg";

const ReactInstructorsList = [
    {
        name: "Bharat Khanna",
        image: BharatImg,
        position: "Co-Founder",
        title: "IIT BHU, Ex- Tower Research Capital",
        desc: "Bharat has worked in Tower Research Capital for two years after graduating from IIT. For his love at competitive coding, he is a candidate master at Codeforces and has mentored hundreds of students to crack their dream company. He is empathetic, patient and loves to teach budding programmers and to be incredible Software Engineers.",
    },
];

const instructorsSectionTitle = "Your Instructors";

export { ReactInstructorsList, instructorsSectionTitle };
