import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import axiosInstance from "../Utilities/RequestWrapper";
import { isNULLEMPTYORUNDEFINED, removeCookiesAndLogout } from "../Utilities/SessionUtility";
import { getCurrentCourseIsSubscribedResponseIdentifier } from "../courses/CourseUtility";

const GlobalContext = React.createContext();

export function useGlobalContext() {
    return useContext(GlobalContext);
}

export function GlobalContextProvider({ children }) {
    const [currentLoggedInUserEmail, setCurrentLoggedInUserEmail] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [onFreeTrial, setOnFreeTrial] = useState(false);
    const [errorSnackbar, setErrorSnackbar] = useState(false);
    const [globalErrorMessage, setGlobalErrorMessage] = useState("");
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [everSubscribed, setEverSubscribed] = useState(false);
    const [freeTrialFormFilled, setFreeTrialFormFilled] = useState(false);

    const [checkForUpperCaseLetter, setCheckForUpperCaseLetter] = useState(false);
    const [checkSpecialCharacter, setCheckSpecialCharacter] = useState(false);
    const [checkCharacterLength, setCheckCharacterLength] = useState(false);
    const [couponDiscountPrice, setCouponDiscountPrice] = useState();
    const [isValidPassword, setIsValidPassword] = useState(false);

    // multiple courses
    const [isSubscribedResponse, setIsSubscribedResponse] = useState({});

    const initiateLogout = () => removeCookiesAndLogout();

    const setCurrentLoggedInUserInState = () => {
        const cookies = new Cookies();
        let token = cookies.get("authorization");
        if (!token) return;
        let decodedToken = jwt_decode(token);
        setCurrentLoggedInUserEmail(decodedToken.email);
    };

    const setStatesAfterIsSubscribed = (response) => {
        //keeping loggedin flag and current user email seperate from isSubscribed responses
        setLoggedIn(true);
        setCurrentLoggedInUserInState();
        
        setIsSubscribedResponse && setIsSubscribedResponse(response);
       

        if (Object.keys(response.data).length === 0) {
            return;
        }
        setFreeTrialFormFilled(true);
        const courseIsSubscribedResponseIdentifier =
            getCurrentCourseIsSubscribedResponseIdentifier();

        if (response && response.data && response.data[courseIsSubscribedResponseIdentifier]) {
            setEverSubscribed(
                response.data[courseIsSubscribedResponseIdentifier].Subscription.everSubscribed
            );
            setSubscribed(response.data[courseIsSubscribedResponseIdentifier].Subscription.status);

            setOnFreeTrial(
                response.data[courseIsSubscribedResponseIdentifier].Subscription.onTrial
            );
        }else{
            //course identifier is not present in isSubscribed response ---> set all the flags back to false
            setEverSubscribed(false);
            setSubscribed(false);
            setOnFreeTrial(false);
        }
    };

    const checkSubscribed = async () => {
        const cookies = new Cookies();
        return await axiosInstance
            .get(process.env.REACT_APP_UTILITY_URL + "/isSubscribed", {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            })
            .then((response) => {
                setStatesAfterIsSubscribed(response);
                return response;
            })
            .catch((error) => {
                console.error(error);
                initiateLogout();
                throw error;
            });
    };

    const value = {
        setCurrentLoggedInUserInState,
        loggedIn,
        subscribed,
        onFreeTrial,
        currentLoggedInUserEmail,
        setCurrentLoggedInUserEmail,
        initiateLogout,
        showOtpScreen,
        setShowOtpScreen,
        couponDiscountPrice,
        setCouponDiscountPrice,
        checkForUpperCaseLetter,
        setCheckForUpperCaseLetter,
        checkSpecialCharacter,
        setCheckSpecialCharacter,
        checkCharacterLength,
        setCheckCharacterLength,
        isValidPassword,
        setIsValidPassword,
        checkSubscribed,
        everSubscribed,
        setStatesAfterIsSubscribed,
        isSubscribedResponse,
        setIsSubscribedResponse,
        freeTrialFormFilled
    };
    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}
