import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import AdvantagesSection from "./AdvantagesSection";
import AdvantagesContent from "./Content/AdvantagesContent";
import "./styles/Advantages.css";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const Advantages = () => {
    const { windowWidth, windowHeight } = useWindowDimensions();
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    function SlidingStories() {
        return (
            <Slider {...sliderSettings}>
                {AdvantagesContent &&
                    AdvantagesContent.map((data, index) => (
                        <div className="advantages-slider-card">
                            <div className="advantages-slider-card-img-wrapper">
                                <img src={data.img} alt="" />
                            </div>
                            <p className="advantages-slider-card-desc">{data.text}</p>
                        </div>
                    ))}
            </Slider>
        );
    }

    function MobileHeading() {
        return (
            <>
                <h1>Get the Programming</h1>
                <h1>Pathshala Advantage</h1>
            </>
        );
    }

    function DesktopHeading() {
        return (
            <h1>
                Get the <span className="blue-heading-tag">Programming Pathshala</span> Advantage
            </h1>
        );
    }

    return (
        <div className="advantages-wrapper">
            {windowWidth <= 600 ? <MobileHeading /> : <DesktopHeading />}
            <p>Your learning outcomes will be :</p>
            <div className="advantages-card-wrapper">
                {windowWidth <= 600 ? <SlidingStories /> : <AdvantagesSection />}
            </div>
        </div>
    );
};

export default Advantages;
