import axios from "axios";
import Cookies from "universal-cookie";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import logo from "../../../img/PpaLogo.svg";
import axiosInstance from "../../../Utilities/RequestWrapper";
import {
    GET_PLANS_FAILURE_MESSAGE,
    INITIATE_PAYMENT_FAILURE_MESSAGE,
} from "../../LandingPage/Pricing/SubscriptionConstants";

export const getPlans = (setPlans, getErrorDisplayModal, setLoaded) => {
    const cookies = new Cookies();
    setLoaded(true);
    try {
        axiosInstance
            .get(process.env.REACT_APP_PAYMENT_SERVICE_URL + "subscriptions", {
                headers: {
                    authorization: cookies.get("authorization"),
                    course: getCurrentCourse(),
                },
            })
            .then((response) => {
                setLoaded(false);
                if (response && response.data && response.data.Response) {
                    let plans = response.data.Response;
                    plans.sort((a, b) => parseInt(a.mrp) - parseInt(b.mrp));
                    setPlans(plans);
                }
            })
            .catch((error) => {
                setLoaded(false);
                getErrorDisplayModal(GET_PLANS_FAILURE_MESSAGE);
            });
    } catch (error) {
        setLoaded(false);
        getErrorDisplayModal(GET_PLANS_FAILURE_MESSAGE);
    }
};

export const updatePlans = (
    couponCode,
    setReferralOrDiscountCouponCode,
    setCouponPlans,
    setIsCouponValid,
    setIsModalVisible,
    setShowSavedMoney,
    setLoaded,
    setErrorSnackbar
) => {
    let data = {
        code: couponCode,
    };
    try {
        setLoaded(true);
        axios
            .post(process.env.REACT_APP_PAYMENT_SERVICE_URL + "applyReferralOrDiscount", data, {
                headers: {
                    course: getCurrentCourse(),
                },
            })
            .then((response) => {
                setLoaded(false);
                setIsModalVisible(true);
                if (response && response.data && response.data.Response) {
                    let plans = response.data.Response;
                    plans.sort((a, b) => parseInt(a.mrp) - parseInt(b.mrp));
                    setCouponPlans(plans);
                    setIsCouponValid(true);
                    setShowSavedMoney(true);
                }
            })
            .catch((error) => {
                setLoaded(false);
                setReferralOrDiscountCouponCode("");
                setErrorSnackbar(true);
                setShowSavedMoney(false);
            });
    } catch (error) {
        setErrorSnackbar(true);
    }
};

//the headers part is commented in initiate payments which will be changed once login flow is finalized
export const implementInitiatePayments = (
    data,
    setPaymentDetails,
    placeOrderAndShowRz,
    currentLoggedInUserEmail,
    setPaymentsId,
    setLoaded,
    setPaymentErrorModal,
    setErrorMessage
) => {
    const cookies = new Cookies();
    setLoaded(true);
    axios
        .post(process.env.REACT_APP_PAYMENT_SERVICE_URL + "initiatePayment", data, {
            headers: {
                authorization: cookies.get("authorization"),
                course: getCurrentCourse(),
            },
        })
        .then((Response) => {
            setLoaded(false);
            let apiRes = Response.data.Response;
            let { orderId, key, amount, currency } = apiRes;

            setPaymentDetails(orderId, key, amount, currency, "");
            placeOrderAndShowRz(
                currentLoggedInUserEmail,
                orderId,
                key,
                amount,
                currency,
                setPaymentsId
            );
        })
        .catch((error) => {
            setLoaded(false);
            setPaymentErrorModal(true);
            setErrorMessage(INITIATE_PAYMENT_FAILURE_MESSAGE);
        });
};

const directToTheDashBoard = () => {
    setTimeout(() => {
        // window.open(process.env.REACT_APP_NEW_DASHBOARD_URL, "_self");
        window.open(`${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`, "_self");
    }, 6000);
};

export const placeOrderAndShowRz = (email, orderId, key, amount, currency, setPaymentsId) => {
    try {
        let name = email.split("@")[0];
        var options = {
            key: `${key}`,
            amount: `${amount}`.toString(),
            currency: `${currency}`,
            name: "Programming Pathshala",
            description: "Subscription Payment",
            image: { logo },
            order_id: `${orderId}`,
            handler: (response) => {
                setPaymentsId(response.razorpay_payment_id, { amount: amount, currency: currency });
                directToTheDashBoard();
            },
            prefill: {
                name,
                email,
                contact: "",
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#444BAB",
            },
            config:{
                display: { hide: [{ method: 'upi', flows: [ "qr"] }]}
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (e) {}
};
