import beginProgramming from "../img/begin-programming.png";
import problemSolving from "../img/problem-solving.png";
import csFundamentals from "../img/cs-fundamentals.png";
import lldFundamentals from "../img/lld-fundamentals.png";
import fullStackDev from "../img/full-stack-dev.png";
import systemDesign from "../img/system-design.png";
import lldConcurrency from "../img/lld-concurrency.png";
import backendDev from "../img/backend-dev.png";
import competitiveProgramming from "../img/competitive-prog.png";

const curriculumModuleDetails = [
  {
    moduleImage: beginProgramming,
    moduleTitle: (
      <p>
        Begin with Programming <span className="optional-text">(Optional)</span>
      </p>
    ),
    duration: "4-6 Weeks",
    moduleTopics:
      <p>Never even wrote a &#39;Hello World!&#39; program? Pick a language from C/C++ or Python and solve exciting problems 😀</p>,
    subTopics: [
      {
        heading: "C and C++",
        content: [
          "Learn how computers run your programs, understand data-types, operators, dealing with Input Output in depth.",
          "Super Important constructs: if-else, loops, multi-dimensional arrays and strings. Learn to think through a problem, create an algorithm and use the above constructs to code them like a pro",
          "Concept of Memory and Pointers, user-defined functions and types like structures.",
          "Grow stronger with STL, use data structures and code some tricky problems on the way.",
        ],
      },
      {
        heading: "Python",
        content: [
          "Learn how the computer runs your python code, compiled vs interpreted languages, data-types, operators, Input Output, If-else conditions and Loops",
          "Lists, Tuples, Zips, Sets and Dictionaries.",
          "Functions, Yields and Generators, Scope, LEGB principle and Recursion, Functional Programming, Currying and Lambdas, Map/Filter/Reduce and Comprehensions.",
          "OOPS concepts and challenging coding exercises.",
        ],
      },
    ],
  },
  {
    moduleImage: problemSolving,
    moduleTitle: "Problem Solving in Data Structures and Algorithms",
    duration: "12-16 Weeks",
    moduleTopics:
      "Give yourself an in-depth expertise in Data Structures and Algorithms with high quality video lectures, superior set of questions and immersive live classes, support system and face your next DSA interview with zero fear",
    subTopics: [
        {
            heading: "Learning Journey:",
            content: [
                "Arrays, dynamic arrays, pre-computations, mathematical algorithms and many more. Learn to write optimal programs.",
                "Dive deep into recursion, backtracking, searching, sorting, two-pointers and bit level hacks. Solve challenging problems and get out of your comfort.",
                "Important Data Structures : Hashmaps, Stacks, Queues, Linked lists, Trees, BST, N-ary trees. Take your problem solving skills to the next level using these.",
                "Advanced Topics : Dynamic Programming, Heaps, Greedy Algorithms, Tries, Range Queries and Graphs. Face the last hurdle. Finish these, then call yourself an expert in DSA and ace your interviews.",
            ]
        },
        {
            heading: "Supporting you:",
            content: [
                "We know learning DSA is hard! So we have a highly responsive discussion forum to respond to your doubts, both via text and video streaming.",
                "People often get stuck somewhere in their learning journey, so our mentors who have already guided many folks are available to have 1:1s with you and help you get back on track.",
                "Live problem solving matters!!! Our weekend live classes are super engaging and they help you learn the art of communicating your approaches in a live interview.",
                "Lastly, to test your interview readiness, we mock interview you once you are ready for it, help you shine your resume and explore job opportunities"
            ],
        },
    ],
  },
  {
    moduleImage: csFundamentals,
    moduleTitle: "Computer Science Fundamentals",
    duration: "4 Weeks",
    moduleTopics: "Stronger you are with fundamentals of OS, DBMS and Computer Networks, deeper you understand a web/app framework or any industry scale project",
    subTopics: [
        {
            heading: "Operating System:",
            content: [
                "Processes and their life-cycle management.",
                "Scheduling Algorithms.",
                "Threads vs Processes, Monitors, mutexes and synchronization. Classical problems related to synchronization.",
                "Context switch and Interprocess communication.",
                "Virtualisation, Memory Model, Segmentation, Fragmentation, Paging and Thrashing.",
            ],
        },
        {
          heading: "Database Management System",
          content: [
              "Intro and types of databases",
              "Entity Relation design.",
              "Writing SQL queries.",
              "Transactions and ACID properties.",
              "Normalization techniques.",
          ],
      },
        {
            heading: "Computer Networks",
            content: [
                "OSI Model and different layers.",
                "Connection-oriented, connectionless and distance-vector routing.",
                "Broadcasting and multicasting algorithms",
                "IP Addressing, VPNs, Firewalls.",
                "Error and Flow control, congestion control.",
                "Three-way handshake, TCP vs UDP, Application Layer and Socket Programming.",
            ],
        },
    ]
  },
  {
    moduleImage: lldFundamentals,
    moduleTitle: "Fundamentals of Low Level Design and System Design",
    duration: "4 Weeks",
    moduleTopics: "Understand the building blocks of design and how to use them to weave an extensible and scalable system, learn to structure your design thought process",
    subTopics: [
        {
            heading: "Low Level Design:",
            content: [
                "Intro to clean, modular and industry-style coding practices.",
                "Practical understanding of Object Oriented Programming Concepts.",
                "SOLID design principles.",
                "Builder, Decorator and other useful design patterns.",
                "Case studies like designing a parking lot, game of chess, food delivery app etc",
                "Enjoy solving frequently asked interview questions during intermittent live classes.",
            ],
        },
        {
          heading: "High Level Design:",
          content: [
            "Understand web architecture and building blocks. ",
            "Databases, indexing, sharding and CAP theorem.",
            "REST APIs, load balancers, consistent hashing, proxies and message queues.",
            "Caching strategies, eviction, invalidation etc",
            "Case studies : URL Shortener, Online IDE.",
          ],
        },
    ],
   
  },
];

const curriculumsubjectsData = [
  "Data Structures and Algorithms",
  "System Design",
  "Low Level Design",
  "Fundamentals of Computer Science",
];

const curriculumElectivesDetails = [
  {
    moduleImage: fullStackDev,
    moduleTitle: "Frontend Development with ReactJS & Capstone Project",
    duration: "12 Weeks",
    moduleTopics:
      "Build an End to End Project in trending Tech Stack of JavaScript, with frameworks like NodeJS, ReactJS.  ",
    subTopics: [
        {
            heading: "HTML/CSS/JavaScript",
            content: [
                "HTML tags", 
                "DOM Tree",
                "CSS and Layout : Flexbox/Grid frameworks",
                "Working with Figma Design",
                "Media queries and responsiveness",
                "SEO",
                "Bootstrap, Tailwind CSS",
                "Javascript Internals: Basic concepts, EventLoops, WebAPIs, Hoisting, Closures, Debouncing, Throttling and many more",
                "More on Async JS, Promises and Error Handling",
                "Mini Project in JS",
            ],
        },
        {
            heading: "React development",
            content: [
                "Basics of React and Building our first app",
                "Components vs Containers",
                "State and Props",
                "React components Lifecycle methods",
                "React Hooks, Router",
                "Using Local Storage and Cookies",
                "Redux",
                "Deployment and next steps, Version Control:Git",
                "Major Project",
            ],
        },
    ],
  },
  {
    moduleImage: lldConcurrency,
    moduleTitle: "Low Level Design and Concurrency for Experienced Professionals",
    duration: "12 Weeks",
    moduleTopics: "Master in-depth skills for building a robust, extensible, testable and concurrent system. Design and review systems like a pro 😎",
    subTopics: [
        {
          heading: "Basics/Refresher:",
            content: [
            "Clean coding",
            "OOPS concepts",
            "SOLID principles and their practical applications.",
        ]
        },
        {
          heading: "Advanced Design:",
          content: [
            "Important Design Patterns : Builder, Factory, Abstract Factory, Strategy, Iterator, Observer, Decorator, Chain-of-Responsibility, State, Command, Singleton.",
            "10+ intensive case studies like In-memory MySQL, Ecommerce App etc with complete code.",
            "Testing : Unit tests, Junit, Inversion of Control, Dependency Injection, Mockito, PowerMock etc.",
          ],
        },
        {
          heading: "Concurrent Programming:",
          content: [
            "Threads, Race Conditions and Locks.",
            "Compound actions and thread safety measures.",
            "Memory Visibility and thread synchronization.",
            "Concurrent machine coding exercises.",
            "Thread Signaling and Deadlocks.",
            "Custom locks and synchronisers like latch, semaphore etc",
            "Thread pools and Non-blocking Algorithms.",
          ],
        },
    ],
  },
  {
    moduleImage: backendDev,
    moduleTitle: "Backend development in Java Springboot & capstone Project",
    duration: "12 Weeks",
    moduleTopics: "Excel in Java Springboot and Hibernate with Projects. ",
    subTopics: [
        {
          heading: "Java:",
            content: [
                "Clean Code and OOPS Concepts",
                "Reflective Programming",
                "Annotations",
                "Exception Handling",
                "Database Programming and JDBC",
                "Generics",
                "Socket Programming",
            ],
        },
        {
          heading: "General Technologies:",
          content: [
            "Build Automation Tool : Maven and dependency management practices.",
            "Version Control: Git",
            "ORMs",
            "Microservices, Monoliths.",
            "Message Queues, Caches",
            "Unit testing",
          ],
        },
        {
          heading: "Core Development:",
          content: [
            "IoC Container, Beans, Autowirings, component scan etc…",
            "MVC Architecture, REST APIs, CRUD operations, Controllers",
            "Hibernate, JPA",
            "Authentication and Authorisation",
            "Spring Security, Spring Data",
            "Spring boot Actuator endpoints.",
            "Databases, SQL/NoSQL",
            "Jackson: Serialisation/Deserialisation",
            "Mini and Major Projects",
          ],
        },
    ],
  },
];

export {
  curriculumModuleDetails,
  curriculumsubjectsData,
  curriculumElectivesDetails,
};
