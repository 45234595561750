import SliderArchit from "../../img/SliderArchit.png";
import SliderTansuh from "../../img/SliderTansuh.png";
import amazonLogo from "./../../img/AmazonLogo.png";
import BharatpeLogo from "./../../img/BharatpeLogo.png";
import GoogleLogo from "./../../img/GoogleLogo.png";
import SharechatLogo from "./../../img/SharechatLogo.png";
import SliderGurinderKaur from "./../../img/SliderGurinderKaur.png";
import SliderShivani from "./../../img/SliderShivani.png";

let OTHER_INSTITUTION_INDEX = 0;
let OTHER_INSTITUTION_NAME = "Other";

let STORIES_SLIDER_DATA = [
    {
        content:
            "Honestly, there is nothing that the course Lacks, everything about the course “Renaissance” is exceptionally well structured. The content, the platform, the guidance, and everything else is Great. It made me feel so confident about the Interviews, as I have only given 2 Interviews in my life before I applied to Amazon.  The guidance that I received from Programming Pathshala helped me a lot, I gave a lot of Mock Interviews and it boosted my confidence to go through the Amazon’s Interview process. The 1:1 sessions that are conducted here are very much helpful to build someone’s confidence.",
        name: "Gurinder Kaur",
        companyLogo: amazonLogo,
        personImage: SliderGurinderKaur,
        linkedInLink: "https://www.linkedin.com/in/gurinder-kaur98/",
        blog: "https://programmingpathshala.medium.com/my-journey-to-amazon-sde-ff00dcf1a3f4",
        backgroundPaint: "#FDF0C8",
    },
    {
        content:
            "Programming Pathshala played a huge role in my success. For me, this course, “Renaissance” was enough. The content was precise and up to the mark. After joining Programming Pathshala, I started learning from the basics and then went to advanced topics. The course made me consistent in solving problems. I became confident after that and Cracked my dream company",
        name: "Archit Dwivedi",
        companyLogo: GoogleLogo,
        personImage: SliderArchit,
        linkedInLink: "https://www.linkedin.com/in/archit-dwevedi",
        blog: "https://programmingpathshala.medium.com/my-interview-experience-with-google-poland-as-swe-629496582655",
        backgroundPaint: "#ECFDF5",
    },
    {
        content:
            "Vivek bhaiya’s teaching and Bharat bhaiya’s guidance has always been top-notch and kept me hooked with my preparations, Even though I had gotten rejected from Top-Tech companies in past, but having Amazon and Sharechat’s offer in my hands felt surreal and I am definitely proud of myself. All thanks to Programming Pathshala.",
        name: "Shivani Mehta",
        companyLogo: SharechatLogo,
        personImage: SliderShivani,
        linkedInLink: "https://www.linkedin.com/in/shivani7n7/",
        blog: "https://programmingpathshala.medium.com/how-i-became-an-sde-after-hailing-from-bca-bachelors-in-computer-applications-background-86d7ecff6d2a",
        backgroundPaint: "#E8EDFB",
    },
    {
        content:
            "I Started with coding and got to know about Programming Pathshala in a workshop. I opted for a three months Renaissance course to prepare well. I started being consistent with the course and with each discussion session that were taken by mentors here, I began getting better at coding.",
        name: "Tanush Angural",
        companyLogo: BharatpeLogo,
        personImage: SliderTansuh,
        linkedInLink: "https://www.linkedin.com/in/tanush-angural-53b1b516a/",
        blog: "https://programmingpathshala.medium.com/our-journey-to-bharatpe-sde-intern-3b08bff298e0",
        backgroundPaint: "#ECFDF5",
    },
];

let SUCCESS_STORIES_BOX_QUANTITY = {
    sectionOdd: 3,
    sectionEven: 2,
};

let NUMBER_OF_PATTERNS = 6;

export {
    NUMBER_OF_PATTERNS,
    OTHER_INSTITUTION_INDEX,
    OTHER_INSTITUTION_NAME,
    STORIES_SLIDER_DATA,
    SUCCESS_STORIES_BOX_QUANTITY,
};
