import React from "react";
import "./styles/About.css";
import { DsaAboutContents } from "./DsaCrashCourseContents/DsaAboutContents";
import dsaAboutImage from "../../img/dsa-about.webp";

const About = () => {
    const aboutContents = DsaAboutContents;

    return (
        <div className="dsa-about-wrapper">
            <div className="dsa-about-heading">
                <h1>{aboutContents.sectionTitle}</h1>
                <div className="dsa-about-subheading">{aboutContents.sectionDescription}</div>
            </div>
            <div className="dsa-about-content">
                <div className="dsa-about-image-wrapper">
                    <img
                        className="dsa-about-image"
                        src={dsaAboutImage}
                        alt=""
                    ></img>
                </div>
                <div className="dsa-about-items-wrapper">
                    <div className="dsa-about-info-wrapper">
                        {aboutContents.AboutInfoWithImages &&
                            aboutContents.AboutInfoWithImages.map((data, index) => (
                                <div key={index} className="dsa-about-info">
                                    <img className="dsa-about-icons" src={data.image} alt="" />
                                    <p>{data.desc}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
