import React from "react";
import "../ComponentsStyles/CommonAbout.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { goToDashboard, startFreeTrial } from "../../../courses/Strategies/RedirectionStrategy";
import { GTM_TRACKER } from "../../../GoogleAnaytics/gtmConstants";

/* intentionally kept for future if required */
// const mobileStarStyle = {
//     color: "#444BAB",
//     fontSize: "14px",
// };
// const starStyle = {
//     color: "#444BAB",
//     fontSize: "1.1vw",
//     marginTop: "0.1vw",
// };
// const starStyleFive = {
//     color: "#8C8888",
//     fontSize: "1.1vw",
//     marginTop: "0.1vw",
// };
// const mobileStarStyleFive = {
//     color: "#8C8888",
//     fontSize: "14px",
// };

const About = (props) => {
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, everSubscribed, freeTrialFormFilled } = useGlobalContext();

    const { aboutContents } = props;
    const getFreeTrialButton = () => {
        return (
            <button
                onClick={() => {
                    startFreeTrial(freeTrialFormFilled, redirectUser);
                }}
            >
                Start Free Trial
            </button>
        );
    };

    const getDashBoardButton = () => {
        return <button onClick={() => goToDashboard()}>Go to Dashboard</button>;
    };

    const getSignupButton = () => {
        return (
            <button
                className={GTM_TRACKER.START_FREE_TRIAL}
                onClick={() => {
                    navigate("/signup");
                }}
            >
                Start 7 Day Free Trial
            </button>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };
    return (
        <div className="common-about-wrapper">
            <div className="common-about-content">
                <h1>{aboutContents.sectionTitle}</h1>
                <div className="common-about-description-wrapper">
                    {aboutContents.sectionDescription}
                </div>
                <div className="common-about-button-wrapper">
                    {getFreeTrailOrDashboardButton()}
                    {/* intentionally kept for future if required */}
                    {/* <div className="common-about-rating-wrapper">
                        <StarIcon style={windowWidth > 600 ? starStyle : mobileStarStyle} />
                        <StarIcon style={windowWidth > 600 ? starStyle : mobileStarStyle} />
                        <StarIcon style={windowWidth > 600 ? starStyle : mobileStarStyle} />
                        <StarIcon style={windowWidth > 600 ? starStyle : mobileStarStyle} />
                        <StarIcon style={windowWidth > 600 ? starStyleFive : mobileStarStyleFive} />
                        <p>4.9/5 ratings</p>
                    </div> */}
                </div>
            </div>
            <div className="about-items-wrapper">
                <div className="vertical-bar"></div>
                <div className="common-about-info-wrapper">
                    {aboutContents.AboutInfoWithImages &&
                        aboutContents.AboutInfoWithImages.map((data, index) => (
                            <div key={index} className="common-about-info">
                                <img className="common-about-icons" src={data.image} alt="" />
                                <p>{data.desc}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default About;
