const PythonFaqsContent = [
    {
        id: 1,
        question: "What opportunities will open up for me after learning python?",
        answer:
            <p>
                After learning python, there is a lot that you can do. You can learn Machine Learning, Data Science, study Data Structures and Algorithms for software development roles, learn Web Development with Django, and participate in Competitive Programming as well. We can help you get on a call with a mentor from industry for further guidance if needed.
            </p>,
    },
    {
        id: 2,
        question: "How much duration is needed to complete this course?",
        answer:
            <p>
                About 2-3 months are needed to complete the course.
            </p>,

    },
    {
        id: 3,
        question: "Will I get a certificate for the course?",
        answer:
            <p>
                Yes, will receive a certificate for the completion of the program, subject to performance on the course.
            </p>,
    },
    {
        id: 4,
        question: "How does Doubt Support work?",
        answer:
            <p>
                There is a discussion forum integrated on the dashboard under each video and problem. You can simply comment and ask your doubts there. All doubts are resolved by course instructors and mentors in almost real time.
            </p>,
    },
    {
        id: 5,
        question: "What is the free Pause and Resume feature?",
        answer:
            <p>
                If you are not able to study for a while, due to health issues, exams, hectic projects, or anything else - you can pause your subscription for a number of days - and your subscription will get extended for those number of days for free. Once you Pause your subscription, the content will be no longer accessible until you resume back.
            </p>,
    },
];

export default PythonFaqsContent;
