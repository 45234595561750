import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "./styles/AdsLiveClassForm.css";
import logo from "../../img/PpaLogo.svg";
import ActionButton from "../Common/ActionButton";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import axios from "axios";
import { getBrowserDetails, nullCheckExistingParams } from "../../Utilities/CommonUtilities";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const AdsLiveClassForm = () => {
    const [adsFormSuccessModalOpen, setAdsFormSuccessModalOpen] = useState(false);

    const LiveClassRegistrationForm = () => {
        const { windowWidth } = useWindowDimensions();

        const style = {
            color: "white",
            background: "#444BAB",
            fontFamily: "Poppins-SemiBold",
            textTransform: "capitalize",
            padding: "0.5vw 0",
            margin: "1vw 0",
            fontSize: "0.9vw",
            width: "100%",
        };
        const mobileStyle = {
            color: "white",
            background: "#444BAB",
            fontFamily: "Poppins-SemiBold",
            textTransform: "capitalize",
            padding: "10px 0",
            margin: "20px 0 5px 0",
            width: "100%",
        };

        const formValidationSchema = Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            phoneNumber: Yup.string().required("Phone number is required"),
        });

        const formInitialValues = { name: "", email: "", phoneNumber: "" };

        const getAdDetailsForFreeLiveClass = () => {
            const utmParams = JSON.parse(localStorage.getItem("utmParameters"));
            const userAdDetails = {
                utm_source: utmParams.utmSource,
                utm_medium: utmParams.utmMedium,
                utm_campaign: utmParams.utmCampaign,
                utm_term: utmParams.utmTerm,
                utm_content: utmParams.utmContent,
                referrer: document.referrer,
                browser: getBrowserDetails(),
                fullUrl: window.location.href,
            };
            return userAdDetails;
        };

        const onSubmit = (values) => {
            setAdsFormSuccessModalOpen(true);

            const data = {
                email: values.email,
                name: values.name,
                phoneNumber: values.phoneNumber,
            };

            // persist user's ad details for free live class if utmParams exist
            if (!nullCheckExistingParams()) {
                axios
                    .post(
                        process.env.REACT_APP_MISC_URL + "/persistAdUserDetailsForFreeLiveClass",
                        {
                            user_details: data,
                            ad_details: getAdDetailsForFreeLiveClass(),
                        },
                        {
                            headers: {
                                "Cache-control": "no-store",
                            },
                        }
                    )
                    .then(() => {
                        localStorage.removeItem("utmParameters");
                    })
                    .catch((e) => console.error(e));
            } else {
                localStorage.removeItem("utmParameters");
            }
        };

        return (
            <Formik
                initialValues={formInitialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                <Form className="AdsLiveClassForm-form">
                    <div className="AdsLiveClassForm-input-div">
                        <label htmlFor="name">Name</label>
                        <Field type="text" name="name" placeholder="Enter your name" />
                        <ErrorMessage
                            className="AdsLiveClassForm-error"
                            name="name"
                            component="div"
                        />
                    </div>

                    <div className="AdsLiveClassForm-input-div">
                        <label htmlFor="email">Email</label>
                        <Field type="email" name="email" placeholder="Enter your email address" />
                        <ErrorMessage
                            className="AdsLiveClassForm-error"
                            name="email"
                            component="div"
                        />
                    </div>

                    <div className="AdsLiveClassForm-input-div">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <Field name="phoneNumber">
                            {({ field, form }) => (
                                <PhoneInput
                                    {...field}
                                    inputClass="AdsLiveClassForm-phone-input"
                                    country={"in"}
                                    buttonStyle={{ fontSize: "0.9vw" }}
                                    onChange={(phone) => form.setFieldValue("phoneNumber", phone)}
                                />
                            )}
                        </Field>

                        <ErrorMessage
                            className="AdsLiveClassForm-error"
                            name="phoneNumber"
                            component="div"
                        />
                    </div>

                    <div>
                        <ActionButton
                            style={windowWidth > 600 ? style : mobileStyle}
                            type="submit"
                            name="Submit"
                        />
                    </div>
                </Form>
            </Formik>
        );
    };

    const LogoHeading = () => {
        return <img className="AdsLiveClassForm-LogoHeading" src={logo} alt="" />;
    };

    const FormTitle = () => {
        return (
            <span className="AdsLiveClassForm-FormTitle">
                Register for a Free Live Class with us
            </span>
        );
    };

    const AdsFormSuccessModal = () => {
        const { windowWidth } = useWindowDimensions();
        const navigate = useNavigate();

        const muiStyles = makeStyles({
            modal: { display: "flex", justifyContent: "center", alignItems: "center" },
            circleIcon: {
                fontSize: windowWidth > 600 ? "5vw" : "4rem",
                color: "#10B981",
                margin: "10px 0",
            },
            closeIcon: {
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: windowWidth > 600 ? "1.7vw" : "1.5rem",
                color: "#4B5768",
            },
        })();

        return (
            <Modal
                open={adsFormSuccessModalOpen}
                onClose={() => setAdsFormSuccessModalOpen(false)}
                className={muiStyles.modal}
            >
                <div className="AdsFormSuccessModal-container">
                    <LogoHeading />
                    <CheckCircleIcon className={muiStyles.circleIcon} />
                    <span
                        style={{
                            fontFamily: "Poppins-SemiBold",
                            fontSize: windowWidth > 600 ? "1.2vw" : "1.3rem",
                        }}
                    >
                        Congratulations
                    </span>
                    <span style={{ margin: "20px 0" }}>
                        You are registered for the live class, and will receive details of the same
                        in your email inbox soon.
                    </span>
                    <span style={{ margin: "10px 0 20px 0", color: "#10B981", fontWeight: "600" }}>
                        And why wait to learn? Start a 7 Day Free Trial today!
                    </span>
                    <div
                        onClick={() => {
                            setAdsFormSuccessModalOpen(false);
                            navigate("/signup");
                        }}
                    >
                        <ActionButton
                            style={{
                                color: "white",
                                background:
                                    " linear-gradient(91.59deg, #444BAB 1.85%, #3CA5C2 99.69%)",
                                fontFamily: "Poppins-SemiBold",
                                textTransform: "capitalize",
                                fontSize: windowWidth > 600 ? "1vw" : "1rem",
                                padding: windowWidth > 600 ? "0.5vw 2vw" : "0.3rem 1.5rem",
                            }}
                            name="Start Free Trial"
                        />
                    </div>

                    <CloseIcon
                        className={muiStyles.closeIcon}
                        onClick={() => setAdsFormSuccessModalOpen(false)}
                    />
                </div>
            </Modal>
        );
    };

    return (
        <div className="AdsLiveClassForm-wrapper">
            <div className="AdsLiveClassForm-container">
                <LogoHeading />
                <FormTitle />
                <LiveClassRegistrationForm />
            </div>
            <AdsFormSuccessModal />
        </div>
    );
};

export default AdsLiveClassForm;
