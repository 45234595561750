import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import logo from "./../../img/PpaLogo.svg";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { coursesDetails } from "./CourseListConstants";
import showMoreArrow from "./../../img/showMoreArrow.png";
import newSticker from "./../../img/new.png";
import closeButton from "./../../img/close.svg";
import { courses } from "./CourseListConstants";
import { gaCourseNameMap } from "../../courses/CourseConstants";
import { getCurrentCourse } from "../../courses/CourseUtility";
import "./styles/Header.css";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import HeaderCoursesDropDown from "../HeaderCoursesDropDown/HeaderCoursesDropDown.js";
import RenaissanceHoverBox from "../Renaissance/RenaissanceHoverBox.js"
import CertificationHoverBox from "../CourseCertification/CertificationHoverBox.js"
import FreeLearningHoverBox from "../HeaderResources/FreeLearningHoverBox.js"

export default function TemporaryDrawer() {
    const navigate = useNavigate();
    const { loggedIn, subscribed, everSubscribed, initiateLogout, freeTrialFormFilled } =
        useGlobalContext();

    const [showDropDown, setShowDropDown] = useState(false);

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getFreeTrialButton = () => {
        return (
            <button
                className="action-btn"
                onClick={() => {
                    startFreeTrial(freeTrialFormFilled, redirectUser);
                }}
            >
                {" "}
                Start Free Trial
            </button>
        );
    };

    const redirectUser = (link) => navigate(link);

    const getDashBoardButton = () => {
        return (
            <button className="action-btn" onClick={() => goToDashboard()}>
                Go to Dashboard
            </button>
        );
    };

    const getSignupButton = () => {
        return (
            <button
                className="action-btn"
                onClick={() => {
                    navigate("/signup");
                }}
            >
                Start Free Trial
            </button>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };

    const getButtons = () => {
        return (
            <>
                {loggedIn ? (
                    <button
                        className="side-navigation-login-btn"
                        onClick={() => {
                            initiateLogout();
                        }}
                    >
                        <span className="button-text">Log Out</span>
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            navigate("/login");
                        }}
                        className={`${GTM_TRACKER.LOGIN} side-navigation-login-btn`}
                    >
                        Log In
                    </button>
                )}
                {getFreeTrailOrDashboardButton()}
            </>
        );
    };
    const getCourseSouceToDestination = (source, destination) => {
        return source + `->` + destination;
    };
    const getDropdownForMob = () => {
        return (
            <div className="drop-down-wrapper">
                {coursesDetails &&
                    coursesDetails.map((data, index) => (
                        <div
                            className="drop-down-menu"
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: `${data.gaCourseTracker}`,
                                });
                                data.clickable && window.open(data.courseLink);
                            }}
                            key={index}
                        >
                            <div className="course-name-wrapper">
                                <h3 className="course-name">{data.courseName}</h3>
                                {data.courseName === courses.RENAISSANCE ? (
                                    <></>
                                ) : (
                                    <img className="new-tag-img" src={newSticker} alt="" />
                                )}
                            </div>

                            <p className="course-description">{data.courseDescription}</p>
                            <div className="horizontal-line"></div>
                        </div>
                    ))}
            </div>
        );
    };

    const NavigationList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="side-nagivation-list-mob">
                {/* <p
                    className={`${GTM_TRACKER.EXPLORE_COURSES} mob-course-drop-down`}
                    onClick={() => setShowDropDown(!showDropDown)}
                >
                    Explore Courses <img width={15} height={8} src={showMoreArrow} />
                </p>
                {showDropDown && getDropdownForMob()}

                <a
                    className={GTM_TRACKER.SUCCESS_STORIES}
                    href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories"
                >
                    Success Stories
                </a>
                <a
                    className={GTM_TRACKER.HIRE_FROM_US}
                    href="https://programmingpathshala.com/hire-from-us#"
                >
                    Hire With Us
                </a>
                <a
                    className={GTM_TRACKER.CAMPUS_PROGRAM}
                    href="https://programmingpathshala.com/campus-program#"
                >
                    Campus Program
                </a>
                <a className={GTM_TRACKER.BLOGS} href="https://blogs.programmingpathshala.com/">
                    Blogs
                </a>
                <a className={GTM_TRACKER.EVENTS} href="https://events.programmingpathshala.com/">
                    Events
                </a> */}
                <HeaderCoursesDropDown isMobile={true}/>
                <RenaissanceHoverBox isMobile={true}/>
                <CertificationHoverBox isMobileCerti={true}/>
                <FreeLearningHoverBox isMobileFree={true}/>
            </div>
            <div className="side-nagivation-login-trail-btn">{getButtons()}</div>
        </Box>
    );

    return (
        <div>
            {["left"].map((anchor, index) => (
                <React.Fragment key={index}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <DehazeIcon sx={{ color: "#333" }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <div className="drawer-wrapper">
                            <div className="side-navigation-header-wrapper">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://programmingpathshala.com/"
                                >
                                    <img className="ppa-logo-mob" src={logo} alt="PPA-logo" />
                                </a>
                                <div onClick={toggleDrawer(anchor, false)}>
                                    <img src={closeButton} height={30} alt="exit" className="close-button-img" />
                                </div>
                            </div>
                            {NavigationList(anchor)}
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
