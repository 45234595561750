import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useStoriesContext } from "../../Contexts/StoriesContexts";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import Arrow_img from "../../img/Arrow.png";
import Companies_Mobile from "../../img/Placed_Companies_Mobile.png";
import Companies from "../../img/Placed_companies.png";
import arrowNext from "../../img/Slider_Right.png";
import arrowPrev from "../../img/Slider_left.png";
import addStory from "../../img/addStory.svg";
import linkedin from "../../img/linkedinImg.png";
import quotation from "../../img/quotation.svg";
import { checkAdmin } from "../Common/CommonClient";
import LinearGradientBtn from "../Common/styles/LinearGradientBtn";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import AddStoryModal from "./AddStoryModal";
import { getExperiences } from "./StoriesClient";
import {
    NUMBER_OF_PATTERNS,
    STORIES_SLIDER_DATA,
    SUCCESS_STORIES_BOX_QUANTITY,
} from "./StoriesConstants";
import "./styles/Stories.css";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

function Stories() {
    const navigate = useNavigate();
    const [stories, setStories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
    const [isReadMoreShown, setIsReadMoreShown] = useState(false);

    const { windowWidth } = useWindowDimensions();

    const {
        setIsSnackbarOpen,
        loaded,
        setLoaded,
        isSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
    } = useStoriesContext();

    useEffect(() => {
        setLoaded(false);
        checkAdmin(setIsAdmin, setIsSnackbarOpen, setSnackbarMessage);
        getExperiences(setStoriesData, setIsSnackbarOpen, setSnackbarMessage, setLoaded);
    }, []);

    const setStoriesData = (storiesData, blogData) => {
        setStories(storiesData);
        setBlogs(blogData);
    };

    function GetHeader() {
        return (
            <section className="success-header-wrapper">
                <h1>Success Stories</h1>
                <p>Every Success Story we get each cycle, reveals our strength.</p>
                {isAdmin ? (
                    <img src={addStory} alt="" onClick={() => setIsStoryModalOpen(true)} />
                ) : (
                    <></>
                )}
            </section>
        );
    }

    const getSliderContent = (story, index) => {
        const backColor = {
            backgroundColor: story.backgroundPaint,
        };
        return (
            <div className="slider-container">
                <div
                    key={index}
                    className="success-slider-statement-quotation-wrapper"
                    style={backColor}
                >
                    <div className="success-slider-contents">
                        <p>
                            {story.content.slice(0, 280)}
                            <span
                                onClick={() => {
                                    window.open(story.blog);
                                }}
                            >
                                ...Read More
                            </span>
                        </p>
                        <section className="success-slider-author-details-wrapper">
                            <h3>{story.name}</h3>

                            <img
                                className="success-slider-logo-img"
                                src={story.companyLogo}
                                alt=""
                            />
                        </section>
                    </div>
                    <div className="success-slider-profile-wrapper">
                        <div className="success-slider-linkedin-wrapper">
                            <img
                                onClick={() => window.open(story.linkedInLink)}
                                className="success-slider-linkedin"
                                alt=""
                                src={linkedin}
                            />
                        </div>
                        <div className="success-slider-person-wrapper">
                            <img className="success-slider-person" alt="" src={story.personImage} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getSliderContentForMob = (story, index) => {
        const backColor = {
            backgroundColor: story.backgroundPaint,
        };
        return (
            <div className="mobile-slider-container">
                <section
                    key={index}
                    className="success-slider-statement-quotation-wrapper"
                    style={backColor}
                >
                    <span className="success-slider-profile-wrapper">
                        <span className="profile-image">
                            <img
                                onClick={() => window.open(story.linkedInLink)}
                                className="success-slider-linkedin"
                                alt=""
                                src={linkedin}
                            />
                            <img className="success-slider-person" alt="" src={story.personImage} />
                        </span>
                        <section className="success-slider-author-details-wrapper">
                            <h3>{story.name}</h3>

                            <img
                                className="success-slider-logo-img"
                                src={story.companyLogo}
                                alt=""
                            />
                        </section>
                    </span>
                    <div className="success-slider-contents">
                        <p>
                            {story.content.slice(0, 160)}
                            <span
                                onClick={() => {
                                    window.open(story.blog);
                                }}
                            >
                                ...Read More
                            </span>
                        </p>
                    </div>
                </section>
            </div>
        );
    };

    function GetSuccessSlider() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dotsClass: "success-slider-dots",
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
            ],
        };
        return (
            <section className="success-slider-slick-wrapper">
                <Slider {...settings}>
                    {STORIES_SLIDER_DATA.map((story, index) => {
                        return windowWidth > 600
                            ? getSliderContent(story, index)
                            : getSliderContentForMob(story, index);
                    })}
                </Slider>
            </section>
        );
    }

    const openSuccessBlog = (blogLink) => {
        if (blogLink) window.open(blogLink, "_blank");
    };

    const getSuccessBoxes = (quantity, storyCount) => {
        let boxes = [];
        for (let i = storyCount; i < storyCount + quantity; i++) {
            if (stories.length && stories[i]) {
                boxes.push(
                    <section className="success-box-wrapper">
                        <section className="success-box-author-wrapper">
                            <section className="success-box-author-details-wrapper">
                                <div className="success-box-person-wrapper">
                                    <img
                                        alt=""
                                        className="success-box-person"
                                        src={stories[i].image_path}
                                    />
                                </div>
                                <div className="success-box-linkedin-wrapper">
                                    <img
                                        onClick={() => {
                                            window.open(stories[i].linkedin_url, "_blank");
                                        }}
                                        className="success-box-linkedin"
                                        src={linkedin}
                                        alt=""
                                    />
                                </div>
                            </section>
                            <section className="success-box-company-wrapper">
                                <h3>{stories[i].author_name}</h3>
                                <div className="company-img-wrapper">
                                    <img alt="" src={stories[i].institution_image} />
                                </div>
                            </section>
                        </section>
                        <section className="success-box-description-wrapper">
                            <div className="success-description">
                                <p className="desc">{stories[i].experience.slice(0, 280)}...</p>
                                <hr />
                                <span className="full-story">
                                    <p
                                        onClick={() => {
                                            openSuccessBlog(stories[i].blog_link);
                                        }}
                                    >
                                        Read full story
                                    </p>
                                    <div className="arrow-wrapper">
                                        <img src={Arrow_img}></img>
                                    </div>
                                </span>
                            </div>
                        </section>
                    </section>
                );
            } else {
                break;
            }
        }
        return [...boxes];
    };

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    function GetErrorSnackbar() {
        return (
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        );
    }

    function StudentsPlaced() {
        return (
            <div className="placements-container">
                <h2 className="heading">Our Students are Placed At</h2>
                <div className="companies-img-wrapper">
                    {windowWidth < 600 ? (
                        <img src={Companies_Mobile}></img>
                    ) : (
                        <img src={Companies}></img>
                    )}
                </div>
                <p className="many-more">And many more</p>
            </div>
        );
    }

    function GetStoriesPageSectionsPattern() {
        let storyCount = 0;
        let storiesPatternComponents = [];
        let storiesPatternBody = [];
        for (let i = 0; i < NUMBER_OF_PATTERNS; i++) {
            storiesPatternComponents.push(
                getSuccessBoxes(SUCCESS_STORIES_BOX_QUANTITY.sectionOdd, storyCount)
            );
            storiesPatternBody.push(
                <section className="stories-success-boxes-wrapper">
                    {storiesPatternComponents}
                </section>
            );
            storiesPatternComponents = [];
            storyCount += 3;
            storiesPatternBody.push(
                <section className="stories-success-blog-wrapper">
                    {storiesPatternComponents}
                </section>
            );
            storiesPatternComponents = [];
            storiesPatternComponents.push(
                getSuccessBoxes(SUCCESS_STORIES_BOX_QUANTITY.sectionEven, storyCount)
            );
            storiesPatternBody.push(
                <section className="stories-success-boxes-wrapper">
                    {storiesPatternComponents}
                </section>
            );
            storyCount += 2;
            storiesPatternComponents = [];
        }
        return storiesPatternBody;
    }

    const handleCloseStoryModal = () => {
        setIsStoryModalOpen(false);
    };

    const getAddStory = () => {
        if (isAdmin && isStoryModalOpen) {
            return <AddStoryModal handleCloseStoryModal={handleCloseStoryModal} />;
        }
    };

    function GetCircularProgress() {
        return (
            <section className="circular-progress-wrapper">
                <CircularProgress />
            </section>
        );
    }
    function StartFreeTrialSection() {
        return (
            <div className="start-free-trial-section">
                <h3>Make your own success story</h3>
                <p>Start your 7 days free trial now!</p>
                <div
                    className={`${GTM_TRACKER.START_FREE_TRIAL} free-btn`}
                    onClick={() => {
                        navigate("/signup");
                        // window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`, "_self")
                    }}
                >
                    <div className="free-trial-btn">Start 7 day Free Trial</div>
                </div>
            </div>
        );
    }

    if (isAdmin && isStoryModalOpen) {
        return getAddStory();
    } else {
        return (
            <>
                <div className="header-sticky">
                    <Header />
                </div>
                <section className="success-wrapper">
                    <GetHeader />
                    <GetSuccessSlider />
                    <StudentsPlaced />
                    {!loaded ? <GetCircularProgress /> : <GetStoriesPageSectionsPattern />}
                    <GetErrorSnackbar />
                </section>
                <StartFreeTrialSection />
                <Footer />
            </>
        );
    }
}

export default Stories;
