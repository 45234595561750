import ZealVagadiaImg from "../../../img/ZealVagadiaInspireStories.svg";
import amazon from "../../../img/amazon-story.png";
import YagyaModiImg from "../../../img/YagyaModiInspireStories.png";
import AyushSrivastavaImg from "../../../img/AyushSrivastavaInspireStories.png";
import google from "../../../img/google-story.png";
import paypal from "../../../img/paypal-story.png";

const PYTHON_SUCCESS_STORIES = [
    {
        name: "Zeal Vagadia",
        img: ZealVagadiaImg,
        company_logo: amazon,
        company_logo_wrapper: "amazon-wrapper",
        job_description: "Software Engineer at ",
        link: "https://www.linkedin.com/in/zeal-vagadia-77b029182/",
        text: "Whatever I am today, I owe it to Programming Pathshala and their life changing course. I think it is pretty much all we need to build problem solving skills.",
    },
    {
        name: "Yagya Modi",
        img: YagyaModiImg,
        company_logo: google,
        company_logo_wrapper: "google-wrapper",
        job_description: "SWE at ",
        link: "https://in.linkedin.com/in/yagya-modi-5085b51a9",
        text: "I have always been a person who wanted a teacher and some proper guidance and I think that programming Pathshala is best for it.",
    },
    {
        name: "Ayush Srivasthava",
        img: AyushSrivastavaImg,
        company_logo: paypal,
        company_logo_wrapper: "paypal-wrapper",
        job_description: "SDE-2 at ",
        link: "https://www.linkedin.com/in/ayushsriv/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in",
        text: "Fortunately, I came across Programming Pathshala on Quora and I was amazed to see awesome reviews. I went through the website and checked the Curriculum, and moreover the fee of the course was in my budget and not overpriced.",
    },
];

export { PYTHON_SUCCESS_STORIES };
