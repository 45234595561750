import React, { useContext, useState } from "react";

const SubscriptionContext = React.createContext();

export function useSubscriptionContext() {
    return useContext(SubscriptionContext);
}

export function SubscriptionContextProvider({ children }) {
    const [upgradeCouponCode, setUpgradeCouponCode] = useState("");
    const [referralOrDiscountCouponCode, setReferralOrDiscountCouponCode] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const value = {
        setUpgradeCouponCode,
        setReferralOrDiscountCouponCode,
        referralOrDiscountCouponCode,
        setLoaded,
        loaded,
        upgradeCouponCode,
        errorMessage,
        setErrorMessage,
    };

    return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
}
