import React from "react";
import Minus from "../../img/Minus.png";
import Plus from "../../img/Plus.png";

const FaqAccordion = (props) => {
    const handleAccordionClick = () => {
        props.recordAccordionId(props.identifier);
    };

    return (
        <div onClick={handleAccordionClick} className="accordion-wrapper">
            <div className={(props.isOpen ? "accordion-background " : " ") + "accordion-item"}>
                {" "}
                <div className="accordion-question">
                    <h2>{props.accordionData.question}</h2>
                    {props.isOpen ? <img src={Minus} alt="" /> : <img src={Plus} alt="" />}
                </div>
                <div className={(props.isOpen ? "accodrion-show" : " ") + " accodrion-content"}>
                    <p>{props.accordionData.answer}</p>
                </div>
            </div>
        </div>
    );
};

export default FaqAccordion;
