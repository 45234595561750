import React from "react";
import { CppOutcomeContents } from "./Content/CppOutcomes";
import tickMark from "../../img/cpp-outcomes-tick.png";
import "./Styles/Outcome.css";

const Outcomes = () => {
    const outcomeContents = CppOutcomeContents;

    return (
        <div className="cpp-learning-outcomes-wrapper">
            <div className="cpp-learning-outcomes-content">
                <h1>{outcomeContents.sectionTitle}</h1>
                <div className="cpp-learning-outcomes-points-wrapper">
                    {outcomeContents.OutcomesList &&
                        outcomeContents.OutcomesList.map((data, index) => (
                            <React.Fragment key={index}>
                                <div className="cpp-learning-outcomes-points" key={index}>
                                    <img src={tickMark} alt="" />
                                    <p>{data}</p>
                                </div>
                            </React.Fragment>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Outcomes;
