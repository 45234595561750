import lecturesImg from "../../../img/cpp-lectures.png";
import caseStudyImg from "../../../img/cpp-problems.png";
import doubtSupportImg from "../../../img/cpp-doubts.png";
import mockInterviewImg from "../../../img/cpp-interview.png";
import problemSolvingImg from "../../../img/cpp-problemsolving.png";
import durationImg from "../../../img/dsa-duration.png";

const DsaAboutContents = {
    sectionTitle: "Why learn DSA Essentials?",
    sectionDescription:
        "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
    AboutInfoWithImages: [
        {
            image: lecturesImg,
            desc: (
                <p>
                    <strong>120+</strong> lectures of important DSA concepts
                </p>
            ),
        },
        {
            image: caseStudyImg,
            desc: (
                <p>
                    <strong>150+</strong> handpicked problems frequently asked in
                    interviews
                </p>
            ),
        },
        {
            image: doubtSupportImg,
            desc: "Real time Doubt Resolution by mentors.",
        },
        {
            image: mockInterviewImg,
            desc: "Master Competitive Programming & Ace Interviews",
        },
        {
            image: problemSolvingImg,
            desc: "Develop Problem solving ability",
        },
        {
            image: durationImg,
            desc: "Recommended duration of 3 months",
        },
    ],
};

export { DsaAboutContents };
