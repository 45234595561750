import React, { useState } from "react";
import "./Pricing.css";
import { useEffect } from "react";
import { SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES } from "./SubscriptionConstants";
import bestValue from "./../../../img/bestValue.png";
import mostPopular from "./../../../img/mostPopular.png";
import starterPack from "./../../../img/starterPack.png";
import subscriptionPlanTickMark from "./../../../img/subscriptionPlanTickMark.png";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { availableCourses } from "../../../courses/CourseConstants";
import {
    MULTIPLE_COURSES_SUBSCRIPTION_DURATION,
    MULTIPLE_COURSES_SUBSCRIPTION_MAP,
} from "./SubscriptionConstants";
import { startFreeTrial } from "../../../courses/Strategies/RedirectionStrategy";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { gaCourseNameMap } from "../../../courses/CourseConstants";

const FreeTrailUserSubscriptionPlan = (props) => {
    const SUBSCRIPTION_MAP = MULTIPLE_COURSES_SUBSCRIPTION_MAP[getCurrentCourse()];
    const SUBSCRIPTION_DURATION = MULTIPLE_COURSES_SUBSCRIPTION_DURATION[getCurrentCourse()];
    const [subscriptionIdentifiers, setSubscriptionIdentifier] = useState({});

    const navigate = useNavigate();
    const [perMonthPrice, setPerMonthPrice] = useState({});
    const [basePlans, setBasePlans] = useState({});
    const [discountedPlans, setDiscountedPlans] = useState({});

    const { setCouponDiscountPrice, freeTrialFormFilled } = useGlobalContext();

    const getPriceAfterDiscount = (price, discount) => {
        return Math.floor(price - (price * discount) / 100);
    };

    const redirectUser = (link) => navigate(link);

    // const upgradelink = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/classroom/subscription`;
    const upgradelink = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`;
    //Here the base plans comes as a response to the API but when coupon is applied the base plans becomes old discounted price (mrp - initial offered discount).

    const getBasePlans = () => {
        let basePlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            basePlansData[SUBSCRIPTION_MAP[subscription]] = props.isCouponValid
                ? getPriceAfterDiscount(
                      parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),
                      parseFloat(
                          props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount
                      ) -
                          parseFloat(
                              props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1]
                                  .additional_discount
                          )
                  )
                : parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp);
        }

        return basePlansData;
    };

    const getDiscountedPlans = () => {
        let discountedPlansData = {};
        for (const subscription in SUBSCRIPTION_MAP) {
            discountedPlansData[SUBSCRIPTION_MAP[subscription]] = getPriceAfterDiscount(
                parseInt(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].mrp),

                parseFloat(props.subscriptionPlans[SUBSCRIPTION_MAP[subscription] - 1].discount)
            );
        }
        return discountedPlansData;
    };

    const getSubscriptionIdentifiers = (plans) => {
        const planIdentifiers = {};
        plans.map((plan, index) => {
            planIdentifiers[index + 1] = plan.subscription_id;
        });
        return planIdentifiers;
    };
    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            setBasePlans(getBasePlans());
            setDiscountedPlans(getDiscountedPlans());
            setSubscriptionIdentifier(getSubscriptionIdentifiers(props.subscriptionPlans));
        }
    }, [props.subscriptionPlans]);

    useEffect(() => {
        if (props.subscriptionPlans && props.subscriptionPlans.length) {
            let pricePerMonthData = {};
            for (const subscription in SUBSCRIPTION_MAP) {
                pricePerMonthData[SUBSCRIPTION_MAP[subscription]] = Math.floor(
                    discountedPlans[SUBSCRIPTION_MAP[subscription]] /
                        SUBSCRIPTION_DURATION[SUBSCRIPTION_MAP[subscription]]
                );
            }
            setPerMonthPrice(pricePerMonthData);
        }
    }, [discountedPlans]);

    const getCardData = (cardType) => {
        let output = {
            duration: "",
            initialPrice: "",
            finalPrice: "",
            perMonth: "",
            services: [],
            moneySaved: 0,
        };
        output.duration = SUBSCRIPTION_DURATION[cardType];
        output.perMonth = perMonthPrice[cardType];
        output.services = SUBSCRIPTION_FREE_TRIAL_USERS_SERVICES[cardType];
        output.initialPrice = basePlans[cardType];
        output.finalPrice = discountedPlans[cardType];

        return output;
    };

    const reloadToUpgradePlan = () => {
        window.open(upgradelink, "_blank");
    };

    const reloadToSignUp = () => {
        navigate("/signup");
        // window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`, "_self");
    };
    const reloadToStartFreeTrial = () => {
        startFreeTrial(freeTrialFormFilled, redirectUser);
    };
    const onSubscribeHandler = (cardType) => {
        if (!props.loggedIn) reloadToSignUp();
        else if (!props.everSubscribed) reloadToStartFreeTrial();
        else if (props.checkUserSubscribed && !props.checkOnFreeTrail) reloadToUpgradePlan();
        else handleSubscriptionPlan(cardType);
    };

    const handleSubscriptionPlan = (subscriptionId) => {
        props.subscriptionPlans.map((subscription) => {
            if (subscription.subscription_id === subscriptionIdentifiers[subscriptionId]) {
                sendEventToGTM({
                    event: "initiated-payment",
                    course: `${gaCourseNameMap[getCurrentCourse()]}`,
                    subscription_id: subscription?.subscription_id,
                    subscription_name: subscription?.subscription_name
                });
                props.initiatePayment(subscription);
            }
        });
    };

    const getPlanImage = (cardType) => { 
        if ( getCurrentCourse() === availableCourses.RENAISSANCE && cardType === SUBSCRIPTION_MAP.HALF_YEARLY ) 
            return <img src={mostPopular} alt="" />
        if ( getCurrentCourse() === availableCourses.RENAISSANCE && cardType === SUBSCRIPTION_MAP.YEARLY )
            return <img src={bestValue} alt="" />
        if ( getCurrentCourse() === availableCourses.RENAISSANCE && cardType === SUBSCRIPTION_MAP.MONTHLY )
            return <img src={starterPack} alt="" />
        return
        }

    const getSubscriptionCard = (cardType) => {
        let cardData = getCardData(cardType);
        setCouponDiscountPrice(basePlans[3] - discountedPlans[3]);

        return (
            <section className="subscription-card-wrapper">
                {getPlanImage(cardType)}
                {getCurrentCourse() === availableCourses.LLD &&
                cardType === SUBSCRIPTION_MAP.QUARTERLY ? (
                    <img src={mostPopular} alt=""/>
                ) : (
                    <></>
                )}

                <h3>
                    {cardData.duration}
                    {cardType === SUBSCRIPTION_MAP.MONTHLY ? " Month" : " Months"}
                </h3>
                <div id="free-trial-users-plans-card-hr"></div>
                <div className="subscription-plan-price-saved-wrapper">
                    <h4>₹ {cardData.initialPrice} </h4>
                    {props.showSavedMoney ? (
                        <p className="money-saved-text">
                            saved ₹ {cardData.initialPrice - cardData.finalPrice} /-
                        </p>
                    ) : (
                        <></>
                    )}
                </div>

                <h2>₹ {cardData.finalPrice}</h2>

                <p>
                    which costs <span className="free-user-subscription-rupee-symbol">₹</span>
                    {cardData.perMonth}/month
                </p>
                <button
                    onClick={() => {
                        onSubscribeHandler(cardType);
                    }}
                >
                    subscribe now{" "}
                </button>
                <section className="card-services-wrapper">
                    <p className="features-text">Features</p>
                    {getCurrentCourse() === availableCourses.RENAISSANCE &&
                        cardData.services.map((service, index) => {
                            return (
                                <section
                                    className="card-services-point-wrapper"
                                    key={index}
                                >
                                    <img src={subscriptionPlanTickMark} />
                                    {service}
                                </section>
                            );
                        })}
                </section>
            </section>
        );
    };
    return (
        <div className="subscription-card-container">
            {Object.keys(SUBSCRIPTION_MAP).map((subscription) => (
                <>{getSubscriptionCard(SUBSCRIPTION_MAP[subscription])}</>
            ))}
        </div>
    );
};

export default FreeTrailUserSubscriptionPlan;
