import React from "react";
import Header from "../../Header/Header";
import Footer from "../../LandingPage/Footer";
import RefundPolicy from "../RefundPolicy";

const RefundPolicyRoute = () => {
    return (
        <div className="">
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <RefundPolicy />
            </div>
            <div>
                <div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default RefundPolicyRoute;
