import React from "react";
import { cancellationPolicyData } from "../../../Constants/RefundPolicyConstant";
import "./CancellationPolicy.css";
const getCancellationPolicyContent = () => {
    return (
        <section className="cancellation-policy-content-wrapper">
            <h3>Refund and Cancellation Policy</h3>
            {cancellationPolicyData &&
                cancellationPolicyData.map((data, index) => (
                    <p key={index}>
                        {"\u2022"} {data.content}
                    </p>
                ))}
        </section>
    );
};

const getRefundRequest = () => {
    return (
        <section className="refund-request-content-wrapper">
            <h3>How to initiate the Refund request</h3>

            <p>
                {"\u2022"} Drop an email on: <span>info@programmingpathshsla.com</span> with a
                subject “Course Refund | Registered email id”. (Please do not forget to provide the
                registered email id in the subject)
            </p>

            <p>
                {" "}
                {"\u2022"} Refund will be processed within 10 working days of receiving the refund
                email"
            </p>
        </section>
    );
};

const getPauseContent = () => {
    return (
        <section className="pause-content-wrapper">
            <h3>Pause</h3>

            <p>
                {"\u2022"} The user can pause their subscription upto 25% of the number of days of
                your subscription. This will extend their subscription by the duration of pause
                without any additional cost.
            </p>

            <p>
                {"\u2022"} You can pause as many number of times as you want as long as you have
                Pause Days remaining in your account.
            </p>
        </section>
    );
};

const CancellationPolicy = () => {
    return (
        <div className="canellation-policy">
            {getCancellationPolicyContent()}
            {getRefundRequest()}
            {getPauseContent()}
        </div>
    );
};

export default CancellationPolicy;
