import React from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/Intro.css";
import { Rating } from "@mui/material";
import cppIntroIllustrator from "../../img/cpp-intro.svg";
import cppAvatar from "../../img/cpp-intro-avatar.png";
import { CppIntroBannerContents } from "./Content/CppIntroContents";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Intro = () => {
    const IntroBannerContents = CppIntroBannerContents;
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, subscribed, everSubscribed, freeTrialFormFilled } = useGlobalContext();
    const { windowWidth } = useWindowDimensions();

    const getFreeTrialButton = () => {
        return (
            <div className="cpp-intro-cta-btn-wrapper">
                <button
                    onClick={() => {
                        startFreeTrial(freeTrialFormFilled, redirectUser);
                    }}
                >
                    Start Free Trial
                </button>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div className="cpp-intro-cta-btn-wrapper">
                <button onClick={() => goToDashboard()}>Go to Dashboard</button>
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div className={`${GTM_TRACKER.START_FREE_TRIAL} cpp-intro-cta-btn-wrapper`}>
                {" "}
                <button onClick={() => navigate("/signup")}>Try 7-Day Free Trial</button>
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };

    return (
        <>
            <div className="cpp-intro-wrapper">
                <div className="cpp-intro-content">
                    <div className="cpp-intro-heading">
                        {IntroBannerContents.introCourseHeading}
                        {IntroBannerContents.introCourseDescription}
                    </div>
                    <img className="cpp-intro-img" src={cppIntroIllustrator} alt=""></img>
                </div>
                <div className="cpp-intro-points-wrapper">
                    {IntroBannerContents.introPoints &&
                        IntroBannerContents.introPoints.map((introPoint) => (
                            <div className="cpp-intro-points">
                                <img src={introPoint.img} alt="" />
                                <p>{introPoint.text}</p>
                            </div>
                        ))}
                </div>

                <div className="cpp-intro-cta-rating-wrapper">
                    <div className="cpp-intro-cta-wrapper">
                        {getFreeTrailOrDashboardButton()}
                        <span className="cpp-free-lectures">
                            Watch 10+ lectures and solve problems for&nbsp;
                            <span className="cpp-free-text">free </span>
                        </span>
                    </div>
                    <div className="cpp-intro-rating-wrapper">
                        <img src={cppAvatar} alt="" />
                        <div className="cpp-star-rating-wrapper">
                            <p className="cpp-star-rating-text">
                                <strong>5000+ students</strong>
                            </p>
                            <Rating
                                name="half-rating-read"
                                defaultValue={5}
                                readOnly
                                size="small"
                                sx={{
                                    fontSize: windowWidth <= 600 ? "4vw" : "1.8vw",
                                    color: "#FCD635",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intro;
