import React from "react";
import {
    ADMIN_WHATSAPP_MESSAGE,
    PPA_ADMIN_PHONE_NUMBER,
    WHATS_APP_LINK,
} from "../../Constants/LandingPageConstants";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import whatsappIcon from "../../img/whatsapp-icon.svg";
import "./styles/FreeTrialMobile.css";

function FreeTrialMobile() {
    const openWhatsapp = () => {
        window.open(WHATS_APP_LINK + PPA_ADMIN_PHONE_NUMBER + ADMIN_WHATSAPP_MESSAGE, "_blank");
    };

    const JoinCommnunity = () => {
        return (
            <div className="free-trial-btn">
                <a href="https://community.programmingpathshala.com/">
                    <button>Join Our Community</button>
                </a>
            </div>
        );
    };

    return (
        <div className="lld-free-trial-mobile-wrapper">
            <p>
                Still confused ? Take a free <br /> trial of our course and see <br />
            </p>
            <p>the difference yourself</p>
            <div className="buttons-wrapper-mobile">
                <div className={`${GTM_TRACKER.WHATSAPP} talk-to-us-btn`} onClick={openWhatsapp}>
                    <p>Talk to us</p>
                    <img src={whatsappIcon} alt="" />
                </div>

                <JoinCommnunity />
            </div>
        </div>
    );
}

export default FreeTrialMobile;
