import axios from "axios";
import logo from "./../../../img/PpaLogo.svg";
import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import {
    COUPON_ADDITIONAL_DISCOUNT,
    COUPON_CODE_MODALS,
    GET_PLANS_FAILURE_MESSAGE,
    INITIATE_PAYMENT_FAILURE_MESSAGE,
    PLAN_WITH_HEIGHEST_VALUE,
} from "./SubscriptionConstants";
import axiosInstance from "../../../Utilities/RequestWrapper";
import { getCurrentCourse } from "../../../courses/CourseUtility";

const baseURL = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}subscriptions`;

export const getPlans = (setPlans, setErrorSnackBar, getErrorDisplayModal, setLoaded) => {
    const cookies = new Cookies();
    setLoaded(true);
    try {
        axiosInstance
            .get(
                // process.env.REACT_APP_PAYMENT_SERVICE_URL + "subscriptions",
                // `${process.env.REACT_APP_PAYMENT_URL}${url}`
                baseURL,
                {
                    headers: {
                        authorization: cookies.get("authorization"),
                        course: getCurrentCourse(),
                    },
                }
            )
            .then((response) => {
                setLoaded(false);
                if (response && response.data && response.data.Response) {
                    let plans = response.data.Response;
                    plans.sort((a, b) => parseInt(a.mrp) - parseInt(b.mrp));
                    setPlans(plans);
                    setErrorSnackBar(false);
                }
            });
    } catch (error) {
        setLoaded(false);
        getErrorDisplayModal(GET_PLANS_FAILURE_MESSAGE);
    }
};

export const updatePlans = (
    updateCouponPlans,
    setCouponCode,
    couponCode,
    url,
    originalMaxDiscount,
    setErrorSnackbar,
    setDiscountPrice,
    setLoaded,
    setIsModalVisible,
    setShowSavedMoney
) => {
    try {
        setLoaded(true);
        axiosInstance
            .post(
                // `${process.env.REACT_APP_PAYMENT_URL}${url}`,
                // "https://backend.programmingpathshala.com/rest/payments/applyReferralOrDiscount",
                process.env.REACT_APP_PAYMENT_SERVICE_URL + "applyReferralOrDiscount",
                { code: couponCode },
                {
                    headers: {
                        course: getCurrentCourse(),
                    },
                }
            )
            .then((response) => {
                setLoaded(false);
                setIsModalVisible(true);

                let plans = response.data.Response;

                plans.sort((a, b) => parseInt(a.mrp) - parseInt(b.mrp));

                let couponAdditionalDiscountPrice = getAdditionalDiscountPrice(
                    plans,
                    originalMaxDiscount
                );

                updateCouponPlans(plans);
                setShowSavedMoney(true);
                setDiscountPrice(couponAdditionalDiscountPrice);
            })
            .catch((error) => {
                setLoaded(false);
                setCouponCode("");
                setErrorSnackbar(true);
                setShowSavedMoney(false);
            });
    } catch (error) {
        setErrorSnackbar(true);
    }
};

export const implementInitiatePayments = (
    data,
    setPaymentDetails,
    placeOrderAndShowRz,
    currentLoggedInUserEmail,
    setPaymentsId,
    url,
    setLoaded,
    setPaymentErrorModal,
    setErrorMessage
) => {
    const cookies = new Cookies();
    setLoaded(true);
    axiosInstance
        .post(
            // `${process.env.REACT_APP_PAYMENT_URL}${url}`
            // "http://backend.programmingpathshala.com/rest/payments/initiatePayment",
            // "http://backend-staging.programmingpathshala.com/rest/payments/initiatePayment ",
            process.env.REACT_APP_PAYMENT_SERVICE_URL + "initiatePayment",
            data,
            {
                headers: {
                    authorization: cookies.get("authorization"),
                    course: getCurrentCourse(),
                },
            }
        )
        .then((Response) => {
            setLoaded(false);
            let apiRes = Response.data.Response;
            let { orderId, key, amount, currency } = apiRes;

            setPaymentDetails(orderId, key, amount, currency, "");
            placeOrderAndShowRz(
                currentLoggedInUserEmail,
                orderId,
                key,
                amount,
                currency,
                setPaymentsId
            );
        })
        .catch((error) => {
            setLoaded(false);
            setPaymentErrorModal(true);
            setErrorMessage(INITIATE_PAYMENT_FAILURE_MESSAGE);
        });
};

const directToTheDashBoard = () => {
    setTimeout(() => {
        // window.open(process.env.REACT_APP_NEW_DASHBOARD_URL, "_self");
        window.open(`${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`, "_self");
    }, 6000);
};

export const placeOrderAndShowRz = (email, orderId, key, amount, currency, setPaymentsId) => {
    try {
        let name = email.split("@")[0];
        var options = {
            key: `${key}`,
            amount: `${amount}`.toString(),
            currency: `${currency}`,
            name: "Programming Pathshala",
            description: "Subscription Payment",
            image: { logo },
            order_id: `${orderId}`,
            handler: (response) => {
                setPaymentsId(response.razorpay_payment_id, { amount: amount, currency: currency });
                directToTheDashBoard();
            },
            prefill: {
                name,
                email,
                contact: "",
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#444BAB",
            },
	    config:{
                display: { hide: [{ method: 'upi', flows: [ "qr"] }]}
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (e) {}
};

const getDiscountPrice = (mrp, discount) => {
    return parseInt(mrp - (mrp * discount) / 100);
};

// this function will calculate the additional discount Price on applying coupon code.
const getAdditionalDiscountPrice = (plans, originalMaxDiscount) => {
    let originalDiscountOnMRP = getDiscountPrice(
        plans[PLAN_WITH_HEIGHEST_VALUE].mrp,
        originalMaxDiscount
    );
    let couponDiscountOnMRP = getDiscountPrice(
        plans[PLAN_WITH_HEIGHEST_VALUE].mrp,

        parseInt(plans[PLAN_WITH_HEIGHEST_VALUE].additional_discount)
    );

    let couponAdditionalDiscountPrice = originalDiscountOnMRP - couponDiscountOnMRP;
    return couponAdditionalDiscountPrice;
};
