import React from "react";
import openIcon from "./../../../img/dropDownOpenIcon.png";
import closeIcon from "./../../../img/dropDownCloseIcon.png";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import "../ComponentsStyles/CommonAccordion.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const CommonFaqAccordion = (props) => {
    const { windowWidth } = useWindowDimensions();

    const handleAccordionClick = () => {
        props.recordAccordionId(props.identifier);
        props.setDropDownImg(props.accordionData.image);
    };

    return (
        <div onClick={handleAccordionClick} className="common-faq-accordion-wrapper">
            <div
                className={
                    (props.isOpen ? "common-faq-accordion-background " : " ") +
                    "common-faq-accordion-item"
                }
            >
                {" "}
                <div className="common-faq-accodrion-title">
                    {windowWidth > 600 ? (
                        <FiberManualRecordIcon sx={{ marginRight: "4%", fontSize: "1vw" }} />
                    ) : (
                        <FiberManualRecordIcon
                            className="circular-dot"
                            sx={{ marginRight: "4%", fontSize: "2vw" }}
                        />
                    )}

                    <h2 className="common-faq-accordion-questions">
                        {props.accordionData.question}
                    </h2>
                    <span>
                        {props.isOpen ? (
                            <img
                                src={closeIcon}
                                width="15vw"
                                className="open-or-close-icon"
                                alt=""
                            />
                        ) : (
                            <img
                                src={openIcon}
                                width="15vw"
                                className="open-or-close-icon"
                                alt=""
                            />
                        )}
                    </span>
                </div>
                <div
                    className={
                        (props.isOpen ? "common-faq-accodrion-show" : " ") +
                        " common-faq-accodrion-content"
                    }
                >
                    <p className="common-faq-accordion-answer">{props.accordionData.answer}</p>
                    {windowWidth < 600 ? (
                        <div className="common-faq-accordion-img-wrapper">
                            <img src={props.accordionData.image} alt=""></img>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CommonFaqAccordion;
