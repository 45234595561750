import React, { useRef } from "react";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PythonAdvantagesContents } from "./Content/PythonAdvantagesContents";
import "./styles/Advantage.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";


const Advantage = () => {
    const advantageContents = PythonAdvantagesContents;

    const { windowWidth } = useWindowDimensions();
    let advantagesSliderRef = useRef(null);

    const sliderSettings = {
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    dots: false,
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                    arrows: false,
                },
            },
        ],
    };

    const getAdvantageCard = () => {
        return (
            advantageContents.AdvantagesList &&
            advantageContents.AdvantagesList.map((advantage) => (
                <>
                    <div className="python-advantage-card">
                        <div className="python-advantage-data">
                            <div className="python-advantage-type">
                                {advantage.type}
                            </div>
                            <div className="python-advantage-text">
                                {advantage.text}
                            </div>
                        </div>
                        <div className="python-advantage-img">
                            <img
                                src={advantage.img}
                                alt="" />
                        </div>
                    </div>
                </>
            ))
        );
    }

    return (
        <div className="python-advantage-wrapper">
            <div className="python-advantage-heading-wrapper">
                {advantageContents.sectionTitle}
                {advantageContents.sectionDescription}
            </div>
            {windowWidth < 600 ? <div className="python-advantage-slider">
                <Slider
                    ref={(slider) => {
                        advantagesSliderRef = slider;
                    }}
                    {...sliderSettings}
                >
                    {getAdvantageCard()}
                </Slider>

                <div className="python-advantage-slider-arrows-wrapper">
                    <img
                        onClick={() => advantagesSliderRef?.slickPrev()}
                        src={arrowPrev}
                        alt="prev"
                    />
                    <img
                        onClick={() => advantagesSliderRef?.slickNext()}
                        src={arrowNext}
                        alt="next"
                    />
                </div>
            </div>
                : <div className="python-advantage-card-wrapper"> {getAdvantageCard()}</div>}
        </div>
    );
};

export default Advantage;


