import yagyaBlog from "../../img/yagya-modi-blog.svg";
import zealBlog from "../../img/zeal-wagadia-blog.webp";
import harshBlog from "../../img/phone-pe-blog.svg";
import harshitBlog from "../../img/harshit-blog.svg";
import designPatternsBlog from "../../img/design-patterns-blog.svg";

const BlogContents = {

    blogLLDHeading: (
        <h1 className="lld-blog-heading">Explore <span className="blue-heading-tag">Our blogs</span></h1> 
        ),
    blogPythonHeading: (
            <h1 className="python-blog-heading">Explore <strong>Our blogs</strong></h1>
        ),
    blogCPPHeading: (
            <h1 className="cpp-blog-heading">Explore Our blogs</h1>
        ),
    blogRenHeading: (
        <h1 className="ren-blog-heading">Explore Our blogs</h1>
    ),
    blogDsaHeading: (
        <h1 className="dsa-blog-heading">Explore Our blogs</h1>
    ),
    blogCardDetails: [
        {
            img: yagyaBlog,
            desc: "My journey to becoming SDE with Google",
            date: "Nov 20, 2021",
            time: "5 min",
            blogLink: `https://blogs.programmingpathshala.com/coding-interview-experience-google-sde/63`,
        },
    
        {
            img: zealBlog,
            desc: "This is how I became SDE intern with Amazon",
            date: "Mar 3, 2022",
            time: "5 min",
            blogLink: `https://blogs.programmingpathshala.com/interview-experience-amazon-sde%20/82`,
        },
        {
            img: harshBlog,
            desc: "My interview experience with PhonePe",
            date: "Dec 11, 2021 ",
            time: "5 min",
            blogLink: `https://blogs.programmingpathshala.com/coding-Interview-experience-PhonePe/65`,
        },
        {
            img: harshitBlog,
            desc: "My Journey from Content Writer to Becoming the SDE at ShareChat",
            date: "Nov 14, 2021",
            time: "5 min",
            blogLink: `https://blogs.programmingpathshala.com/content-writer-to-SDE-journey/61`,
        },
    
        {
            img: designPatternsBlog,
            desc: "What are Design Patterns? -Vivekanand Vivek",
            date: "July 03, 2023 ",
            time: "5 min",
            blogLink: `https://blogs.programmingpathshala.com/design-patterns/85`,
        },
    ]
}
export { BlogContents };