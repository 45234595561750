import React from "react";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/SuccessStories.css";
import { DSA_SUCCESS_STORIES } from "./DsaCrashCourseContents/DsaSuccessStoriesContent";
import quotesIcon from "../../img/quotes-icon.png";
import linkedin_icon from "../../img/linkedin.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const SuccessStories = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {DSA_SUCCESS_STORIES &&
                    DSA_SUCCESS_STORIES.map((success_story, index) => (
                        <div className="dsa-success-stories-card">
                            <div className="dsa-success-stories-content">
                                <img
                                    className="dsa-success-stories-card-quotes-img"
                                    src={quotesIcon}
                                    alt=""
                                />
                                <div className="dsa-success-stories-card-txt">
                                    <p>{success_story.text}</p>
                                </div>
                                <img
                                    className="dsa-success-stories-card-img"
                                    src={success_story.img}
                                    alt=""
                                />
                                <div className="dsa-success-stories-name-wrapper">
                                    <p className="dsa-success-stories-name">{success_story.name}</p>
                                    <a href={success_story.link}>
                                        <img src={linkedin_icon} alt="" />
                                    </a>
                                </div>
                                <div className="dsa-success-stories-company-logo-wrapper">
                                    <p className="dsa-sde-text">{success_story.job_description}</p>
                                    <div className="dsa-logo-wrapper">
                                        <img src={success_story.company_logo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </Slider>
        );
    };
    return (
        <div className="dsa-success-stories-wrapper">
            <h1>Success Stories</h1>
            <h2>They did it. So can you!</h2>
            {getSlidingStories()}
            <div
                className={`${GTM_TRACKER.SUCCESS_STORIES} dsa-success-stories-read-more-btn`}
                onClick={() =>
                    window.open(
                        process.env.REACT_APP_FRONTEND_MAIN_URL + "crack-coding-interviews/stories",
                        "_self"
                    )
                }
            >
                <button>Read More Stories</button>
            </div>
        </div>
    );
};

export default SuccessStories;
