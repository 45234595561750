import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import linkedin_icon from "../../img/linkedin.png";
import quotesIcon from "../../img/quotes-icon.png";
import { SUCCESS_STORIES } from "./Content/SuccessStoriesContent";
import SuccessStoriesMobile from "./SuccessStoriesMobile";
import "./styles/SuccessStories.css";

const SuccessStories = () => {
    const { windowWidth, windowHeight } = useWindowDimensions();

    const successStoriesCard = () => {
        return (
            <>
                {SUCCESS_STORIES &&
                    SUCCESS_STORIES.map((success_story, index) => (
                        <div className="success-stories-card">
                            <img
                                className="success-stories-card-quotes-img"
                                src={quotesIcon}
                                alt=""
                            />
                            <div className="success-stories-card-txt">
                                <p>{success_story.text}</p>
                            </div>
                            <img
                                className="success-stories-card-img"
                                src={success_story.img}
                                alt=""
                            />
                            <div className="success-stories-name-wrapper">
                                <p className="success-stories-name">{success_story.name}</p>
                                <a href={success_story.link}>
                                    <img src={linkedin_icon} alt="" />
                                </a>
                            </div>
                            <div className="success-stories-company-logo-wrapper">
                                <p className="sde-text">SDE</p>
                                <div className={success_story.company_logo_wrapper}>
                                    <img src={success_story.company_logo} alt="" />
                                </div>
                            </div>
                        </div>
                    ))}
            </>
        );
    };

    function DesktopHeading() {
        return <h2>They did it. So can you!</h2>;
    }

    return (
        <div className="success-stories-wrapper">
            <h1>
                Success <span className="blue-heading-tag">Stories</span>
            </h1>
            {windowWidth <= 600 ? "" : <DesktopHeading />}
            <div className="success-stories-card-wrapper">
                {windowWidth <= 600 ? <SuccessStoriesMobile /> : successStoriesCard()}
            </div>
            <div
                className={`${GTM_TRACKER.SUCCESS_STORIES} success-stories-read-more-btn`}
                onClick={() =>
                    window.open(
                        process.env.REACT_APP_FRONTEND_MAIN_URL + "crack-coding-interviews/stories",
                        "_self"
                    )
                }
            >
                <button>Read More Stories</button>
            </div>
        </div>
    );
};

export default SuccessStories;
