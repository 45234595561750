import TagManager from "react-gtm-module";

const initializeGTM = () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
};

// https://developers.google.com/tag-platform/tag-manager/datalayer
const sendEventToGTM = (data) => {
    window.dataLayer.push(data);
}

export { initializeGTM, sendEventToGTM };
