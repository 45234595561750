import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/LogoBelt.css";
import CompanyLogos from "./LogoBeltConstants";

export default class AutoPlay extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 9,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 3000,
            // important : set arrows to false to get rid of arrows or invisible marging will occur
            arrows: false,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        autoplay: true,
                    },
                },
            ],
        };

        return (
            <div className="dsa-logo-slider">
                <div className="dsa-logo-slider-text">
                    Alumni now work at top companies like:
                </div>
                <div className="dsa-logo-slider-img-wrapper">
                    <Slider {...settings}>
                        {CompanyLogos && CompanyLogos.map((logo) =>
                            <div>
                                <img
                                    className={logo.classes}
                                    src={logo.src} alt={logo.text} />
                            </div>)}
                    </Slider>
                </div>
            </div>
        );
    }
}
