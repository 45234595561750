import reactIntroIllustrator from "../../../img/reactIntroIllustrator.svg";

const ReactIntroBannerContents = {
    introCourseHeading: <h1> Become a Brilliant Frontend Engineer</h1>,
    introCourseDescription: (
        <p className="common-intro-desc">
            Master the skills of a front-end engineer and build modern & responsive <br />
            website applications.
        </p>
    ),

    introCourseReccomends:
        "Highly recommended for folks looking to gain hands-on experience with projects.⚡",
    imageIllustrator: reactIntroIllustrator,

    commonNavTags: [
        "About",
        "Learning Outcomes",
        "Who this course is for",
        "Prerequisites",
        "Course Curriculum",
        "Instructors",
        "Pricing",
        "FAQs",
    ],
    // the css are found in common-intro-css files
    BgColorClassName: "react-background-color",
    illustratorCss: "react-illustrator-Img",
};

export { ReactIntroBannerContents };
