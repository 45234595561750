import React, { useEffect, useState } from "react";
import "./styles/Outcomes.css";
import CodeSharp from "../../img/CodeSharp.svg";
import CodeSharpWhite from "../../img/CodeSharpWhite.svg";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { CardDataOutcomes as CardData } from "./SpringBootData";

const Card = ({ id, heading, paragraph, changeLogo }) => {
    const { windowWidth } = useWindowDimensions();
    return (
        <div className="springboot-switcher-card" data-value={id}>
            <div className="springboot-outcomes-points-container">
                <div className="springboot-outcomes-switcher-points" />
            </div>
            <div>
                <img
                    src={windowWidth > 600 && !changeLogo ? CodeSharp : CodeSharpWhite}
                    alt="code"
                    className="springboot-outcomes-code-box"
                    width={windowWidth > 600 ? 56 : 40}
                />
            </div>
            <div>
                <h3 className="springboot-outcomes-card-heading">{heading}</h3>
                <p className="springboot-outcomes-card-paragraph">{paragraph}</p>
            </div>
        </div>
    );
};

const Outcomes = () => {
    let initialArray = Array(CardData.length).fill(false);
    const [currentIntersectedPoint, setCurrentIntersectedPoint] = useState(initialArray);
    const [activePoint, setActivePoint] = useState(0);

    const points = document.getElementsByClassName("springboot-switcher-card");

    // These are the heights of the lines in percentages that cover each point of the outcome section from the top.
    const backgroungPercentages = [0, 1, 22, 41, 60, 78, 100];

    // This function is used to animate the flow in the course outcomes section.
    // I have used Intersection Observer to observe the appearence of each outcome point in the center of the screen.
    // Then added some required states and modified a classname to produce the transition and changes needed.
    useEffect(() => {
        if (points.length > 0) {
            let options = {
                rootMargin: "-45% 0px -45% 0px",
            };

            let observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const currId = entry.target.dataset.value;
                        setActivePoint((prev) => prev + 1);
                        setTimeout(() => {
                            entry.target.classList.add("change-background");
                            setCurrentIntersectedPoint((prev) =>
                                prev.map((fl, idx) => (currId == idx ? true : fl)),
                            );
                        }, 500);
                    }
                });
            }, options);

            [...points].forEach((point) => {
                observer.observe(point);
            });
        }
    }, []);

    return (
        <div className="springboot-outcomes">
            <h2 className="springboot-outcomes-heading">Your Learning Outcomes will be:</h2>
            <div className="springboot-outcomes-stepper-switcher-container">
                <div className="springboot-outcomes-stepper-switcher">
                    <div className="springboot-outcomes-switcher-horizontal-line">
                        <div
                            className="colored-switcher-horizontal-line"
                            style={{
                                height: `${backgroungPercentages[activePoint]}%`,
                                transition: "height 0.5s ease-in",
                            }}
                        />
                    </div>
                </div>
                <div className="springboot-outcomes-card-container">
                    {CardData.map((data, index) => (
                        <Card {...data} changeLogo={currentIntersectedPoint[index]} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Outcomes;
