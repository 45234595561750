import React from "react";
import "./styles/Prerequisites.css";
import prerequisiteImg from "../../img/dsa-prerequisitewing.svg";

const Prerequisites = () => {
    return (
        <div className="dsa-pre-requisites-wrapper">
            <div className="dsa-pre-requisites-image-left">
                <img src={prerequisiteImg} alt="" />
            </div>
            <div className="dsa-pre-requisites-content">
                <h1>But wait, what are the pre-requisites for this course?</h1>
                <p>You must be able to write code in at least one programming language.</p>
            </div>
            <div className="dsa-pre-requisites-image-right">
                <img src={prerequisiteImg} alt="" />
            </div>
        </div>
    );
};

export default Prerequisites;
