import { availableCourses } from "../../courses/CourseConstants";
import vivekMentorIcon from "../../img/vivek-mentor-img.png";
import vivekMentorIconMob from "../../img/vivek-mentor-img-mob.png";


const MentorHeadings = {
    [availableCourses.PYTHON]:
        <h1 className="python-mentor-heading">
            Learn with <strong>
                Industry Experienced Mentor</strong>
        </h1>,
    [availableCourses.LLD]:
        <h1 className="lld-mentor-heading">
            Learn with <span className="blue-heading-tag">
                Industry Experienced Mentor</span>
        </h1>,
    [availableCourses.CPP]:
        <h1 className="cpp-mentor-heading">
            Learn with <br className="mentor-line-break" />
            Industry Experienced Mentor
        </h1>,
    [availableCourses.RENAISSANCE]:
        <h1 className="ren-mentor-heading">
            Learn with <br className="mentor-line-break" />
            Industry Experienced Mentor
        </h1>,
    [availableCourses.CRASH_COURSE]:
        <h1 className="dsa-mentor-heading">
            Learn with <br className="mentor-line-break" />
            Industry Experienced Mentor
        </h1>,
};

const MentorCardDetails =
{
    img: vivekMentorIcon,
    imgMob: vivekMentorIconMob,
    link: "https://in.linkedin.com/in/vivekanand-vivek-7a4ab388",
    name: "Vivekanand Vivek",
    description: (
        <p>
            Vivek has taught <span className="mentor-bold-text">10,000+</span> students through on campus workshops and
            online courses on Interview Preparation. He has worked at{" "}
            <span className="mentor-bold-text">Amazon with its High Scale Systems for three years</span> after graduating
            from IIT. He is loved by his students for his lucid in-depth explanations and
            ability to make people think through problems.
        </p>
    ),
    gmail: "mailto:vivek@programmingpathshala.com",
};

export { MentorCardDetails, MentorHeadings };
