export const handleApplyCouponCode = ({ couponCode: COUPON_CODE, setCouponCode }) => {
    document.getElementById("ppa-course-coupon-code-input")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
    });

    setTimeout(() => {
        document
            .getElementById("ppa-course-coupon-code-input")
            ?.classList.add("shining-button-to-attract-click");
    }, 1200);

    setTimeout(() => {
        document
            .getElementById("ppa-course-coupon-code-input")
            ?.classList.remove("shining-button-to-attract-click");
    }, 5000);

    const timeOffsetToStart = 2000;
    const delayBetweenKeyStrokes = 220;

    for (let i = 0; i < COUPON_CODE.length; i++) {
        let text = COUPON_CODE.substring(0, i + 1);
        setTimeout(() => {
            if (document.getElementById("ppa-course-coupon-code-input")) {
                document.getElementById("ppa-course-coupon-code-input").value = text;
            }
        }, timeOffsetToStart + i * delayBetweenKeyStrokes);
    }

    const buttonGlowStartOffset =
        timeOffsetToStart + COUPON_CODE.length * delayBetweenKeyStrokes + 1000;
    const buttonGlowOn = 2500;

    setTimeout(() => {
        setCouponCode(COUPON_CODE);
        document
            .getElementById("ppa-course-apply-coupon-code-button")
            ?.classList.add("shining-button-to-attract-click");
    }, buttonGlowStartOffset);

    setTimeout(() => {
        document.getElementById("ppa-course-apply-coupon-code-button")?.click();
    }, buttonGlowStartOffset + buttonGlowOn / 2);

    setTimeout(() => {
        document
            .getElementById("ppa-course-apply-coupon-code-button")
            ?.classList.remove("shining-button-to-attract-click");
    }, buttonGlowStartOffset + buttonGlowOn);
};
