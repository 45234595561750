import React from "react";
import CancellationPolicy from "./CancellationPolicy/CancellationPolicy";
import RefundBanner from "./RefundBanner/RefundBanner";

const RefundPolicy = () => {
    return (
        <div>
            <RefundBanner />
            <CancellationPolicy />
        </div>
    );
};

export default RefundPolicy;
