import "./styles/signup.css";
import { companyLogos } from "../../../Constants/LogInFlowConstants";
const CompanyAlumni = (props) => {
    const { alumni } = props;
    return (
        <div className="company-logo-success-story-wrapper">
            <div className="company-logo-success-story-content">
                <p className="company-logo-success-story-heading">
                    Alumini now work at top companies like:
                </p>
                <div className="company-logos-wrapper">
                    {companyLogos &&
                        companyLogos.map((companyLogo) => (
                            <div className="company-logo-img">
                                <img src={companyLogo} alt="" />
                            </div>
                        ))}
                </div>
            </div>
            <div className="alumni-wrapper">
                <p className="alumni-desc">{alumni.desc}</p>
                <p className="alumni-name">{alumni.name}</p>
                <div className="sde-company-logo-wrapper">
                    SDE
                    <img className="alumni-logo" src={alumni.company_logo} alt="" />
                </div>
            </div>
            <div className="success-story-wrapper"></div>
        </div>
    );
};

export default CompanyAlumni;
