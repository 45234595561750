const Reacttopics = [
    {
        heading: "HTML and CSS",
        subtopics: [
            "HTML Container and Non Container Tags",
            "DOM Tree and using divs for its creation.",
            "CSS and Layout: Flexbox/Grid Frameworks.",
            "Measurements and Sizes in CSS: Height/width/Font size/padding/margin (px, vw,vh,rem,%)",
            "Colors, Fonts and Working With Figma Design",
            "Media queries and Responsiveness",
            "Writing frontend pages of your project using HTML",
        ],
    },
    {
        heading: "CSS and JS Frameworks ",
        subtopics: [
            "SEO Impact by Title, Meta tags, etc",
            "CSS/JS Frameworks - Bootstrap, Tailwind CSS",
            "Beautifying the frontend pages using CSS Frameworks",
            "Javascript introduction: Dynamicity of Website",
        ],
    },
    {
        heading: "Javascript",
        subtopics: [
            "JavaScript Internal Working using Event Loop, Web APIs, etc",
            "JavaScript Specific Concepts like Variable Hoisting, Closures, Arrow Functions, Map/Filter/Reduce, Deep vs Shallow Cloning, == vs ===, Debouncing & Throttling, etc",
            " Objects & Proto - Why is everything an object in JS?",
            "Async JS: Promises, async, await, then",
            "Error Handling",
            " Side project: Tic Tac Toe Game using JS. Integrating timer and timer based result.",
        ],
    },
    {
        heading: "React ",
        subtopics: [
            "Reactjs - Writing your first app using create react app",
            "Components vs Containers: Clean way to split and write React Code",
            "State and Props: Working with Class vs Functional Components",
            "React Component Lifecycle Methods",
            "React Hooks",
            "React Router",
            "Using Local Storage and cookies",
            "Converting our plain HTML/CSS Pages to be written with ReactJS",
        ],
    },
    {
        heading: "Deployment and next steps ",
        subtopics: [
            "Learning Github",
            "Learning how deployment happens and deploying your frontend to a live link",
            "Expanding the horizon for future: Building backends and databases",
        ],
    },
];

export { Reacttopics };
