import IntroBeginnerIcon from "../../../img/intro-beginner-icon.png";
import IntroDevelopIcon from "../../../img/intro-develop-icon.png";
import IntroLearnIcon from "../../../img/intro-learn-icon.png";
import IntroTimerIcon from "../../../img/intro-timer-icon.png";
const IntroPoints = [
    {
        text: "Learn OOPs, Design Patterns and SOLID Principles.",
        img: IntroLearnIcon,
    },
    {
        text: "Develop scalable and resilient concurrent applications with thread synchronization and locking.",
        img: IntroDevelopIcon,
    },
    {
        text: "Beginner Friendly, Basic Background in Java is a plus.",
        img: IntroBeginnerIcon,
    },
    {
        text: "Estimated Duration: 6 months.",
        img: IntroTimerIcon,
    },
];

export default IntroPoints;
