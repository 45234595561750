import React from "react";
import "./Styles/Prerequisites.css";
import prerequisiteImg from "../../img/python-prerequisitewing.svg";

const Prerequisites = () => {
    return (
        <div className="cpp-pre-requisites-wrapper">
            <div className="cpp-pre-requisites-image-left">
                <img src={prerequisiteImg} alt="" />
            </div>
            <div className="cpp-pre-requisites-content">
                <h1>But wait, what are the pre-requisites for this course?</h1>
                <p>No prior experience in any programming language is required</p>
            </div>
            <div className="cpp-pre-requisites-image-right">
                <img src={prerequisiteImg} alt="" />
            </div>
        </div>
    );
};

export default Prerequisites;
