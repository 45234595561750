import IntroLearnIcon from "../../../img/cpp-learn-icon.png";
import IntroDevelopIcon from "../../../img/cpp-develop-icon.png";
import IntroBeginnerIcon from "../../../img/cpp-beginner-icon.png";
import IntroTimerIcon from "../../../img/cpp-timer-icon.png";

const DsaBannerContents = {
    introCourseHeading: (
        <h1>
            Data Structures & <br /> Algorithms Essentials
        </h1>
    ),
    introCourseDescription: (
        <h2>
            The only DSA Course you need to be able to ace your next coding interview.
        </h2>
    ),
    introPoints: [
        {
            text: <span>Start your path in Coding and competitive programming.</span>,
            img: IntroLearnIcon,
        },
        {
            text: <span>Ace your next DSA interview.</span>,
            img: IntroDevelopIcon,
        },
        {
            text: <span>Beginner Friendly.</span>,
            img: IntroBeginnerIcon,
        },
        {
            text: (
                <span>
                    Estimated Duration: <strong>3 months</strong>
                </span>
            ),
            img: IntroTimerIcon,
        },
    ],
};

export { DsaBannerContents };
