import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreBlogs.css";
import arrowRight from "../../img/arrow-right.png";
import ellipse from "../../img/Ellipse.svg";
import { BlogContents } from "./ExploreBlogsCardDetails";
import { availableCourses } from "../../courses/CourseConstants";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <img className={className} onClick={onClick} src={arrowNext} alt="next" />
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />
    );
}

const ExploreBlogs = (props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    const currentCourse = props.course;
    const getHeading = () => {
        if (currentCourse === availableCourses.PYTHON)
            return BlogContents.blogPythonHeading;
        if (currentCourse === availableCourses.LLD)
            return BlogContents.blogLLDHeading;
        if (currentCourse === availableCourses.CPP)
            return BlogContents.blogCPPHeading;
        if (currentCourse === availableCourses.RENAISSANCE)
            return BlogContents.blogRenHeading;
        if (currentCourse === availableCourses.CRASH_COURSE)
            return BlogContents.blogDsaHeading;
    };
    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {BlogContents.blogCardDetails &&
                    BlogContents.blogCardDetails.map((data, index) => (
                        <a href={data.blogLink} className="blogs-card" key={index}>
                            <div className="blogs-card-img-wrapper">
                                <img src={data.img} alt="" />
                            </div>
                            <div className="blogs-date-time-wrapper">
                                <p className="blogs-date">{data.date}</p>
                                <div className="ellipse-icon">
                                    <img src={ellipse} alt="" />
                                </div>
                                <p className="blogs-time">{data.time}</p>
                            </div>
                            <p className="blogs-card-desc">{data.desc}</p>
                        </a>
                    ))}
            </Slider>
        );
    };

    return (
        <>
            <div className="blogs-wrapper">
                {getHeading()}
                {getSlidingStories()}
            </div>
            <div
                className={`${GTM_TRACKER.BLOGS} read-more-blogs`}
                onClick={() => window.open(process.env.REACT_APP_BLOGS_URL)}
            >
                <p>Read More Blogs</p>
                <img src={arrowRight} alt="" />
            </div>
        </>
    );
};

export default ExploreBlogs;
