import React from "react";
import Header from "../../Header/Header";
import Footer from "../../LandingPage/Footer";
import TermsAndCondition from "../TermsAndConditions";

const TermsAndConditionRoute = () => {
    return (
        <div>
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <TermsAndCondition />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default TermsAndConditionRoute;
