import manWithQuestionMark from "../../../img/confused-man 1.svg";

const ReactCourseForContents = {
    sectionTitle: "Who this course is for",
    sectionSubTitle:
        "College graduates who are enthusiastic about frontend development and want to build an impactful project for their resume for on/off campus placements.",

    sectionSummary:
        "The course is also suitable for working professionals who are currently in a non-development role and want to build a good project to try for Core Software Development/Frontend roles.",

    sectionIllustrator: manWithQuestionMark,
    illustratorCss: "react-course-for-illustrator",
};

export { ReactCourseForContents };
