const LowLevelDesignCurriculum = [
    {
        heading: "Clean Coding",
        data: [
            "1.1 Fundamentals of clean coding principles ",
            "1.2 Best practices for naming variables and functions",
            "1.3 Modularity and levels of abstraction.",
        ],
    },
    {
        heading: "Object Oriented principles & their applications",
        data: [
            "2.1 Why OOPs? Structuring data and algorithms together.",
            "2.2 Objects and Classes. Constructors, this keyword.",
            "2.3 Final keyword and concept of immutability.",
            "2.4 Pillars of OOPS and their practical relevance: Abstraction, Encapsulation, Polymorphism, Inheritance ",
            "2.5 Static variables and methods",
            "2.6 Problems with Inheritance.",
            "2.7 Making generic functionalities with  Polymorphism ",
            "2.8 Access modifiers",
        ],
    },
    {
        heading: "Deciphering Design principles ",
        data: [
            "3.1 Single Responsibility principle",
            "3.2 Open close principle",
            "3.3 Liskov Substitution principle",
            "3.4 Interface Segregation principle",
            "3.5 Dependency Inversion principle",
        ],
    },
    {
        heading: "Design Patterns and Case Studies",
        data: [
            "4.1 Top-Bottom flow of thinking before attempting any design problem.",
            "4.2 Design a Library Management System",
            "4.3 Strategy Design Pattern ",
            "4.4 Builder Design Pattern",
            "4.5 Factory Design Pattern",
            "4.6 Abstract Factory Design Pattern",
            "4.7 Design a Food delivery system like Zomato",
            "4.8 Decorator Design Pattern",
            "4.9 Design a Game of Chess",
            "4.10 Design a Parking Lot",
            "4.11 State Design Pattern",
            "4.12 Design an ATM",
            "4.13 Design a Json Parser Module",
            "4.14 Observer Design Pattern",
            "4.15 Design a Stock Trading App",
            "4.16 Chain of Responsibility Design Pattern",
            "4.17 Design a Logging System",
            "4.18 Design an Elevator System",
            "4.19 Command Design Pattern",
            "4.20 Design an e-commerce like Amazon",
            "4.21 Design an in-memory MySQL Server",
            "4.22 Design an extensible Caching library",
            "4.23 Singleton Design Pattern",
            "4.24 Design a social media Platform like Facebook",
            "4.25 Remaining important Design Patterns and their practical applications.",
        ],
    },
    {
        heading: "Testability ",
        data: [
            "5.1 What is Unit Testing",
            "5.2 Mockingand Assertion",
            "5.3 Why need Testing Frameworks: Junit, Mockito",
            "5.4 Writing robust tests",
            "5.5 Dependency Injection and Inversion of Control",
            "5.6 Great Testability is a consequence of Good LLD",
            "5.7 Things to avoid ",
            "5.8 Dealing with worst cases: PowerMock",
        ],
    },
];

const ConcurrrencyCurriculum = [
    {
        heading: "Concurrency vs. Parallelism, Thread safety ",
        data: [
            "1.1 Context switches, task scheduling, Thread stack",
            "1.2 Race conditions and Locks",
            "1.3 Reentrant locks and Thread safety measures.",
        ],
    },
    {
        heading: "Volatile, Monitors, Thread Synchronization and signaling",
        data: [
            "2.1 Memory visibility issues",
            "2.2 Busy waiting vs wait/notify",
            "2.3 Challenging Exercises, Problem solving with threads",
        ],
    },
    {
        heading: "Deadlocks, Producer Consumer problem, Dining Philosophers problem",
        data: [
            "3.1 Deadlock, Livelock",
            "3.2 Coding Dining Philosophers problem, Producer Consumer problem and its variants",
        ],
    },
    {
        heading: "Explicit Locks, ReadWrite Locks Blocking Queues, Synchronizers",
        data: [
            "4.1 Thread safe Data structures, Hand over Hand locking",
            "4.2 Creating our own locks and ensuring reentrancy",
            "4.3 Latch, Semaphore, FutureTask, Barrier",
        ],
    },
    {
        heading: "Thread Pool, Executor Service, Non Blocking Algorithms",
        data: [
            "5.1 Building our own thread pool, understanding Executor Service.",
            "5.2 Exercises and Problem solving, Building a thread-safe hashmap, lock-striping.",
            "5.3 Atomic Data Types",
        ],
    },
];

export { LowLevelDesignCurriculum, ConcurrrencyCurriculum };
