import axios from "axios";
import {
    HEADERS,
    HTTP_SUCCESS,
    SIGNUP_AND_LOGIN_PAGE_STEPS,
} from "../../Constants/GlobalConstants";
import axiosInstance from "../../Utilities/RequestWrapper";
import { afterLogin } from "../LoginFlow/Login/LoginClient";
const confirmPassword = (
	email,
	temporaryPassword,
	newPassword,
	setConfirmPwdSubmitLoading,
	setConfirmPwdSubmitError,
	setConfirmPwdSubmitErrorMessage,
	setConfirmPwdSubmitSuccess,
	setconfirmPwdSubmitSuccessMessage,

) => {
    axiosInstance
		.post(
			process.env.REACT_APP_AUTH_URL + "/confirmpassword",
			{
				username: email,
				verificationCode: temporaryPassword,
				password: newPassword,
			},
			{ headers: HEADERS }
		)
		.then((res) => {
			if (res.status === HTTP_SUCCESS) {
				if (res.data.Response.message === "Password changed successfully.") {
					setConfirmPwdSubmitLoading(false);
					setConfirmPwdSubmitError(false);
					setConfirmPwdSubmitErrorMessage("");
					setConfirmPwdSubmitSuccess(true);
					setconfirmPwdSubmitSuccessMessage("Your password has been changed successfully. Please use your new password to Log In")
				}
			}
		})
		.catch((error) => {
			setConfirmPwdSubmitLoading(false);
			setConfirmPwdSubmitError(true);
			error.response
				? setConfirmPwdSubmitErrorMessage(error.response.data.errorMessage)
				: setConfirmPwdSubmitErrorMessage(error.message);
		});
};

const changePassword = (
    email,
    temporaryPassword,
    newPassword,
    setSignUpAndLoginStep,
    setConfirmPwdSubmitErrorMessage,
    setLoaded,
    redirectUser,
    setStatesAfterIsSubscribed
) => {
    axiosInstance
        .post(
            process.env.REACT_APP_AUTH_URL + "/changepassword",
            {
                username: email,
                oldPassword: temporaryPassword,
                password: newPassword,
            },
            { headers: HEADERS }
        )
        .then((response) => {
            if (response.status === HTTP_SUCCESS) {
                afterLogin(
                    response.data.Response.IdToken,
                    setSignUpAndLoginStep,
                    SIGNUP_AND_LOGIN_PAGE_STEPS.CHANGE_PASSWORD,
                    setLoaded,
                    redirectUser,
                    setStatesAfterIsSubscribed
                );
            }
        })
        .catch((error) => {
            setLoaded(true);
            error.response
                ? setConfirmPwdSubmitErrorMessage(error.response.data.errorMessage)
                : setConfirmPwdSubmitErrorMessage(error.message);
        });
};


export { confirmPassword, changePassword };
