const CppOutcomeContents = {
    sectionTitle: "Learning Outcomes:",
    OutcomesList: [
        "Understand C++ concepts like Data Types, Values, Operators.",
        "Learn to read and write codes.",
        "Write and Debug C++ Programs.",
        "Apply C++ in other domains."
    ],
};

export { CppOutcomeContents };
