const Cpptopics = [
    {
        id: 1,
        module: 1,
        heading: "Introduction to Programming",
        subtopics: [ 
            "1.1 What is Programming?",
            "1.2 Running a Program",
            "1.3 Understanding a Small Program",
        ],
    },
    {
        id: 2,
        module: 2,
        heading: "Data Types",
        subtopics: [ 
            "2.1 Fundamental Data types",
            "2.2 Integer Overflow",
            "2.3 Type Casting",
            "2.4 ASCII Codes",
            "2.5 Precision Issues",
        ],
    },
    {
        id: 3,
        module: 3,
        heading: "Input Output",
        subtopics: [
            "3.1 How to take space input (space separated, input with different data type etc)",
            "3.2 How to print output",
        ],
    },
    {
        id: 4,
        module: 4,
        heading: "Transitioning from C to C++",
        subtopics: [ 
            "4.1 Differences b/w C and C++",
            "4.2 Some challenging Practice problems",
            "4.3 Vectors",
            "4.4 Iterators",
            "4.5 Pairs",
            "4.6 Strings",
            "4.7 Ordered and Unordered maps",
            "4.8 Ordered and Unordered sets",
            "4.9 Problems involving above data structures",
        ],
    },

];

export { Cpptopics };
