import paloalto from "../../../img/paloalto-logo.png";
import amazon from "../../../img/amazon-audience-logo.png";
import microsoft from "../../../img/microsoft-logo.png";
import codenation from "../../../img/codenation-logo.png";
import jaguar from "../../../img/jaguar-logo.png";
import BalvinderKaurImg from "../../../img/balvinderKaur.webp";
import ZealVagadiaImg from "../../../img/zealVagadia.webp";
import AnkitGuddewalaImg from "../../../img/ankitGuddewala.webp"; 
import ChetanVermaImg from "../../../img/chetanVerma.webp";
import VinayakNayyarImg from "../../../img/vinayakNayyar.webp";
import BalvinderKaurMobileImg from "../../../img/balvinder-kaur-mob.png";
import ZealVagadiaMobileImg from "../../../img/zeal-vagadia-mob.png";
import AnkitGuddewalaMobileImg from "../../../img/ankit-guddewala-mob.png";
import ChetanVermaMobileImg from "../../../img/chetan-verma-mob.png";
import VinayakNayyarMobileImg from "../../../img/vinayak-nayyar-mob.png";

const RenaissanceAudienceContent = [
    {
        title: <p>A College Student</p>,
        description: <p>Like <strong>Balvinder Kaur</strong>, who graduated from LPU to land a Coveted Software Engineering role at <img src={paloalto} alt=""/></p>,
        img: BalvinderKaurImg,
        mobileImg: BalvinderKaurMobileImg,
        imgWrapper: "",
        link: "https://www.linkedin.com/in/balvinderkaur17/",
    },
    {
        title: "A Beginner in the Tech World with <1 year experience. ",
        description: <p>Like <strong>Zeal Vagadia</strong>, whose hard work and preparation resulted in landing a role at <img src={amazon} alt="" /></p>,
        img: ZealVagadiaImg,
        mobileImg: ZealVagadiaMobileImg,
        imgWrapper: "zeal-chetan-image-wrapper",
        link: "https://www.linkedin.com/in/zeal-vagadia-77b029182/",
    },
    {
        title: <p>Switching from service based to<br /> product based role</p>,
        description: <p>Like <strong>Ankit Guddewala</strong>, who transitioned from 
        service based to product based role at <img src={microsoft} alt=""/></p>,
        img: AnkitGuddewalaImg,
        imgWrapper: "",
        mobileImg: AnkitGuddewalaMobileImg,
        link: "https://www.linkedin.com/in/ankitguddewala/",
    },
    {
        title: <p>Switching from Non-Development<br /> role to Development role</p>,
        description: <p>Like <strong>Chetan Verma</strong>, who started his career as
        an Animator and switched to a SDE role at <img src={codenation} alt="" /></p>,
        img: ChetanVermaImg,
        imgWrapper: "zeal-chetan-image-wrapper",
        mobileImg: ChetanVermaMobileImg,
        link: "https://www.linkedin.com/in/chetan-verma-633577146",
    },
    {
        title: <p>Experienced professional willing to level up your career</p>,
        description: <p>Like <strong>Vinayak Nayyar</strong>, studied hard alongside work, and is now Principal Engineer at Jaguar Land Rover India<img src={jaguar} alt="" /></p>,
        img: VinayakNayyarImg,
        imgWrapper: "",
        mobileImg: VinayakNayyarMobileImg,
        link: "https://www.linkedin.com/in/vinayak-nayyar/",
    },
]

export default RenaissanceAudienceContent