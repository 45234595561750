import LearningOutcomesButton from "../../../img/learning-outcomes-button.svg";

const ReactOutcomeContents = {
    sectionTitle: "Learning Outcomes",
    sectionDescription: (
        <p>
            This course will equip you to brilliantly build <br /> products from scratch. You will
            <img className="right-arrow" src={LearningOutcomesButton} alt="" />
        </p>
    ),
    OutcomesList: [
        "Learn state of the art Web Development frameworks by learning React for Frontend.",

        "Get introduced to standard software development life cycle.",

        "Make an end to end live working project and learn to deploy it.",
    ],
};

export { ReactOutcomeContents };
