import React from "react";
import { PythonAudienceContents } from "./Content/PythonAudienceContents";
import "./styles/Audience.css";

const Audience = () => {
    const audienceContents = PythonAudienceContents;
    return (
        <div className="python-audience-wrapper">
            <div className="python-audience-heading-wrapper">
                {audienceContents.sectionTitle}
                {audienceContents.sectionSubTitle}
            </div>
            <div className="python-audience-card-wrapper">
                {audienceContents.sectionOfferings &&
                    audienceContents.sectionOfferings.map((audience) => (
                        <div className="python-audience-card">
                            <div className="python-audience-img">
                                <img
                                    src={audience.img}
                                    alt="" />
                            </div>
                            <div className="python-audience-text">
                                {audience.text}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Audience;
