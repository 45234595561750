import React from "react";
import TheFaqsTabs from "./TheFaqsTabs";
import "./../LandingPage/styles/TheFaqs.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import FaqSelectMenuForMob from "./FaqSelectMenuForMob";
import "../../components/LandingPage/styles/CustomAccordion.css";

const TheFaqs = () => {
    const { windowWidth } = useWindowDimensions();

    const faqContent = () => {
        return (
            <div className="faq-section-content">
                <h2>The FAQs</h2>
                <h3>Help Center</h3>
            </div>
        );
    };

    return (
        <div>
            <div className="">{faqContent()}</div>
            <div className="the-faqs-tabs-section">
                {windowWidth > 600 ? <TheFaqsTabs action={""} /> : <FaqSelectMenuForMob />}
            </div>
        </div>
    );
};

export default TheFaqs;
