import curriculumImg from "../img/courseDropDownCurriculumImg.png";
import weekendClassesImg from "../img/courseDropDownWeekendClassesImg.png";
import learnAnywhereImg from "../img/courseDropDownLearnAnywhereImg.png";
import handPickedProbImg from "../img/courseDropDownHandPickedProbImg.png";
import buildingProbSolvingImg from "../img/courseDropDownBuildingProbSolvingImg.png";
import mockInterViewImg from "../img/courseDropDownMockInterViewImg.png";
import expierencedMentorsImg from "../img/courseDropDownExpierencedMentorsImg.png";
import doubtResolutionImg from "../img/courseDropDowndoubtResolutionImg.png";
import peerGroupImg from "../img/courseDropDownPeerGroupImg.png";
import resumeImg from "../img/courseDropDownResumeImg.png";
import mentors from "../img/mentor.png";
import lectures from "../img/lectures.png";
import referrals from "../img/refferals.png";
import language from "../img/language.png";
import liveClass from "../img/liveClass.png";
import developer from "../img/developer.png";
import VivekLearnWithImg from "../img/VivekLearnWithImg.png";
import BharatearnWithImg from "../img/BharatLearnWith.png";
import linkedinImg from "../img/linkedinImg.png";
import amazonImg from "../img/amazon-logo.png";
import towerImg from "../img/Tower.png";
import AyushSrivastavaInspireStories from "../img/AyushSrivastavaInspireStories.png";
import ZealVagadiaInspireStories from "../img/ZealVagadiaInspireStories.svg";
import AnkitGuddewalaOurMentor from "../img/AnkitGuddewalaOurMentor.png";
import ManavBhallaOurMentor from "../img/ManavBhallaOurMentor.png";
import ZafirAhmadOurMentor from "../img/ZafirAhmadOurMentor.png";
import VivekPanchalOurMentor from "../img/VivekPanchalOurMentor.png";
import HarshithInspiringStories from "../img/HarshithInspiringStories.png";

const WHATS_APP_LINK = "https://wa.me/";
const PPA_ADMIN_PHONE_NUMBER = 919871723495;
const PPA_ADMIN_PHONE_NUMBER_TELEGRAM = "tel:+91-9871723495";
const PAGINATION_PER_PAGE = 6;
const ADMIN_WHATSAPP_MESSAGE =
    "?text=Hi,%20I'm%20interested%20in%20Renaissance.%20But%20I%20have%20some%20queries.";
const INSPIRING_STORIES_TITLE = "Success Stories";
const INSPIRING_STORIES_HEADING = "They did it. So can you.";

const whatWeOfferList = [
    {
        alt: "academic",
        image: developer,
        title: "Learn DSA, CS Fundamentals, System Design and Low level design.",
    },
    {
        alt: "academic",
        image: mentors,
        title: "IIT Graduate Instructors from Amazon.",
    },
    {
        alt: "chart",
        image: lectures,
        title: "350+ hours of lectures | 600+ handpicked problems.",
    },
    {
        alt: "language-img-icon",
        image: language,
        title: "Build Industry Level Full Stack Project.",
    },
    {
        alt: "live-classes-img",
        image: liveClass,
        title: "Beginner Friendly, Code in any language of your choice.",
    },
    {
        alt: "referral",
        image: referrals,
        title: "Mock interviews and Performance based Referrals.",
    },
];

const sideNavigationForMob = [
    { name: "Renaissance" },
    { name: " Stories" },
    { name: "Hire With Us" },
    { name: "Pricing" },
    { name: "Blogs" },
];

const dropDownDetailsLeft = [
    {
        id: 1,
        question: "In - depth curriculum",
        answer: "With a proven curriculum on Data Structures, Algorithms, System Design, Low Level Design and Computer Science Fundamentals in one place - you will not have to look anywhere else. ",
        image: curriculumImg,
    },
    {
        id: 2,
        question: "Topic - Wise Weekend Live Classes",
        answer: "Build your thought process to solve new problems in real time, interact with your mentors and peers, and ask your questions. ",
        image: weekendClassesImg,
    },
    {
        id: 3,
        question: "350+ hours of concept video lectures",
        answer: "Our whiteboard based concept video lectures are designed to dive deep into topics so you can learn at your own convenience and pace, while having a real classroom like experience - anytime, anywhere. ",
        image: learnAnywhereImg,
    },
    {
        id: 4,
        question: "600+ Handpicked Problems for Practice & Assessments",
        answer: "Our handpicked problems will give you a rich and diverse practice experience, covering all possible patterns and variations around the concepts for your 100% preparation.",
        image: handPickedProbImg,
    },
    {
        id: 5,
        question: "Build your intuition for problem solving",
        answer: "Renaissance is designed to stimulate your intellect, build the right intuition, and help you think through new problems independently. ",
        image: buildingProbSolvingImg,
    },
];

const dropDownDetailsRight = [
    {
        id: 6,
        question: "Mock Interviews and Referrals",
        answer: "When you are prepared enough, we take your mock interviews, give you feedback, and refer you to tech companies based on your performance in the course.",
        image: mockInterViewImg,
    },
    {
        id: 7,
        question: "Industry Experienced Mentors",
        answer: "With a cumulative 10+ years of rich industry experience from companies like Amazon, Tower Research Capital, Google and Rubrik - our mentors have helped 10,000+ students get placed at top tech companies.",
        image: expierencedMentorsImg,
    },
    {
        id: 8,
        question: "Real Time Doubt Resolution",
        answer: "Each time you are stuck on a concept or a problem, you can punch it on the discussion forum below the concept video or assignment. We help you in almost real-time, and give you hints to move in the right direction. ",
        image: doubtResolutionImg,
    },
    {
        id: 9,
        question: "Diverse Peer Group & Mentorship",
        answer: "At Renaissance, you get to interact with a diverse crowd of learners - from college students to working professionals, everyone comes from a different background and have something to offer. Along with the peer support, you will also have mentors to guide you and help you get the best job offer! ",
        image: peerGroupImg,
    },
    {
        id: 10,
        question: "Pause/Resume and Upgrade",
        answer: "Stuck somewhere and need a break? You can easily pause your subscription until you feel ready to get back to learning. Your subscription will extend by the number of days you pause for. Want to continue learning with us? You can extend your subscription with easy upgrade plans.",
        image: resumeImg,
    },
];

const learnWithCardDetails = [
    {
        profileImage: VivekLearnWithImg,
        linkedInImg: linkedinImg,
        companyLogo: amazonImg,
        mentorName: " Vivekanand Vivek",
        mentorDesc:
            "Vivek has taught 10,000+ students through on campus workshops and online courses on Data Structures and Algorithms. He has worked at Amazon for three years after graduating from IIT. He is loved by his students for his lucid in-depth explanations and ability to make people think through problems.",
        linkedIn:
            "https://www.linkedin.com/in/vivekanand-vivek-7a4ab388?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BOfz3U7yZRR%2BCr9iJUinvZg%3D%3D",
    },
    {
        profileImage: BharatearnWithImg,
        linkedInImg: linkedinImg,
        companyLogo: towerImg,
        mentorName: " Bharat Khanna",
        mentorDesc:
            "Bharat has worked in Tower Research Capital for two years after graduating from IIT. Because of his love for competitive coding, he is a candidate master at Codeforces and has mentored hundreds of students to crack their dream job. He is empathetic, patient and loves to teach budding programmers, who go on to become incredible software engineers.",
        linkedIn:
            "https://www.linkedin.com/in/bharat-khanna-717b4817b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BeAmE88IZSOSozf2eZxdnww%3D%3D",
    },
];

const InspiringStoriesProfileData = [
    {
        profileImg: AyushSrivastavaInspireStories,
        profileName: "Ayush Srivastava",
        linkedInLink: "https://www.linkedin.com/in/ayushsriv/",
        profileJob: "Software Engineer at Paypal",
        profileJobDesc:
            "Fortunately, I came across Programming Pathshala on Quora and I was amazed to see awesome reviews. I went through the website and checked the Curriculum, and moreover the fee of the course was in my budget and not overpriced.",
    },

    {
        profileImg: ZealVagadiaInspireStories,
        profileName: "Zeal Vagadia",
        linkedInLink: "https://www.linkedin.com/in/zeal-vagadia-77b029182/",
        profileJob: "Software Engineer at Amazon",
        profileJobDesc:
            "Whatever I am today, I owe it to Programming Pathshala and their life changing course. I think it is pretty much all we need to build problem solving skills.",
    },
    {
        profileImg: HarshithInspiringStories,
        profileName: "Harshit Jaiswal",
        linkedInLink: "https://www.linkedin.com/in/harshitt07/",
        profileJob: "Software Engineer at ShareChat",
        profileJobDesc:
            "Programming Pathshala is the best course. You don’t know what you get until you enrol and start preparing. It is the most Value for money course available online and You’re getting “Gold in the price of Silver”.",
    },
];

const ourMentorsData = [
    {
        profileImg: AnkitGuddewalaOurMentor,
        name: "Ankit Guddewala",
        job: "Software Engineer 2 at Microsoft",
        linkedIn: "https://www.linkedin.com/in/ankitguddewala",
    },
    {
        profileImg: ManavBhallaOurMentor,
        name: "Manav Bhalla",
        job: "Software Engineer 1 at Amazon",
        linkedIn: "https://www.linkedin.com/in/manav-bhalla-161211a5",
    },
    {
        profileImg: ZafirAhmadOurMentor,
        name: "Zafir Ahmad",
        job: "Software Engineer 2 at Servicenow",
        linkedIn: "https://www.linkedin.com/in/zafir-ahmad",
    },
    // {
    //     profileImg: VivekPanchalOurMentor,
    //     name: "Vivek Panchal",
    //     job: "Software Engineer at Amazon",
    //     linkedIn: "https://www.linkedin.com/in/vivekpanchal444",
    // },
];
const theFaqsDropdownData = [
    {
        id: 1,
        question: "What all do I need to know before enrolling at Renaissance?",
        answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
        id: 2,
        question: "What all do I need to know before enrolling at Renaissance?",
        answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
        id: 3,
        question: "What all do I need to know before enrolling at Renaissance?",
        answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
        id: 4,
        question: "What all do I need to know before enrolling at Renaissance?",
        answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
];

const theFaqsTabsHeading = [
    { label: "Admission & Eligibility" },
    { label: "Curriculum & Schedule" },
    { label: "Pause & Subscription" },
    { label: "Course Outcomes" },
];

export {
    dropDownDetailsLeft,
    dropDownDetailsRight,
    ourMentorsData,
    theFaqsDropdownData,
    WHATS_APP_LINK,
    PPA_ADMIN_PHONE_NUMBER,
    PPA_ADMIN_PHONE_NUMBER_TELEGRAM,
    PAGINATION_PER_PAGE,
    ADMIN_WHATSAPP_MESSAGE,
    INSPIRING_STORIES_TITLE,
    INSPIRING_STORIES_HEADING,
    InspiringStoriesProfileData,
    theFaqsTabsHeading,
    whatWeOfferList,
    learnWithCardDetails,
    sideNavigationForMob,
};
