import renCourseImg from "../img/renCourseImg.svg";
import LLdcourseImg from "../img/LLdcourseImg.svg";
import beginwithCppImg from "../img/beginwithC++Img.svg";
import DSAImg from "../img/DSA.svg";
import reactImg from "../img/reactImg.svg";
import pythonImg from "../img/python.svg";
import { gaCourseNameMap } from "../courses/CourseConstants";
const courseCardDetails = [
    {
        courseBannerImg: renCourseImg,
        courseName: "renaissance",
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",
        courseLink: `/`,
        clickable: true,
        ctaButton: "Know More",
        gaCourseTracker: gaCourseNameMap.renaissance,
    },

    {
        courseBannerImg: LLdcourseImg,
        courseName: "low-level-design",
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        ctaButton: "Know More",
        courseLink: `/low-level-design`,
        clickable: true,
        gaCourseTracker: gaCourseNameMap["low-level-design"],
    },
    {
        courseBannerImg: DSAImg,
        courseName: "crash-course",
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
        ctaButton: "Know More",
        courseLink: `/dsa-and-algorithms-essentials`,
        clickable: true,
        gaCourseTracker: gaCourseNameMap["crash-course"],
    },
    // {
    // courseBannerImg: reactImg,
    // courseName: "react",
    // courseDescription:
    //     "Master the skills of a front-end engineer and build modern, responsive websites",
    // courseLink: `/become-a-frontend-engineer`,
    // clickable: true,
    // ctaButton: "Know More",
    // },
    {
        courseBannerImg: beginwithCppImg,
        courseName: "Begin with Programming in C++",
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        ctaButton: "Know More",
        courseLink: "/begin-with-c++",
        clickable: true,
        gaCourseTracker: gaCourseNameMap.cpp,
    },
    // {
    //     courseBannerImg: pythonImg,
    //     courseName: "Become a Python Expert",
    //     courseDescription:
    //         "Become a Python pro and unlock the door to endless career opportunities",
    //     clickable: false,
    //     ctaButton: "Coming Soon",
    // },
];
export { courseCardDetails };
