import React from "react";
import Button from "@mui/material/Button";
import { getCurrentCourse } from "../../courses/CourseUtility";
import ArrowForward from "@mui/icons-material/ArrowForward";

const ActionButton = (props) => {
    return (
        <div className="action-button">
            <Button
                sx={props.style}
                variant="contained"
                type={props.type}
            >
                {props.name}
                { props.hasArrow ?
                     <ArrowForward/>
                 : "" }
            </Button>
        </div>
    );
};

export default ActionButton;
