import React from "react"
import RenaissanceWhyLearnContent from "./Content/RenaissanceWhyLearnContent"
import WhyLearnImg from "../../img/ren-WhyLearn.webp"
import "./styles/WhyLearn.css"

const WhyLearn = () => {
  return (
    <div className="ren-whylearn-wrapper">
        <div className="whylearn-content">
            <h1> Why learn <br/ > Renaissance Plus?</h1>
            <h2>Our key highlights</h2>
            <img src={WhyLearnImg} alt="" className="whylearn-illustrator" />
        </div>
        <div className="whylearn-points-wrapper">
            {
                RenaissanceWhyLearnContent&& RenaissanceWhyLearnContent.map((data, index) => (
                    <div className="whylearn-points">
                        <img src={data.img} className="whylearn-icons" alt="" /> 
                        <div>
                            <div className="whylearn-title">{data.title}</div>
                            <div className="whylearn-description">{data.description}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default WhyLearn