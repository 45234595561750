const GTM_TRACKER = {
    LOGIN: "ga-login-btn",
    SIGN_UP: "ga-sign-up-btn",
    START_FREE_TRIAL: "ga-start-free-trial-btn",
    WHATSAPP: "ga-whatsapp-btn",
    SUCCESS_STORIES: "ga-success-stories-btn",
    HIRE_FROM_US: "ga-hire-from-us-btn",
    EXPLORE_COURSES: "ga-explore-courses-btn",
    BLOGS: "ga-blogs-btn",
    EVENTS: "ga-events-btn",
    CAMPUS_PROGRAM: "ga-campus-program-btn",
    DOWNLOAD_CURRICULUM: "ga-download-curriculum",
};

export { GTM_TRACKER };
