import lecturesImg from "../../../img/lectures-button.svg";
import caseStudyImg from "../../../img/case-study-button.svg";
import doubtSupportImg from "../../../img/doubt-support-button.svg";
import reactProjectsImg from "../../../img/reactProjectsIcon.svg";
import reactProjectImg from "../../../img/ReactProject.png";

const ReactAboutContents = {
    sectionTitle: "About this course",
    sectionDescription: (
        <>
            <p>
                Are you ready to take your Front End Engineering skills to the next level? We will
                help you do just that! In this course, you'll learn how to use cutting-edge
                technologies like HTML, CSS, JavaScript, and ReactJS to build stunning and
                interactive applications.
            </p>
            <br />
            <p>
                Get ready to roll up your sleeves and get hands-on with over 90 videos, 10+ problems
                and 5+ mini projects, culminating in a major Mentor-led Project where you'll have
                the chance to create a complete web application from start to finish by following
                industry standard practices.
            </p>
            <br />
            <p>
                By the end of this course, you'll be a pro at frontend engineering, with a portfolio
                of impressive projects to prove it. Don't miss out on this opportunity to become a
                top-notch frontend developer!
            </p>
            <br />
        </>
    ),
    AboutInfoWithImages: [
        {
            image: lecturesImg,
            desc: "90+ In depth Lectures",
        },
        {
            image: caseStudyImg,
            desc: "10+ Problems & 5+ mini projects ",
        },
        {
            image: doubtSupportImg,
            desc: "Real-time doubt support",
        },
        {
            image: reactProjectImg,
            desc: "A major Mentor Led Project",
        },
        {
            image: reactProjectsImg,
            desc: "Certificate of Completion",
        },
    ],
};

export { ReactAboutContents };
