import axios from "axios";
import Cookies from "universal-cookie";
import { HTTP_SUCCESS } from "../../../Constants/GlobalConstants";
import { setAuthorizationCookie, setCookieWhenRedirectingAfterGoogleSignIn } from "../../../cookie-manager/CookieManager";
import { hasFilledUserDetailsForm } from "../../../courses/CourseUtility";
import axiosInstance from "../../../Utilities/RequestWrapper";
import { checkCurrentCourseAndSubscriptionStatus } from "../../../courses/Strategies/RedirectionStrategy";
const afterGoogleLogin = (token, redirectUser, setStatesAfterIsSubscribed, redirect_url) => {
    setAuthorizationCookie(token);

    // Remove utm data after login
    localStorage.removeItem("utmParameters");

    axiosInstance
        .get(process.env.REACT_APP_UTILITY_URL + "/isSubscribed", {
            headers: {
                authorization: token,
            },
        })
        .then((response) => {
            if (!hasFilledUserDetailsForm(response.data)) {
                redirectUser("/start-free-trial");
                setStatesAfterIsSubscribed(response);
            } else {
                checkCurrentCourseAndSubscriptionStatus(response,redirectUser,setStatesAfterIsSubscribed, redirect_url)
            }
        })
        .catch((error) => {
            throw error;
        });
};

const redirectAfterGoogleSignIn = (authorization_code, redirectUser, setStatesAfterIsSubscribed, redirect_url) => {
    const cookies = new Cookies();

    axiosInstance
        .post(
            process.env.REACT_APP_AUTH_URL + "/loginwithgoogle",
            {},
            {
                headers: {
                    authorization_code: authorization_code,
                },
            }
        )
        .then((response) => {
            if (response.status === HTTP_SUCCESS) {
                // Set id_token and access_token in cookie after success
                setCookieWhenRedirectingAfterGoogleSignIn(
                    response.data.Response.idToken,
                    response.data.Response.accessToken
                );

                if (response.data.Response.isPasswordSettingNeeded) {
                    redirectUser("/signup-with-google");
                } else {
                    afterGoogleLogin(
                        cookies.get("id_token"),
                        redirectUser,
                        setStatesAfterIsSubscribed,
                        redirect_url
                    );
                }
            }
        })
        .catch((error) => {
            console.error('Google Login/Signup failed... ' + error);
        });
};

const setPasswordAfterGoogleLogin = (
    password,
    confirmPassword,
    setPasswordLoader,
    redirectUser,
    setStatesAfterIsSubscribed
) => {
    const cookies = new Cookies();
    axiosInstance
        .post(
            process.env.REACT_APP_AUTH_URL + "/set_password_after_google_login",
            {
                password: password,
                confirmPassword: confirmPassword,
            },
            {
                headers: {
                    id_token: cookies.get("id_token"),
                    access_token: cookies.get("access_token"),
                },
            }
        )
        .then((response) => {
            if (response.status === 200) {
                setPasswordLoader(false);
                afterGoogleLogin(cookies.get("id_token"), redirectUser, setStatesAfterIsSubscribed);
            }
        })
        .catch((error) => {
            setPasswordLoader(false);
        });
};

export { redirectAfterGoogleSignIn, setPasswordAfterGoogleLogin };
