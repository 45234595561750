const cancellationPolicyData = [
    {
        content:
            "If a cancellation is done by the user with valid reasons within 7 days after successful payment is done , 100% of paid fee will be refunded to the user after deducting the transaction charges. (usually 5-8% depending on mode of payment)",
    },
    {
        content: "No refund would be processed if cancellation is done after 7 days of payment.",
    },
];

export { cancellationPolicyData };
