import axios from "axios";
import Cookies from "universal-cookie";
import { Buffer } from "buffer";
import { setAuthorizationCookie } from "../cookie-manager/CookieManager";
import redirectToLandingPageStrategy from "../courses/Strategies/LandingPageRedirectionStrategy";
const cookies = new Cookies();

const isNULLEMPTYORUNDEFINED = (value) => {
    return value === null || value === undefined || value === "";
};

//If no token is provided we consider it not expired.
const isTokenExpired = (token) => {
    if (isNULLEMPTYORUNDEFINED(token)) return false;
    const base64Payload = token.split(".")[1];
    if (isNULLEMPTYORUNDEFINED(base64Payload)) return false;
    const payload = Buffer.from(base64Payload, "base64");
    const decodedPayload = JSON.parse(payload.toString());
    const expTime = parseInt(decodedPayload.exp);
    const curTime = new Date();
    const jwtExpTime = new Date(expTime * 1000);
    const isExpired = curTime > jwtExpTime ? true : false;
    return isExpired;
};

const removeCookiesAndLogout = () => {
    const cookies = new Cookies();
    if (cookies.get("id_token")) {
        cookies.remove("id_token", {
            path: "/",
            domain: ".programmingpathshala.com",
        });
        // Uncomment to run locally
        // cookies.remove("id_token");
    }
    if (cookies.get("access_token")) {
        cookies.remove("access_token", {
            path: "/",
            domain: ".programmingpathshala.com",
        });
        // Uncomment to run locally
        // cookies.remove("access_token");
    }
    if (cookies.get("authorization")) {
        cookies.remove("authorization", {
            path: "/",
            domain: ".programmingpathshala.com",
        });
        // Uncomment to run locally
        // cookies.remove("authorization");
    }
    // window.open(process.env.REACT_APP_FRONTEND_MAIN_URL, "_self");
    redirectToLandingPageStrategy();
};

const getFreshTokens = (expiredIdToken) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(`${process.env.REACT_APP_AUTH_URL}/getFreshTokens`, {
                    idToken: expiredIdToken,
                })
                .then((res) => resolve(res))
                .catch((err) => {
                    console.error("Error fetching fresh tokens : " + err.message);
                    return reject(err);
                });
        } catch (err) {
            console.error("Error in getFreshTokens : " + err.message);
            return reject(err);
        }
    });
};

const setFreshTokens = async (request) => {
    // If new token is set by other requests before
    // update auth header in current request and return
    if (!isTokenExpired(cookies.get("authorization"))) {
        request.headers.authorization = cookies.get("authorization");
        return request;
    }

    // Token is expired, fetch new token
    await getFreshTokens(cookies.get("authorization"))
        .then((res) => {
            // Set new auth token in cookie and current request
            setAuthorizationCookie(res.data.freshIdToken);
            request.headers.authorization = res.data.freshIdToken;
        })
        .catch((err) => {
            console.error(err);
            console.error("Unauthorized request...Logging out!");
            removeCookiesAndLogout();
        });
};

export {
    isNULLEMPTYORUNDEFINED,
    isTokenExpired,
    removeCookiesAndLogout,
    getFreshTokens,
    setFreshTokens,
};
