import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import googleIcon from "../../../resources/images/google.svg";
import seePassword from "../../../resources/images/seePassword.svg";
import hidePassword from "../../../resources/images/hidePassword.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { loginPageValidationSchema } from "../../../Utilities/Validation";
import "./styles/Login.css";
import { loginWithEmailAndPassword } from "./LoginClient";
import { SIGNUP_AND_LOGIN_PAGE_STEPS, LOGGED_IN_FROM } from "../../../Constants/GlobalConstants";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import "./../../Common/styles/CommonFunction.css";
import Loader from "../../Loader/Loader";
import Header from "../../Header/Header";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const Login = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [formSubmitError, setFormSubmitError] = useState(false);
    const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState("");
    const [formSubmitLoaded, setFormSubmitLoaded] = useState(true);
    const [loginStep, setLoginStep] = useState(SIGNUP_AND_LOGIN_PAGE_STEPS.LOGIN);
    const { loggedIn, currentLoggedInUserEmail, setStatesAfterIsSubscribed } = useGlobalContext();

    const query = useQuery();

    useEffect(() => {
        if (query.get("page") === LOGGED_IN_FROM.MAIN_PPA)
            localStorage.setItem("isLoggedInFromMainPage", true);
    }, []);

    useEffect(() => {
        setEmail(currentLoggedInUserEmail);
    }, [currentLoggedInUserEmail]);

    const redirectUser = (link) => navigate(link);

    const redirectIfLoggedIn = () => {
        if (loggedIn) {
            // navigate('/crack-coding-interviews');
            redirectToLandingPageStrategy();
        }
    };

    const [emailAndPassword, setError] = useState("");

    const onSubmit = () => {
        mixpanel.track("Clicked on password login");
        loginPageValidationSchema
            .validate({ email: email, password: password })
            .then(() => {
                setError("");
                setFormSubmitLoaded(false);
                setFormSubmitError(false);
                loginWithEmailAndPassword(
                    email,
                    password,
                    setFormSubmitError,
                    setFormSubmitErrorMessage,
                    setFormSubmitLoaded,
                    setLoginStep,
                    redirectUser,
                    setStatesAfterIsSubscribed,
                    query.get("redirect_url")
                );
            })
            .catch((err) => {
                setError(err.errors);
            });
    };

    const handleSignupClick = () => {
        mixpanel.track("Clicked on Dont have an account? Sign up");
        if (query.get("redirect_url")) {
            navigate(`/signup?redirect_url=${query.get("redirect_url")}`);
        } else {
            navigate("/signup");
        }
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
        }
    };

    const getLoginContent = () => {
        return (
            <section className="login-content-wrapper">
                {!formSubmitLoaded ? <Loader /> : <></>}
                <h2 className="common-page-title">Login</h2>
                <form className="login-content-form">
                    <label>
                        <p className="common-input-title">Email</p>
                        <input
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onKeyDown={onKeyDown}
                            type="email"
                            required
                            className="common-input-styles example"
                            value={email}
                        />
                    </label>
                    <label>
                        <p className="common-input-title">Password</p>

                        <section className="login-content-password-wrapper">
                            {isPasswordVisible ? (
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="text"
                                    value={password}
                                    className="common-input-styles"
                                    onKeyDown={onKeyDown}
                                />
                            ) : (
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    value={password}
                                    className="common-input-styles"
                                    onKeyDown={onKeyDown}
                                />
                            )}
                            {isPasswordVisible ? (
                                <img
                                    onClick={() => {
                                        mixpanel.track("Clicked on view password");
                                        setIsPasswordVisible(!isPasswordVisible);
                                    }}
                                    src={seePassword}
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => {
                                        mixpanel.track("Clicked on view password");
                                        setIsPasswordVisible(!isPasswordVisible);
                                    }}
                                    src={hidePassword}
                                    alt=""
                                />
                            )}
                        </section>
                    </label>
                    <div>
                        {emailAndPassword && <p>{emailAndPassword}</p>}
                        {formSubmitErrorMessage && (
                            <p className="error-text">{formSubmitErrorMessage}</p>
                        )}
                    </div>
                    <h4
                        className="forgot-password-text"
                        onClick={() => {
                            mixpanel.track("Clicked on Forget Password");
                            redirectUser("/forgot-password");
                        }}
                    >
                        Forgot password
                    </h4>
                    <section>
                        <div onClick={onSubmit} className="common-blue-gradient-CTA">
                            Login
                        </div>
                        <h3 className="login-content-OR">OR</h3>
                        <div
                            className="login-content-google-CTA"
                            onClick={() => {
                                mixpanel.track("Clicked on login with google");
                                if (query.get("redirect_url")) {
                                    window.open(
                                        process.env.REACT_APP_GOOGLE_OAUTH_LOGIN_URL +
                                            `&state=${query.get("redirect_url")}`,
                                        "_self"
                                    );
                                } else {
                                    window.open(
                                        process.env.REACT_APP_GOOGLE_OAUTH_LOGIN_URL,
                                        "_self"
                                    );
                                }
                            }}
                        >
                            <img src={googleIcon} alt="" />
                            <p>Login with Google</p>
                        </div>
                    </section>
                </form>
                <p onClick={handleSignupClick}>
                    Don't have an account? <span>Sign Up</span>
                </p>
            </section>
        );
    };

    return (
        <>
            {redirectIfLoggedIn()}
            <div className="header-sticky">
                <Header />
            </div>
            <section className="login-wrapper">{getLoginContent()}</section>
        </>
    );
};
