import React, { useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import {
  goToDashboard,
  startFreeTrial,
} from "../../courses/Strategies/RedirectionStrategy";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import ActionButton from "../Common/ActionButton";
import { useNavigate } from "react-router-dom";
import "./styles/AboutRenaissance.css";
import thumbnail from "../../img/renaissance-video-thumbnail.webp";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

const style = {
  color: "white",
  background:
    "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
  fontFamily: "Poppins-SemiBold",
  textTransform: "capitalize",
  padding: "15px 40px",
  fontSize: "1.4vw",
  width: "25vw",
};
const mobileStyle = {
  color: "white",
  background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
  fontFamily: "Poppins-SemiBold",
  textTransform: "capitalize",
  width: "90vw",
  padding: "2vw 15px",
  fontSize: "16px",
  whiteSpace: "noWrap",
}

const AboutRenaissance = () => {
  const { windowWidth } = useWindowDimensions();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    // set clicked to true instead of inverting it to permanently get out of thumbnail state
    // this prevents accidently clicking again to get back to thumbnail state
    setClicked(true); 
  };
  const {
    loggedIn,
    subscribed,
    everSubscribed,
    setShowOtpScreen,
    setCurrentLoggedInUserEmail,
    freeTrialFormFilled,
  } = useGlobalContext();
  const redirectUser = (link) => navigate(link);

  const getFreeTrialBtnForMobile = () => {
    return (
      <div
        onClick={() => {
          startFreeTrial(freeTrialFormFilled, redirectUser);
        }}
      >
        <ActionButton style={windowWidth < 600 ? mobileStyle : style} name="Try 7 Day Free Trial" />
      </div>
    );
  };

  const getDashBoardButton = () => {
    return (
      <div onClick={() => goToDashboard()}>
        <ActionButton style={windowWidth < 600 ? mobileStyle : style} name="Go to Dashboard" />
      </div>
    );
  };

  const getSignupButton = () => {
    return (
      <div
        className={GTM_TRACKER.START_FREE_TRIAL}
        onClick={() => {
          navigate("/signup");
        }}
      >
        <ActionButton
          style={windowWidth < 600 ? mobileStyle : style}
          name="Try 7 Day Free Trial&nbsp;"
          hasArrow={true}
        />
      </div>
    );
  };

  const getFreeTrailOrDashboardButton = () => {
    if (!loggedIn) return getSignupButton();
    else if (!everSubscribed) return getFreeTrialBtnForMobile();
    else return getDashBoardButton();
  };

  return (
    <div className="about-main-wrapper">
      <h2 className="about-heading">What is Renaissance Plus?</h2>
      <div className="about-video-wrapper" onClick={handleClick}>
        {clicked ? (
          <iframe
            className="about-video-wrapper"
            sandbox=""
            src="https://storage.googleapis.com/course-overview-videos/What%20is%20Renaissance_2.mp4"
            title="What is Renaissance Plus?"
            allowFullScreen
          ></iframe>
        ) : (
          <img src={thumbnail} alt="" className="about-thumbnail" />
        )}
      </div>
      {getFreeTrailOrDashboardButton()}
    </div>
  );
};

export default AboutRenaissance;
