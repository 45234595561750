import { useLocation } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Cookies from "universal-cookie";

var browserList = [
    { name: "Firefox", value: "Firefox" },
    { name: "Opera", value: "OPR" },
    { name: "Edge", value: "Edg" },
    { name: "Chrome", value: "Chrome" },
    { name: "Safari", value: "Safari" },
];

function useQuery() {
	return new URLSearchParams(useLocation().search);
};

let getBrowserDetails = () => {
    let userDetails = navigator.userAgent;
    for (let i in browserList) {
        if (userDetails.includes(browserList[i].value)) {
        return browserList[i].name || "Unknown Browser";
        }
    }
};
        
let validateEmail = (email) => {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const isNOTNULLEMPTYORUNDEFINED = (value) => {
    return !(value === null || value === undefined || value === "");
};

const validPhoneNumber = (number) => {
    const value = /^[0-9]{10}$/;
    return value.test(number);
};

const nullCheckIncomingParams = (query) => {
    return ((query.get("utm_source") || query.get("utm_campaign") || query.get("utm_medium") || query.get("utm_term") || query.get("utm_content")) == null);
};

const nullCheckExistingParams = () => {
    const utmParams = JSON.parse(localStorage.getItem('utmParameters'));
    return (utmParams == null || (utmParams.utmSource || utmParams.utmCampaign ||utmParams.utmMedium || utmParams.utmTerm || utmParams.utmContent) == null)
};

const setUTMParametersInLocalStorage = (query) => {
    if(!nullCheckExistingParams() && nullCheckIncomingParams(query))
        return;
    const utmParameters = {
        "utmSource" : query.get("utm_source"),
        "utmCampaign" : query.get("utm_campaign"),
        "utmMedium" : query.get("utm_medium"),
        "utmTerm" : query.get("utm_term"),
        "utmContent" : query.get("utm_content")
    }
    localStorage.setItem('utmParameters', JSON.stringify(utmParameters));
};

const setUtmCouponCodeInCookie = (query) => {
    const FIVE_DAYS_IN_SECONDS = 432000;
    const cookies = new Cookies();
    const couponCode = query.get("utm_coupon_code");

    if (couponCode) {
        cookies.set("utm_coupon_code", couponCode, {
            path: "/",
            domain: ".programmingpathshala.com",   // comment to run locally
            maxAge: FIVE_DAYS_IN_SECONDS,
        });
    }
};

const getUserAdDetails = () =>{
    const utmParams = JSON.parse(localStorage.getItem('utmParameters'));
    const userAdDetails = {
        "utm_source" : utmParams.utmSource, 
        "utm_medium" : utmParams.utmMedium,
        "utm_campaign" : utmParams.utmCampaign,
        "utm_term":utmParams.utmTerm,
        "utm_content" : utmParams.utmContent,
        "referrer" : document.referrer,
        "browser" : getBrowserDetails(),
        "device" : isMobile ? "Mobile" : "Desktop",
        "location_id" : window.location.href,
        "utm_type" : "course"
    }
    localStorage.removeItem('utmParameters');
    return userAdDetails;
};

export {
    validateEmail,
    isNOTNULLEMPTYORUNDEFINED,
    validPhoneNumber,
    setUTMParametersInLocalStorage,
    useQuery,
    getUserAdDetails,
    nullCheckExistingParams,
    setUtmCouponCodeInCookie,
    getBrowserDetails,
};
