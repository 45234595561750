import React from "react";
import { useState } from "react";
import "../../Common/ComponentsStyles/CommonAccordion.css";
import "../ComponentsStyles/CommonFaqs.css";
import CommonFaqAccordion from "./CommonFaqAccordion";
const Faq = (props) => {
    const { faqsContent } = props;
    const [openId, setOpenId] = useState(1);
    const recordAccordionId = (id) => {
        if (id === openId) setOpenId(-1);
        else setOpenId(id);
    };
    return (
        <div className="common-faq-wrapper">
            <h1>Frequently asked questions</h1>
            {faqsContent &&
                faqsContent.map((accordionData, index) => (
                    <div key={index}>
                        <CommonFaqAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionId}
                            isOpen={accordionData.id === openId}
                        />
                    </div>
                ))}
        </div>
    );
};

export default Faq;
