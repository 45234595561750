import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { freeTrialValidationSchema } from "../../Utilities/Validation";
import { startFreeTrial } from "./StartFreeTrialClient";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import "../LoginFlow/SignUp/styles/signup.css";
import { useNavigate } from "react-router-dom";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Cookies from "universal-cookie";
import mixpanel from "mixpanel-browser";
import redirectToLandingPageStrategy from "../../courses/Strategies/LandingPageRedirectionStrategy";
import CompanyAlumni from "../LoginFlow/SignUp/CompanyAlumni";
import { ALUMNI_CONTENT } from "../../Constants/LogInFlowConstants";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

export const StartFreeTrial = (props) => {
    const { windowWidth } = useWindowDimensions();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const PROFESSIONS = ["Student", "Working Professional", "On a Career Break", "Unemployed"];

    const EXPERIENCES = {
        "No experience yet": "Aspirant (No experience yet)",
        "Up to 6 months of experience": "Amateur (Up to 6 months of experience)",
        "6 months to 2 years of experience": "Techie Junior (6 months to 2 years of experience )",
        "2 to 4 years of experience": "“Pro” grammer (2 to 4 years of experience)",
        "More than 4 years of experience": "Code Veteran (More than 4 years of experience)",
    };
    const PROFICIENCY = [
        "Never written a line of code",
        "Know Fundamentals of a Programming Language",
        "Familiar with Basic Data Structures and Algorithms",
        "Proficient in solving Easy and Medium DSA problems",
        "Already a DSA Master",
    ];

    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [experience, setExperience] = useState("");
    const [proficiency, setProficiency] = useState("");
    const [profession, setProfession] = useState("");
    const [errorObject, setErrorObject] = useState({ email: "", otpAndPassword: "", user: "" });
    const [freeTrialSubmitLoading, setFreeTrialSubmitLoading] = useState(false);
    const [freeTrialSubmitError, setFreeTrialSubmitError] = useState(false);
    const [freeTrialSubmitErrorMessage, setFreeTrialSubmitErrorMessage] = useState(false);

    const {
        currentLoggedInUserEmail,
        loggedIn,
        subscribed,
        everSubscribed,
        freeTrialFormFilled,
        checkSubscribed,
        isSubscribedResponse,
        setStatesAfterIsSubscribed,
    } = useGlobalContext();

    useEffect(() => {
        checkSubscribed();
    }, []);
    const redirectUser = (link) => navigate(link, { replace: true });

    const getProgress = () => {
        return (
            <section className="signup-setup-progress-wrapper">
                <div className="signup-setup-progress-success"></div>
                <div className="signup-setup-progress-success"></div>
                <div className="signup-setup-progress-success"></div>
            </section>
        );
    };

    const handleProfessionChange = (e) => {
        setProfession(e.target.value);
    };

    const handleExperienceChange = (e) => {
        setExperience(e.target.value);
    };

    const handleProficiencyChange = (e) => {
        setProficiency(e.target.value);
    };
    const getConditionalForm = () => {
        return (
            <section className="signup-form-conditional-input-wrapper">
                <p>Work Experience? </p>
                <Select
                    value={experience}
                    onChange={handleExperienceChange}
                    displayEmpty
                    className="signup-form-experience-dropdown"
                    inputProps={{ "aria-label": "Without label" }}
                    required
                >
                    {Object.keys(EXPERIENCES).map((expKey) => (
                        <MenuItem value={EXPERIENCES[expKey]}>{expKey}</MenuItem>
                    ))}
                </Select>
                <p>Proficiency in Programming and Data Structures & Algorithms?</p>
                <Select
                    value={proficiency}
                    onChange={handleProficiencyChange}
                    displayEmpty
                    className="signup-form-experience-dropdown"
                    inputProps={{ "aria-label": "Without label" }}
                    required
                >
                    {PROFICIENCY.map((prof) => (
                        <MenuItem value={prof}>{prof}</MenuItem>
                    ))}
                </Select>
            </section>
        );
    };

    const submitUserForm = () => {
        mixpanel.track("Clicked on Sign Up button on Start Free trial form");
        const userData = {};
        userData.email = currentLoggedInUserEmail;
        userData.name = name;
        userData.phoneNumber = phone;
        userData.userType = profession;
        userData.experience = experience;
        userData.proficiency = proficiency;
        freeTrialValidationSchema
            .validate({
                name: name,
                phoneNumber: phone,
                userType: profession,
                experience: experience,
                proficiency: proficiency,
            })
            .then(() => {
                let temp = { ...errorObject };
                temp.user = "";
                setErrorObject(temp);
                setFreeTrialSubmitLoading(true);
                startFreeTrial(
                    userData,
                    setFreeTrialSubmitLoading,
                    setFreeTrialSubmitError,
                    setFreeTrialSubmitErrorMessage,
                    redirectUser,
                    isSubscribedResponse,
                    setStatesAfterIsSubscribed
                );
            })
            .catch((err) => {
                let temp = { ...errorObject };
                temp.user = err.errors;
                setErrorObject(temp);
            });
    };

    const redirectIfInvalidState = () => {
        // If a user is not logged in (directly typed this url) : redirect to landing page of current course
        if (isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) redirectToLandingPageStrategy();
    };

    return (
        <>
            {redirectIfInvalidState()}
            <div className="header-sticky">
                <Header />
            </div>
            <section className="signup-wrapper">
                {freeTrialSubmitLoading && <Loader />}
                {windowWidth > 600 && <CompanyAlumni alumni={ALUMNI_CONTENT[2]} />}
                <div className="start-free-trial-form-wrapper">
                    <p className="signup-progress-caption">You are almost there!</p>
                    {getProgress()}
                    <p className="form-details-input-title">Name</p>
                    <input
                        type="text"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        required
                    />

                    <div className="phone-number-box">
                        <p className="form-details-input-title">Phone Number</p>
                        <PhoneInput
                            country={"in"}
                            value={phone}
                            className="phone-input"
                            onChange={(phone) => setPhone(phone)}
                        />
                    </div>
                    <p className=" form-details-input-title signup-form-profession-dropdown-title">
                        You are a
                    </p>
                    <Select
                        value={profession}
                        onChange={handleProfessionChange}
                        displayEmpty
                        className="signup-form-profession-dropdown"
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        {PROFESSIONS.map((profession) => (
                            <MenuItem value={profession}>{profession}</MenuItem>
                        ))}
                    </Select>
                    {getConditionalForm()}

                    <div
                        className="signup-form-submit-CTA common-blue-gradient-CTA"
                        onClick={submitUserForm}
                    >
                        Start Free Trial
                    </div>
                    <div className="error-text">
                        {errorObject.user && <p>{errorObject.user}</p>}
                    </div>
                </div>
            </section>
        </>
    );
};
