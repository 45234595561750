const CppFaqContents = [
    {
        id: 1,
        question: "What opportunities will open up for me after learning C++?",
        answer: "After learning C++, there is a lot that you can do. You can study Data Structures and Algorithms for software development roles, and participate in Competitive Programming as well. You can also start working with organisations whose code base is primarily in C or C plus plus.",
        
    },
    {
        id: 2,
        question: "How much duration is needed to complete this course?",
        answer: "2-3 months is the ideal duration required to complete this course well.",
        
    },
    {
        id: 3,
        question: "How does Doubt Support work?",
        answer: "There is a discussion forum integrated on the dashboard under each video and problem. You can simply comment and ask your doubts there. All doubts are resolved by course instructors and mentors in almost real time. ",
        
    },
    {
        id: 4,
        question: "What is the free Pause and Resume feature?",
        answer: "If you are not able to study for a while, due to health issues, exams, hectic projects, or anything else - you can pause your subscription for a number of days - and your subscription will get extended for those number of days for free. Once you Pause your subscription, the content will be no longer accessible until you resume back. You get 15 Free pause days with this subscription.",
    },
    {
        id: 5,
        question: "I cannot pay due to financial circumstances, what should I do?",
        answer: (
            <p>
                We have scholarships for meritorious students in exceptional financial situations.
                Please apply at{"  "}
                <a href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true">
                    bit.ly/3wFhoAY
                </a>
            </p>
        ),
    },
];

export { CppFaqContents };
