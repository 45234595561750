const RenaissanceFaqContents = [
  {
    id: 1,
    question: "I don't know any programming language. Can I enroll?",
    answer:
      "We teach C++ from basics, so if you don't know any programming language, you can learn here.",
  },
  {
    id: 2,
    question: "How many months are ideal to complete Renaissance?",
    answer:
      "If you want to study 1 Elective, 6-9 months is ideal. And if you want to study with 2 Electives, 12 months is ideal.",
  },
  {
    id: 3,
    question: "How does Doubt Support work?",
    answer:
      "There is a discussion forum integrated on the dashboard under each video and problem. You can simply comment and ask your doubts there. All doubts are resolved in almost real time.",
  },
  {
    id: 4,
    question: "How do Live Masterclasses work?",
    answer:
      "In Masterclasses, we challenge your understanding of topics with the help of new questions and also resolve your doubts. Masterclasses are scheduled on a rolling basis by topics and are minimum one per week.",
  },
  {
    id: 5,
    question: "What is the Personalisation feature?",
    answer:
      "When you subscribe to Renaissance, you tell us about your current level of knowledge and proficiency in coding, what your goal is, and how much you can study in a week - and based on these inputs, we prepare a Personalized Study Plan for you. This Plan makes the course a much easier journey for you, as it is tailored for your strengths and needs.",
  },
  {
    id: 6,
    question: "Which Elective subject should I choose to study?",
    answer:
      "You can choose a subject yourself based on your interests, but if you need help choosing a relevant subject - our course mentors are always present to help you out. ",
  },
  {
    id: 7,
    question: "What is the free Pause and Resume feature?",
    answer:
      "If you are not able to study for a while, due to health issues, exams, hectic projects, or anything else - you can pause your subscription for a number of days - and your subscription will get extended for those number of days for free. You can pause your subscription for free, for the days you are not able to study and resume it back again.",
  },
  {
    id: 8,
    question: "When can I avail Mock Interviews?",
    answer:
      "You can avail Mock Interviews, when you have covered a significant part of your Renaissance syllabus, with continuous study of at least 6 months. You can reach out to us when you feel you are ready to give mock interviews.",
  },
  {
    id: 9,
    question: "Will there be any placement assistance at Renaissance?",
    answer:
      "Yes, we refer great performing and diligent learners for vacancies in companies. We also help you build your resume, prepare for mock interviews and begin your tech career.",
  },
  {
    id: 10,
    question: "What is the Code Everyday Community?",
    answer:
      "As part of our Community, you will develop your problem solving skills by joining Live Coding Sessions everyday, and build your consistency. You will also get access to a lot of Free Events and interact with peers.",
  },
  {
    id: 11,
    question: "I cannot pay due to financial circumstances, what should I do?",
    answer: (
      <p>
        We have scholarships for meritorious students in exceptional financial
        situations. Please apply at {"  "}
        <a href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true">
          bit.ly/3wFhoAY
        </a>
      </p>
    ),
  },
];

export { RenaissanceFaqContents };
