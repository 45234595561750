import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import googleImg from "../../img/sliderGoogle.png";
import amazonLogo from "../../img/sliderAmazon.png";
import microsoft from "../../img/sliderMicrosoft.png";
import adobe from "../../img/sliderAdobe.png";
import razorpay from "../../img/sliderRazorpay.png";
import flipkart from "../../img/sliderFlipkart.png";
import walmart from "../../img/sliderWalmart.png";
import paytm from "../../img/sliderPaytm.png";
import oracle from "../../img/sliderOracle.png";
import paloalto from "../../img/sliderPaloalto.png";


export default class AutoPlay extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            speed: 8000,
            autoplaySpeed: 5000,
            cssEase: "linear",
            // important : set arrows to false to get rid of arrows or invisible marging will occur
            arrows: false,
            responsive: [
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        autoplay: true,
                    },
                },
            ],
        };
        return (
            <div className="logo-slider">
                <div className="logo-slider-text">Alumni now work at top companies like:</div>
                <div className="logo-slider-img-wrapper">
                <Slider {...settings}>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={googleImg}
                            width="150vw"
                            alt="googleImg"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={flipkart}
                            width="150vw"
                            alt="flipkart"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img" 
                            src={adobe} 
                            width="150vw" 
                            alt="adobe" />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={microsoft}
                            width="150vw"
                            alt="microsoft"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={amazonLogo}
                            width="150vw"
                            alt="amazonLogo"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={walmart}
                            width="150vw"
                            alt="walmart"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={razorpay}
                            width="150vw"
                            alt="razorpay"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={paytm}
                            width="150vw"
                            alt="paytm"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={oracle}
                            width="150vw"
                            alt="oracle"
                        />
                    </div>
                    <div>
                        <img
                            className="logo-slider-img"
                            src={paloalto}
                            width="150vw"
                            alt="paloalto"
                        />
                    </div>
                    
                </Slider>
                </div>
            </div>
        );
    }
}
