import designSoftwareIcon from "../../../img/design-software-icon.svg";
import codeReviewerIcon from "../../../img/rockstar-code-reviewer-icon.svg";
import concurrencyIcon from "../../../img/understanding-of-concurrency-icon.svg";
import interviewReadyIcon from "../../../img/ready-for-interview-icon.svg";
const AdavantagesContent = [
    {
        text: (
            <p>
                Design <strong>robust and extensible</strong> software systems as per industry
                standards,  adhering to design principles and patterns.
            </p>
        ),
        img: designSoftwareIcon,
        className: "design-software-icon",
    },
    {
        text: (
            <p>
                Be a <strong>rockstar code-reviewer</strong> for your team and help with design
                inputs and code architecture.
            </p>
        ),
        img: codeReviewerIcon,
        className: "code-reviewer-icon",
    },
    {
        text: (
            <p>
                Gain <strong>in-depth understanding</strong> of concurrency, confidently participate
                in design discussions.
            </p>
        ),
        img: concurrencyIcon,
        className: "concurrency-icon",
    },
    {
        text: (
            <p>
                Ready to <strong>face interviews</strong> focused on Low Level Design, Concurrency
                and Machine Coding.
            </p>
        ),
        img: interviewReadyIcon,
        className: "interview-ready-icon",
    },
];

export default AdavantagesContent;
